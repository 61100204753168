import { useQuery } from "@tanstack/react-query";
import {useMemo} from "react";
import {getUniqueObjectsByKey} from "../../utils";

export function useSchematics({ region_id }) {
  const schematicURL = useMemo(() => {
    const params = new URLSearchParams();
    params.set("expand", ["output_item", "inputs.item"].join(","));
    if (region_id) {
      params.set("region_id", region_id);
    }
    return `/api/pi/schematics/?${params.toString()}`;
  }, [region_id]);


  const { data: schematics, isLoading: schematicsIsLoading } = useQuery({
    queryKey: ["pi", "schematics", schematicURL],
    queryFn: () => {
      return fetch(schematicURL)
        .then((res) => res.json())
        .then((data) => {
          // We create a synthetic schematic for each input to P1s
          const p1s = data.filter((s) => s.tier === 1);
          const p1Inputs = p1s.flatMap((s) => s.inputs);

          for (const input of p1Inputs) {
            const schematic = {
              id: input.item_id,
              output_item: {
                ...input,
                id: input.item_id,
              },
              inputs: [],
              tier: 0,
            };
            data.push(schematic);
          }

          return data;
        })
        .then((data) => data.sort((a, b) => a.tier > b.tier));
    },
    staleTime: 600000,
  });

  return {
    schematics,
    schematicsIsLoading,
  };
}

export function usePIByCharacter() {
  const piPlanetsURL = useMemo(() => {
    const params = new URLSearchParams();
    params.set(
      "expand",
      [
        "character",
        "pins",
        "pins.schematic",
        "pins.schematic.output_item",
        "pins.schematic.inputs",
        "pins.extracting",
        "planet",
        "planet.item",
        "solar_system",
      ].join(","),
    );
    return `/api/pi/planets/?${params.toString()}`;
  }, []);

  const { data: planets, isLoading } = useQuery({
    queryKey: ["pi", "planets", piPlanetsURL],
    queryFn: () => {
      return fetch(piPlanetsURL).then((res) => res.json());
    },
    staleTime: 300000,
  });

  const results = useMemo(() => {
    if (!planets) {
      return {};
    }

    const planetsByCharacter = Object.groupBy(planets, (planet) => planet.character.id);
    const characters = getUniqueObjectsByKey(planets.flatMap((p) => p.character.id), (p) => p);
    const results = {};

    for (const character of characters) {
      if (!planetsByCharacter[character]) {
        continue;
      }

      results[character] = {
        character: planetsByCharacter[character][0].character,
        planets: planetsByCharacter[character] || []
      };

      for (const planet of results[character].planets) {
        const extracts = getUniqueObjectsByKey(
          planet.pins.filter((pin) => pin.extracting),
          (pin) => pin.extracting.id,
        );

        const availableInputs = new Map();
        const potentialExports = new Map();
        const usedInputs = new Set();

        for (const pin of planet.pins) {
          if (pin.extracting) {
            potentialExports.set(pin.extracting.id, pin.extracting);
            availableInputs.set(pin.extracting.id, pin.extracting);
          }
          if (pin.schematic) {
            const output = pin.schematic.output_item;
            potentialExports.set(output.id, output);
            availableInputs.set(output.id, output);
          }
        }

        const imports = [];
        for (const pin of planet.pins) {
          if (pin.schematic) {
            for (const input of pin.schematic.inputs) {
              if (!availableInputs.has(input.item_id)) {
                imports.push(input);
                availableInputs.set(input.item_id, input);
              }
              usedInputs.add(input.item_id);
            }
          }
        }

        const exports = Array.from(potentialExports.values())
          .filter(item => !usedInputs.has(item.id));

        planet.extracts = extracts;
        planet.imports = getUniqueObjectsByKey(imports, item => item.item_id);
        planet.exports = exports;
      }
    }

    return results;
  }, [planets]);

  return {isLoading, results};
}