import React, { useCallback, useState } from "react";
import { ScopeBar } from "../../components/ScopeBar";
import { Content, Header, Page } from "../../components/Page";
import { Form, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { FilterBuilder } from "../../components/FilterBuilder";
import { AutocompleteInput } from "../components/AutocompleteInput";
import { useStorage } from "../../hooks/useStorage";
import { ContractTable } from "./ContractTable";

export function ContractList() {
  const [type, setType] = useStorage("contracts.type", "item_exchange");
  const [url, setURL] = useState(null);
  const [filters, setFilters] = useStorage("contracts.filters", []);
  const [sorting, setSorting] = useStorage("contracts.sorting", [
    { id: "date_expired", desc: false },
  ]);

  const changeType = useCallback((value) => {
    setType(value);
    setURL(null);
  }, [setType]);

  const changeFilters = useCallback((value) => {
    setFilters(value);
    setURL(null);
  }, [setFilters]);

  return (
    <Page>
      <Content>
        <Header title="Contracts" />
        <div className="mb-3">
          <ScopeBar optional={["esi-contracts.read_character_contracts.v1"]} />
        </div>
        <ToggleButtonGroup
          className="mb-3"
          defaultValue={type}
          type="radio"
          name="contract_type"
          onChange={changeType}
        >
          <ToggleButton id="exchange" value="item_exchange" variant="dark">
            Exchange
          </ToggleButton>
          <ToggleButton id="courier" value="courier" variant="dark">
            Courier
          </ToggleButton>
          <ToggleButton id="auction" value="auction" variant="dark">
            Auction
          </ToggleButton>
        </ToggleButtonGroup>
        <FilterBuilder
          onChange={changeFilters}
          filters={filters}
          allowedFilters={{
            location: {
              label: "Location",
              operators: ["==", "!="],
              component: AutocompleteInput,
              props: {
                type: "location",
              },
            },
            available_to: {
              label: "Available To",
              operators: ["==", "!="],
              component: AutocompleteInput,
              props: {
                type: "entity",
              },
            },
            issuer: {
              label: "Issuer",
              operators: ["==", "!="],
              component: AutocompleteInput,
              props: {
                type: "entity",
              },
            },
            price: {
              label: "Price",
              operators: ["<<", "<=", "==", "!=", ">>", ">="],
            },
            volume: {
              label: "Volume",
              operators: ["<<", "<=", "==", "!=", ">>", ">="],
            },
            is_copy: {
              label: "Is Blueprint Copy",
              operators: ["==", "!="],
              component: Form.Select,
              props: {
                children: [
                  <option key="true" value="1">Yes</option>,
                  <option key="false" value="0">No</option>,
                ],
              },
            },
          }}
        />
        <ContractTable
          type={type}
          filters={filters}
          sorting={sorting}
          setSorting={setSorting}
          url={url}
          setURL={setURL}
        />
      </Content>
    </Page>
  );
}