import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Loading } from "../../components/Loading";
import { Header } from "../../components/Page";
import * as Plot from "@observablehq/plot";
import { AutocompleteInput } from "../components/AutocompleteInput";
import { useStorage } from "../../hooks/useStorage";
import { Table } from "../../components/Table";
import { priceCell } from "../../components/Cells";
import { compactNumber } from "../../formatting";

const MarketDataChart = ({ data }) => {
  const chartRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateDimensions = () => {
      const containerWidth = chartRef.current.parentElement.clientWidth;
      setDimensions({
        width: containerWidth,
        height: Math.max(400, Math.min(600, containerWidth * 0.6)),
      });
    };

    window.addEventListener("resize", updateDimensions);
    updateDimensions();

    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if (!data || data.length === 0 || dimensions.width === 0) return;

    // Calculate moving averages
    const movingAverage = (data, period) => {
      return data.map((d, i) => {
        const start = Math.max(0, i - period);
        const end = i + 1;
        const subset = data.slice(start, end);
        const sum = subset.reduce((acc, d) => acc + d.average, 0);
        return sum / subset.length;
      });
    };

    const movingAverage7Day = movingAverage(data, 7);
    const movingAverage20Day = movingAverage(data, 20);

    const chart = Plot.plot({
      color: {
        range: ["#995500", "#664411", "steelblue"],
        domain: [
          "Average Price",
          "20 Day Moving Average",
          "7 Day Moving Average",
        ],
      },
      y: {
        grid: true,
        label: "Price",
        tickFormat: compactNumber,
      },
      x: {
        grid: true,
        label: "Date",
        interval: "day",
      },
      marks: [
        Plot.dotY(data, {
          x: "day",
          y: "average",
          stroke: "#995500",
          fill: "#664411",
          tip: {
            fill: "black",
          },
        }),
        Plot.lineY(movingAverage20Day, {
          x: data.map((d) => d.day),
          stroke: "#664411",
        }),
        Plot.lineY(movingAverage7Day, {
          x: data.map((d) => d.day),
          stroke: "steelblue",
        }),
      ],
      width: dimensions.width,
      height: dimensions.height,
    });

    const legend = chart.legend("color");

    const volumeChart = Plot.plot({
      color: {
        range: ["steelblue"],
        domain: ["Volume"],
      },
      y: {
        grid: true,
        label: "Volume",
        tickFormat: compactNumber,
      },
      x: {
        grid: true,
        label: "Date",
        interval: "day",
      },
      marks: [
        Plot.barY(data, {
          x: "day",
          y: "volume",
          fill: "steelblue",
          tip: {
            fill: "black",
          },
        }),
      ],
      width: dimensions.width,
      height: 120,
    });

    chartRef.current.innerHTML = "";
    chartRef.current.append(chart);
    chartRef.current.append(legend);
    chartRef.current.append(volumeChart);

    return () => {
      chart.remove();
      legend.remove();
    };
  }, [data, dimensions]);

  return <div ref={chartRef} className="w-full h-full"></div>;
};

export function HistoryTab({ itemID }) {
  const [region, setRegion] = useStorage("market.history.region", {
    id: 10000002,
    name: "The Forge",
  });

  const { data: history, isLoading } = useQuery({
    queryKey: ["market", "history", itemID, region],
    queryFn: () =>
      fetch(`/api/types/${itemID}/history/${region.id}/`)
        .then((res) => res.json())
        .then((data) => {
          return {
            ...data,
            results: data.results
              .map((d) => ({
                ...d,
                day: new Date(d.day),
                average: parseFloat(d.average),
                lowest: parseFloat(d.lowest),
                highest: parseFloat(d.highest),
                volume: parseInt(d.volume),
              }))
              .sort((a, b) => a.day - b.day),
          };
        }),
    enabled: !!itemID && !!region?.id,
  });

  return (
    <div className={"mt-3"}>
      <Header title={"Market History"} />
      <div className={"mb-3 dark"}>
        <AutocompleteInput
          defaultValue={region}
          onChange={setRegion}
          inputProps={{
            placeholder: "Region",
          }}
          type={"regions"}
          helpText={"Select the region to view history for."}
        />
      </div>
      {isLoading ? (
        <Loading />
      ) : history?.results ? (
        <div className={"w-100"}>
          <MarketDataChart data={history.results} />
        </div>
      ) : (
        <div>No data available</div>
      )}
      <div
        className={"mt-3 overflow-y-scroll"}
        style={{
          maxHeight: "50vh",
        }}
      >
        <Table
          rows={history?.results.toReversed() || []}
          isLoading={isLoading}
          defaultColumn={{
            enableSorting: false,
            meta: {
              align: "end",
            },
          }}
          columns={[
            {
              accessorKey: "day",
              header: "Date",
              cell: (cell) => (
                <span>{cell.getValue().toLocaleDateString()}</span>
              ),
            },
            {
              accessorKey: "average",
              header: "Average",
              cell: (cell) => <span>{priceCell(cell.getValue(), false)}</span>,
            },
            {
              accessorKey: "lowest",
              header: "Lowest",
              cell: (cell) => <span>{priceCell(cell.getValue(), false)}</span>,
            },
            {
              accessorKey: "highest",
              header: "Highest",
              cell: (cell) => <span>{priceCell(cell.getValue(), false)}</span>,
            },
            {
              accessorKey: "volume",
              header: "Volume",
              format: (v) => v.toLocaleString(),
            },
          ]}
        />
      </div>
    </div>
  );
}
