import {Content, Header, Page} from "../../components/Page";

export function Settings() {
  return (
    <Page>
      <Content>
        <Header title={"Settings"} />
        <div className={"alert alert-info"}>
          There's nothing here yet. Check back later!
          Most settings are available directly on each page.
        </div>
      </Content>
    </Page>
  )
}
