import { Header } from "../../components/Page";
import { useQuery } from "@tanstack/react-query";
import { Loading } from "../../components/Loading";
import { Table } from "../../components/Table";
import { characterCell, priceCell } from "../../components/Cells";
import { ScopeBar } from "../../components/ScopeBar";
import { AggregatesChart } from "../../components/AggregatesChart";
import { useStorage } from "../../hooks/useStorage";

export function WalletDashboard() {
  const [characterSorting, setCharacterSorting] = useStorage("wallets.dashboard.character.sorting", [
    {
      id: "balance",
      desc: true,
    },
  ]);

  const [corporationSorting, setCorporationSorting] = useStorage("wallets.dashboard.corporation.sorting", [
    {
      id: "balance",
      desc: true,
    },
  ]);

  const { data: characters, isLoading: charactersIsLoading } = useQuery({
    queryKey: ["wallets", "characters"],
    queryFn: () => {
      const url = new URL("/api/characters/", window.location.origin);
      return fetch(url).then((res) => res.json());
    },
  });

  const { data: corporations, isLoading: corporationsIsLoading } = useQuery({
    queryKey: ["wallets", "corporations"],
    queryFn: () => {
      const url = new URL("/api/corporation/", window.location.origin);
      return fetch(url).then((res) => res.json());
    },
  });

  if (charactersIsLoading || corporationsIsLoading) {
    return <Loading />;
  }

  return (
    <>
      <Header title={"Dashboard"} />
      <div className={"mb-4"}>
        <ScopeBar
          scopes={[
            "esi-wallet.read_character_wallet.v1",
            "esi-characters.read_corporation_roles.v1",
          ]}
          optional={["esi-wallet.read_corporation_wallets.v1"]}
        />
      </div>
      <div className={"mb-4"}>
        <h3 className={"my-4"}>Wallets Over Time</h3>
        <AggregatesChart dataKey={"total_isk"} />
      </div>
      <h3 className={"my-4"}>Characters</h3>
      <Table
        sorting={characterSorting}
        setSorting={setCharacterSorting}
        columns={[
          {
            header: "Character",
            cell: (cell) => {
              const original = cell.row.original;
              return characterCell(original.id, original.name);
            },
          },
          {
            header: "Balance (ISK)",
            accessorKey: "balance",
            meta: {
              align: "end",
            },
            cell: (cell) => priceCell(cell.getValue(), false),
          },
        ]}
        rows={characters}
      />
      <h3 className={"my-4"}>Corporations</h3>
      <Table
        sorting={corporationSorting}
        setSorting={setCorporationSorting}
        columns={[
          {
            header: "Corporation",
            cell: (cell) => {
              const original = cell.row.original;
              return (
                <div className={"d-flex align-items-center"}>
                  <img
                    src={`https://images.evetech.net/corporations/${original.id}/logo`}
                    alt={original.name}
                    className={"icon me-2"}
                  />
                  {original.name}
                </div>
              );
            },
          },
          {
            header: "Balance",
            id: "balance",
            accessorFn: (row) => {
              let balance = 0;
              balance += row.balance_division_1 || 0;
              balance += row.balance_division_2 || 0;
              balance += row.balance_division_3 || 0;
              balance += row.balance_division_4 || 0;
              balance += row.balance_division_5 || 0;
              balance += row.balance_division_6 || 0;
              balance += row.balance_division_7 || 0;
              return balance;
            },
            meta: {
              align: "end",
            },
            cell: (cell) => {
              const value = cell.getValue();
              return priceCell(value);
            },
          },
        ]}
        rows={corporations}
      />
    </>
  );
}
