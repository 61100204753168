import { useQuery } from "@tanstack/react-query";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Table } from "../../components/Table";
import { characterCell } from "../../components/Cells";
import { Loading } from "../../components/Loading";

const skillsColumns = [
  {
    header: "Character",
    accessorKey: "name",
    cell: (cell) => characterCell(cell.row.original.id, cell.getValue()),
  },
  {
    header: "Total Skill Points",
    accessorKey: "sp",
    meta: {
      align: "end",
    },
  },
  {
    header: "Level",
    accessorKey: "active",
    meta: {
      align: "end",
    },
    cell: (cell) => {
      const original = cell.row.original;
      if (original.active < original.trained) {
        return (
          <OverlayTrigger
            overlay={
              <Popover>
                <Popover.Body>
                  <p>
                    This character has a higher trained skill level than active
                    skill level. This typically means the character was Omega,
                    but is now Alpha.
                  </p>
                  <dl>
                    <dt>Trained Skill Level</dt>
                    <dd>{original.trained}</dd>
                    <dt>Active Skill Level</dt>
                    <dd>{original.active}</dd>
                  </dl>
                </Popover.Body>
              </Popover>
            }
          >
            <span className={"text-warning"}>{original.active}</span>
          </OverlayTrigger>
        );
      } else {
        return original.active;
      }
    },
  },
];

export function SkillsTab({ skillId }) {
  const { data, isLoading } = useQuery({
    queryKey: ["characters_skills", skillId],
    queryFn: () => {
      const url = new URL(`/api/characters/`, window.location.origin);
      url.searchParams.append("expand", ["skills", "skills.skill"].join(","));

      return fetch(url)
        .then((res) => res.json())
        .then((res) => {
          return res
            .filter((character) => {
              return character.skills.some((skill) => {
                return skill.skill.id === skillId;
              });
            })
            .map((character) => {
              const skill = character.skills.find((skill) => {
                return skill.skill.id === skillId;
              });

              return {
                name: character.name,
                id: character.id,
                sp: skill.sp,
                active: skill.active_skill_level,
                trained: skill.trained_skill_level,
              };
            });
        });
    },
  });

  if (isLoading || !data) {
    return <Loading />;
  }

  return <Table columns={skillsColumns} rows={data} />;
}
