import { Table } from "../../components/Table";
import { CompactStationLocation } from "../../components/Cells";
import { Link } from "react-router-dom";

const cloneColumns = [
  {
    header: "Name",
    accessorKey: "name",
    cell: (cell) => {
      const original = cell.row.original;
      if (original.jump_clone_id === 0) {
        return <span className={"text-info"}>Active Clone</span>;
      }

      if (original.name) {
        return original.name;
      } else {
        return <em>Unnamed</em>;
      }
    },
  },
  {
    header: "Location",
    accessorKey: "location",
    cell: (cell) => {
      const system = cell.row.original.system;

      if (cell.row.original.location) {
        return <CompactStationLocation location={cell.row.original.location} />;
      } else if (system) {
        return (
          <Link to={`/universe/system/${system.id}`}>
            {system.name} (in space)
          </Link>
        );
      } else {
        return <em>Unknown</em>;
      }
    },
  },
  {
    header: "Implants",
    accessorKey: "implants",
    cell: (cell) => {
      const original = cell.row.original;
      if (original?.implants?.length > 0) {
        return original.implants.map((implant) => (
          <Link to={`/market/${implant.id}`} key={implant.id}>
            <img
              src={`https://images.evetech.net/types/${implant.id}/icon?size=32`}
              alt={implant.name}
              title={implant.name}
              className={"icon"}
            />
          </Link>
        ));
      } else {
        return <em>-</em>;
      }
    },
  },
];

/**
 * ClonesTable displays a table of clones for a character.
 *
 * @param character The source character.
 * @returns {JSX.Element}
 * @constructor
 */
export function ClonesTable({ character }) {
  if (!character.clones) {
    return (
      <div className={"alert alert-info"}>This character has no clones.</div>
    );
  }

  return <Table columns={cloneColumns} rows={character.clones || []} />;
}
