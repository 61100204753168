export function getUniqueObjectsByKey(objects, keyFn) {
  const uniqueMap = new Map();

  return objects.filter((obj) => {
    const key = keyFn(obj);
    if (key !== undefined && key !== null) {
      if (!uniqueMap.has(key)) {
        uniqueMap.set(key, true);
        return true;
      }
    }
    return false;
  });
}


export function padPrefix(value, length, withChar = "0") {
  if (value === null || value === undefined) {
    return value;
  }

  if (typeof value === "number") {
    value = value.toString();
  }

  return value.padStart(length, withChar);
}