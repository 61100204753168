import {useNavigate, useParams} from "react-router-dom";
import {Content, Header, Page} from "../../components/Page";
import {useQuery} from "@tanstack/react-query";
import {Loading} from "../../components/Loading";
import {characterCell, IconCell, TypeCell} from "../../components/Cells";
import {useCallback, useEffect, useMemo} from "react";
import {Block, BlockHeader, BlockList, BlockListItem} from "../../components/Block";
import {CopyButton} from "../../components/Copy";
import {ScopeBar} from "../../components/ScopeBar";
import {savePaste} from "../../mutations/paste";

// Holds are special slots that can hold items without any particular positional slot
const holds = ["Cargo", "DroneBay", "FighterBay"];
// Slotted items are items that have a specific slot they can be placed in,
// identified by the suffix of the slot name. This list is the [prefix, # of slots]
// for each group.
const slotted = [
  ["HiSlot", 8],
  ["MedSlot", 8],
  ["LoSlot", 8],
  ["RigSlot", 3],
  ["ServiceSlot", 8],
  ["SubSystem", 4]
];

const orderOfGroups = [
  ["HiSlot", "High Slots"],
  ["MedSlot", "Medium Slots"],
  ["LoSlot", "Low Slots"],
  ["RigSlot", "Rig Slots"],
  ["ServiceSlot", "Service Slots"],
  ["SubSystem", "Sub Systems"],
  ["DroneBay", "Drone Bay"],
  ["FighterBay", "Fighter Bay"],
  ["Cargo", "Cargo"],
];

const makeEFTFitting = (fitting, groupedItems) => {
  let eft = `[${fitting.ship_item.name}, ${fitting.name}]\n`;

  // EFT uses a different ordering, with each section (even when empty)
  // separated by a blank line.
  const eftOrderingOfGroups = [
    "LoSlot",
    "MedSlot",
    "HiSlot",
    "RigSlot",
    "ServiceSlot",
    "SubSystem",
    "DroneBay",
  ];

  for (const key of eftOrderingOfGroups) {
    eft += "\n";
    eft += groupedItems[key].map((item) => {
      if (!item) {
        return "";
      }
      if (item.flag === "DroneBay") {
        return `${item.item.name}, ${item.quantity}`;
      } else {
        return item.item.name;
      }
    }).join("\n");
  }

  // Everything that isn't one of the groups above will go in the last
  // group as cargo
  for (const key of ["Cargo", "FighterBay"]) {
    eft += "\n";
    eft += groupedItems[key].map((item) => {
      return `${item.item.name}, ${item.quantity}`;
    }).join("\n");
  }

  return eft;
}

export function FittingPage () {
  const { id } = useParams();
  return (
    <Page>
      <Content>
        <Header title={"Fitting"} />
        <Fitting id={id} />
      </Content>
    </Page>
  )
}

export function Fitting ({ id }) {
  const { data: fitting, isLoading } = useQuery({
    queryKey: ["fitting", id],
    queryFn: async () => {
      const params = new URLSearchParams();
      params.append("expand", "ship_item,items,items.item,character");
      const response = await fetch(`/api/fittings/${id}/?${params.toString()}`);
      return response.json();
    },
  });
  
  const groupedItems = useMemo(() => {
    if (!fitting) {
      return {};
    }

    // Create a pre-filled dict with the keys for each group
    // and an empty list for the items in that group.
    const groups = {};
    for (const [name, count] of slotted) {
      groups[name] = Array(count).fill(null);
    }

    // Add the holds to the groups
    for (const hold of holds) {
      groups[hold] = [];
      for (const item of fitting.items) {
        if (item.flag === hold) {
          groups[hold].push(item);
        }
      }
    }

    // Add the items to the groups
    for (const item of fitting.items) {
      for (const [name, count] of slotted) {
        // The prefix of the `flag` field is the name of the slot,
        // followed by a number. We only care about the name.
        if (item.flag.startsWith(name)) {
          const slot = parseInt(item.flag.replace(name, ""));
          groups[name][slot] = item;
        }
      }
    }

    return groups;
  }, [fitting]);

  const navigate = useNavigate();
  const saveMutation = savePaste({
    onSuccess: (data) => {
      navigate(`/paste/${data.slug}`);
    },
  });

  const createAppraisal = useCallback(
    (e) => {
      e.target.disabled = true;

      let content = `${fitting.ship_item.name} 1\n`;
      content += fitting.items.map((item) => {
        return `${item.item.name} ${item.quantity}`;
      }).join("\n");

      saveMutation.mutate({
        content: content,
        region: 10000002,
      });
    },
    [fitting],
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className={"mb-4"}>
        <ScopeBar scopes={["esi-fittings.read_fittings.v1", "esi-fittings.write_fittings.v1"]} />
      </div>
      <div className={"row"}>
        <div className={"col-12 col-md-6"}>
          <Block className={"mb-3"}>
            <BlockList>
              <BlockListItem>
                <div>Ship</div>
                <TypeCell id={fitting.ship_item.id} name={fitting.ship_item.name}/>
              </BlockListItem>
              <BlockListItem>
                <div>Character</div>
                {characterCell(fitting.character.id, fitting.character.name)}
              </BlockListItem>
              <BlockListItem>
                <div>Volume</div>
                <div>
                  {fitting.items.reduce((acc, item) => acc + item.item.volume * item.quantity, 0).toFixed(2)} m<sup>3</sup>
                </div>
              </BlockListItem>
            </BlockList>
          </Block>
          <CopyButton className={"btn btn-outline-info w-100 mb-3"} onDoCopy={() => makeEFTFitting(fitting, groupedItems)}>
            Copy EFT Fitting
          </CopyButton>
          <button
            className={"btn btn-outline-info w-100 mb-3"}
            onClick={createAppraisal}
            disabled={saveMutation.isPending}
          >
            Create Appraisal from Fitting
          </button>
        </div>
        <div className={"col-12 col-md-6"}>
          {orderOfGroups.map(([key, title]) => {
            return (
              <Block>
                <BlockHeader>
                  <strong>{title}</strong>
                </BlockHeader>
                <BlockList>
                  {groupedItems[key].map((item, index) => {
                    if (!item) {
                      return;
                    }
                    return (
                      <BlockListItem>
                        <div className={""}>
                          <TypeCell id={item.item.id} name={item.item.name} />
                        </div>
                        <div>
                          <span>x{item.quantity}</span>
                        </div>
                      </BlockListItem>
                    );
                  })}
                </BlockList>
              </Block>
            )
          })}
        </div>
      </div>
    </>
  )
}