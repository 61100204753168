import {useMemo} from "react";
import {useQuery} from "@tanstack/react-query";
import {applyFiltersToURL} from "../components/FilterBuilder";

const FairValue = {
  VeryOverpriced: "very_overpriced",
  Overpriced: "overpriced",
  Underpriced: "underpriced",
  Fair: "fair",
  NotEnoughData: "not_enough_data",
};

/**
 * Check if the contract is fair value.
 *
 * If a contract only has included items, it's a Want To Sell contract.
 * If a contract only has wanted items, it's a Want To Buy contract.
 * If a contract has both included and wanted items, it's a Want To Trade contract.
 *
 * WTS contracts are considered fair value if the price is less than the fair price of the included items.
 * WTB contracts are considered fair value if the reward is more than the fair price of the wanted items.
 * WTT contracts are considered fair value if the price is less than the fair price of the included items
 *    and the reward is more than the fair price of the wanted items combined.
 *
 * @param contract
 */
function isFairValue(contract) {
  const { items, price, reward, buyout } = contract;

  const included = items.filter((item) => item.is_included);
  const wanted = items.filter((item) => !item.is_included);

  let contractPrice = price || buyout || (reward * -1) || 0;

  // Calculate the fair price of the included items in the contract.
  let includedFairPrice = 0;
  for (const item of included) {
    if (!item.item) {
      return {
        value: FairValue.NotEnoughData,
        fairPrice: null,
        price: contractPrice,
      };
    }

    if (item.item.fair_price.length > 0 && !item.is_blueprint_copy && item.item.fair_price[0].sell > 0) {
      includedFairPrice += item.item.fair_price[0].sell * item.quantity;
    } else {
      return {
        value: FairValue.NotEnoughData,
        fairPrice: null,
        price: contractPrice,
      };
    }
  }

  // Calculate the fair price of the wanted items in the contract.
  let wantedFairPrice = 0;
  for (const item of wanted) {
    if (item.item.fair_price.length > 0 && !item.is_blueprint_copy && item.item.fair_price[0].sell > 0) {
      wantedFairPrice += item.item.fair_price[0].sell * item.quantity;
    } else {
      return {
        value: FairValue.NotEnoughData,
        fairPrice: null,
        price: contractPrice,
      };
    }
  }

  // Determine contract type
  const isWTS = included.length > 0 && wanted.length === 0;
  const isWTB = included.length === 0 && wanted.length > 0;
  const isWTT = included.length > 0 && wanted.length > 0;

  // Helper function to determine value category
  const getValueCategory = (actualValue, fairValue) => {
    if (isWTS) {
      if (actualValue < fairValue) {
        return FairValue.Underpriced;
      } else if (actualValue > fairValue * 2) {
        return FairValue.VeryOverpriced;
      } else if (actualValue > fairValue) {
        return FairValue.Overpriced;
      } else {
        return FairValue.Fair;
      }
    } else if (isWTB) {
      if (actualValue > fairValue) {
        return FairValue.Overpriced;
      } else if (actualValue < fairValue / 2) {
        return FairValue.VeryOverpriced;
      } else if (actualValue < fairValue) {
        return FairValue.Underpriced;
      } else {
        return FairValue.Fair;
      }
    } else if (isWTT) {
      if (actualValue < fairValue) {
        return FairValue.Underpriced;
      } else if (actualValue > fairValue * 2) {
        return FairValue.VeryOverpriced;
      } else if (actualValue > fairValue) {
        return FairValue.Overpriced;
      } else {
        return FairValue.Fair;
      }
    } else {
      return FairValue.NotEnoughData;
    }
  };

  // Evaluate based on contract type
  if (isWTS) {
    // For WTS, compare the contract price to the fair price of included items
    return {
      value: getValueCategory(contractPrice, includedFairPrice),
      fairPrice: includedFairPrice,
      price: contractPrice,
    };
  } else if (isWTB) {
    // For WTB, compare the contract price plus reward to the fair price of wanted items
    return {
      value: getValueCategory(contractPrice, wantedFairPrice),
      fairPrice: wantedFairPrice,
      price: contractPrice,
    };
  } else if (isWTT) {
    const fairPriceDifference = includedFairPrice - wantedFairPrice;
    return {
      value: getValueCategory(contractPrice, fairPriceDifference),
      fairPrice: fairPriceDifference,
      price: contractPrice,
    };
  }

  // If we reach here, it's an unexpected case
  return {
    value: FairValue.NotEnoughData,
    fairPrice: null,
    price: contractPrice,
  };
}


function useContracts({ contractType = "item_exchange", fields, sorting, filters, expand, url }) {
  const contractsURL = useMemo(() => {
    const params = new URLSearchParams();
    params.append("contract_type", `==${contractType}`);
    if (fields) params.append("fields", fields.join(","));
    if (expand) params.append("expand", expand.join(","));
    if (filters) applyFiltersToURL(params, filters);
    if (sorting) {
      params.set(
        "ordering",
        sorting.map((sort) => `${sort.desc ? "-" : ""}${sort.id}`).join(",")
      );
    }
    return `/api/contracts/?${params}`;
  }, [contractType, fields, expand, sorting, filters]);

  return useQuery({
    queryKey: ["contracts", url || contractsURL],
    queryFn: () => fetch(url || contractsURL).then((res) => res.json()),
    staleTime: 30_000,
  });
}

export {useContracts, isFairValue, FairValue};