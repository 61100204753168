import { useState } from "react";
import { useCombobox } from "downshift";
import {
  useFloating,
  autoUpdate,
  autoPlacement,
  size,
} from "@floating-ui/react-dom";
import { FloatingPortal, offset, shift } from "@floating-ui/react";
import { IconCell, security } from "../../components/Cells";
import Structure from "jsx:../../icons/structure.svg";
import System from "jsx:../../icons/system.svg";
import Universe from "jsx:../../icons/universe.svg";
import Fuse from "fuse.js";

const EMPTY = [];

export function renderItem(type, item) {
  if (type === "station") {
    return (
      <>
        {security(item.system.security)}&nbsp;-&nbsp;
        <strong>{item.name}</strong>
      </>
    );
  } else if (type === "type") {
    return (
      <>
        <IconCell
          id={item.id}
          name={item.name}
          iconSize={32}
          className="me-1"
          style={{
            height: "16px",
          }}
        />
        <strong>{item.name}</strong>
      </>
    );
  } else if (type === "container") {
    return (
      <>
        <strong>{item.name}</strong>
        {item.location && <div className={"fs-6"}>{item.location.name}</div>}
        <div className={"fs-6"}></div>
      </>
    );
  } else if (type === "location" || type === "regions") {
    const Icon = {
      station: Structure,
      system: System,
      region: Universe,
    }[item.type];

    return (
      <>
        <Icon className={"icon sm me-2"} />
        <strong>{item.name}</strong>
      </>
    );
  } else if (
    type === "character" ||
    type === "entity" ||
    type === "lp_corporation"
  ) {
    return (
      <>
        <IconCell
          id={item.id}
          name={item.name}
          iconSize={32}
          className="me-1"
          style={{
            height: "16px",
          }}
        />
        <strong>{item.name}</strong>
      </>
    );
  } else {
    return <>{item.name}</>;
  }
}

export function AutocompleteInput({
  label,
  type,
  onChange,
  value,
  inputProps,
  helpText,
  defaultValue,
}) {
  const [items, setItems] = useState(EMPTY);
  const [aborter, setAborter] = useState(null);

  const {
    getLabelProps,
    getInputProps,
    getMenuProps,
    getItemProps,
    isOpen,
    highlightedIndex,
    selectedItem,
  } = useCombobox({
    onInputValueChange: ({ inputValue }) => {
      if (inputValue.length > 0) {
        if (aborter) {
          aborter.abort();
        }

        const abb = new AbortController();
        setAborter(abb);

        fetch(`/api/autocomplete/?type=${type}&query=${inputValue}`, {
          signal: abb.signal,
        })
          .then((res) => res.json())
          .then((data) => {
            const fuse = new Fuse(data.results, {
              keys: ["name"],
              threshold: 0.5,
            });
            setItems(fuse.search(inputValue).map((result) => result.item));
          })
          .catch((e) => {
            if (e.name !== "AbortError") {
              throw e;
            }
          });
      } else {
        setItems(EMPTY);
        if (inputValue.length === 0) {
          onChange(null);
        }
      }
    },
    onSelectedItemChange: ({ selectedItem }) => {
      onChange(selectedItem);
    },
    items: items,
    itemToString: (item) => item?.name,
    selectedItem: value,
    defaultSelectedItem: defaultValue,
  });

  const { refs, floatingStyles } = useFloating({
    whileElementsMounted: autoUpdate,
    open: isOpen,
    middleware: [
      offset(10),
      shift({
        padding: 8,
      }),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          });
        },
      }),
      autoPlacement({
        padding: 8,
        allowedPlacements: [
          "bottom-start",
          "bottom-end",
          "top-start",
          "top-end",
        ],
      }),
    ],
  });

  return (
    <>
      <div className={"form-group"} ref={refs.setReference}>
        {label && (
          <label className={"form-label"} {...getLabelProps()}>
            {label}
          </label>
        )}
        <input
          className={"form-control"}
          {...inputProps}
          {...getInputProps()}
        />
        {helpText && (
          <small className={"form-text text-white-50"}>{helpText}</small>
        )}
      </div>
      <FloatingPortal>
        <div
          className={["popup", isOpen && items.length > 0 ? "" : "d-none"].join(
            " ",
          )}
          ref={refs.setFloating}
          style={floatingStyles}
        >
          <ul
            className={"list-group border-0 overflow-y-auto"}
            style={{
              maxHeight: "200px",
            }}
            {...getMenuProps()}
          >
            {isOpen &&
              items.map((item, index) => (
                <li
                  key={item.id}
                  className={[
                    "list-group-item",
                    "list-group-item-action",
                    highlightedIndex === index ? "active" : "",
                  ].join(" ")}
                  style={{
                    color: selectedItem === item ? "white" : "",
                  }}
                  {...getItemProps({ item, index })}
                >
                  {renderItem(type, item)}
                </li>
              ))}
          </ul>
        </div>
      </FloatingPortal>
    </>
  );
}
