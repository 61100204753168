import {Content, Header, Page} from "../../components/Page";
import {Block, BlockBody, BlockFooter, BlockHeader, BlockList, BlockListItem} from "../../components/Block";
import {priceCell} from "../../components/Cells";
import {CopyButton} from "../../components/Copy";
import {useAuth} from "../../hooks/useAuth";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {Table} from "../../components/Table";
import {compactNumber} from "../../formatting";

export function ProSettings() {
  const { user } = useAuth();
  const queryClient = useQueryClient();

  const { data: payments, isLoading } = useQuery({
    queryKey: ["user", "payments", user.id],
    queryFn: () => {
      const params = new URLSearchParams();
      params.set("expand", [
        "payments",
      ].join(","));
      return fetch(`/api/auth/me?${params.toString()}`).then((res) => res.json());
    }
  });

  const subscribeMutation = useMutation({
    mutationFn: () => fetch("/api/auth/subscribe", { method: "POST" })
      .then((res) => res.json()),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["user"],
      });
    },
  });

  const unsubscribeMutation = useMutation({
    mutationFn: () => fetch("/api/auth/unsubscribe", { method: "POST" })
      .then((res) => res.json()),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["user"],
      });
    }
  });

  return (
    <Page>
      <Content>
        <Header title={"Pro"} description={
          <>
            Unlock additional features and support the development of this site by becoming
            a Pro user.
            <span className={"text-warning"}>
              &nbsp;Cosmic Ledger is still under heavy development, and its features may change at any time.
              Previously pro-only features may become free, and vice versa as development continues.
            </span> At this early stage, you should consider your subscription a donation to support the development
            of this tool.
          </>
        }/>
        <div className={"row g-4 mb-3"}>
          <div className={"col-12"}>
            <Block>
              <BlockHeader>
                <span className={"display-6"}>Free Plan</span>
              </BlockHeader>
              <BlockList>
                <BlockListItem>
                  Access to most features, free of charge
                </BlockListItem>
                <BlockListItem>
                  Up to 10 characters*
                  <div className={"text-white-50 small"}>
                    *this is currently a soft limit
                  </div>
                </BlockListItem>
                <BlockListItem>
                  Regular priority for API requests
                </BlockListItem>
                <BlockListItem>
                  60 days of trade data retention*
                  <div className={"text-white-50 small"}>
                    *this is currently a soft limit
                  </div>
                </BlockListItem>
              </BlockList>
            </Block>
          </div>
          <div className={"col-12"}>
            <Block>
              <BlockHeader>
                <span className={"display-6"}>Pro Plan</span>
              </BlockHeader>
              <BlockList>
                <BlockListItem>
                  Everything in the free tier; plus
                </BlockListItem>
                <BlockListItem>
                  Unlimited characters
                </BlockListItem>
                <BlockListItem>
                  Discord Webhooks
                </BlockListItem>
                <BlockListItem>
                  High priority for API requests*
                  <div className={"text-white-50 small"}>
                    *This is currently not implemented everywhere
                  </div>
                </BlockListItem>
                <BlockListItem>
                  Unlimited Trade Data Retention
                </BlockListItem>
                <BlockListItem>
                  More coming soon...
                </BlockListItem>
                <BlockFooter>
                  {user.subscription_plan === "pro" ? (
                    user.subscription_end ? (
                      <div className={"text-center"}>
                        <p>
                          A subscription to Pro is active until <span className={"text-warning"}>{new Date(user.subscription_end).toLocaleString()}</span>,
                          after which it will not be automatically renewed.
                        </p>
                        <button
                          className={"btn btn-primary w-100"}
                          onClick={subscribeMutation.mutate}
                          disabled={subscribeMutation.isPending}
                        >
                          Enable Auto-Renewal
                        </button>
                      </div>
                    ) : (
                      <div className={"text-center"}>
                        <p>
                          Your subscription to Pro is active and will be renewed automatically on the 1st
                          of each month. You can cancel your subscription to prevent it from being renewed,
                          but you will still have access to Pro until the end of the current period.
                        </p>
                        <button
                          className={"btn btn-danger w-100"}
                          onClick={unsubscribeMutation.mutate}
                          disabled={unsubscribeMutation.isPending}
                        >
                          Cancel Subscription
                        </button>
                      </div>
                    )
                  ) : (
                    <>
                      <div className={"text-center"}>
                        {subscribeMutation?.data?.error && (
                          <div className={"alert alert-danger"}>
                            {subscribeMutation.data.error}
                            {subscribeMutation?.data?.amount && (
                              <div>
                                You need {compactNumber(subscribeMutation.data.amount)} ISK to subscribe for the
                                rest of the month.
                              </div>
                            )}
                          </div>
                        )}
                        <p>
                          It costs <span className={"text-info"}>150 million ISK/month</span> to subscribe to Pro.
                          When you subscribe, you will only be charged the prorated amount for the remainder of the
                          month.
                        </p>
                        <button
                          className={"btn btn-success w-100"}
                          onClick={subscribeMutation.mutate}
                          disabled={subscribeMutation.isPending}
                        >
                          Subscribe to Pro
                        </button>
                      </div>
                    </>
                  )}
                </BlockFooter>
              </BlockList>
            </Block>
          </div>
        </div>
        <Block>
          <BlockHeader>
            <span className={"fw-bolder"}>Refill ISK Balance</span>
          </BlockHeader>
          <BlockBody>
            To add more money to your account, send ISK to the character
            <CopyButton className={"btn btn-info btn-sm mx-2"} onDoCopy={() => "Cosmic Ledger"}>Cosmic Ledger</CopyButton>
            from any character or corporation with
            <CopyButton className={"btn btn-info btn-sm mx-2"} onDoCopy={() => user.id}>{user.id}</CopyButton>
            in the reason field. Your balance will be updated within 24 hours, typically within the next
            hour as this is the time it takes for the wallet API to update.
            <div className={"mt-4"}>
            </div>
          </BlockBody>
          <Table
            rows={payments?.payments?.sort((a, b) => new Date(a.created_at) < new Date(b.created_at)) || []}
            isLoading={isLoading}
            defaultColumn={{
              enableSorting: false,
              meta: {
                align: "end",
              }
            }}
            columns={[
              {
                accessorKey: "created_at",
                header: "Date",
                cell: (cell) => new Date(cell.getValue()).toLocaleString()
              },
              { accessorKey: "reason", header: "Reason" },
              {
                accessorKey: "amount",
                header: "Amount",
                cell: (cell) => {
                  const amount = cell.getValue();
                  return (
                    <span className={amount > 0 ? "text-success" : "text-danger"}>
                      {priceCell(amount)}
                    </span>
                  );
                }
              },
              {
                accessorKey: "balance",
                header: "Balance",
                cell: (cell) => priceCell(cell.getValue())
              }
            ]}
          />
        </Block>
      </Content>
    </Page>
  );
}