import { useCallback, useMemo } from "react";
import { priceCell, TypeCell } from "../../components/Cells";
import {
  Block,
  BlockBody,
  BlockFooter,
  BlockHeader,
} from "../../components/Block";
import { Link } from "react-router-dom";
import { Table } from "../../components/Table";
import { compactNumber, humanizeRelativeDate } from "../../formatting";
import { Spinner } from "react-bootstrap";
import { CopyButton } from "../../components/Copy";

export function SeedingTarget({
  target,
  onlyNeedsAttention = false,
}) {
  const marketRollupColumns = useMemo(
    () => [
      {
        header: "Item",
        accessorFn: (row) => row.item,
        cell: (cell) => {
          const item = cell.getValue();
          return <TypeCell id={item.id} name={item.name} />;
        },
      },
      {
        header: "Min.",
        accessorFn: (row) => row.min_quantity,
        meta: {
          align: "end",
          tooltipFn: () => "Minimum quantity to stock",
        },
      },
      {
        header: "Qty.",
        accessorFn: (row) => row.in_stock,
        meta: {
          align: "end",
          tooltipFn: () => "Quantity currently in stock",
        },
        cell: (cell) => (
          <div
            className={`${
              cell.getValue() < cell.row.original.min_quantity
                ? "text-danger"
                : null
            }`}
          >
            {cell.getValue()}
          </div>
        ),
      },
      {
        header: "Hub Price",
        accessorFn: (row) => row.hub_price,
        meta: {
          align: "end",
          tooltipFn: () => "Current Jita sell price",
        },
        cell: (cell) => priceCell(cell.getValue(), false),
      },
      {
        header: "Fair Price",
        accessorFn: (row) => row.fair_price,
        meta: {
          align: "end",
          tooltipFn: () => "Recommended price to sell at",
        },
        cell: (cell) => priceCell(cell.getValue(), false),
      },
      {
        header: "Price",
        accessorFn: (row) => row.price,
        meta: {
          align: "end",
          tooltipFn: () => "Current price in this station",
        },
        cell: (cell) => {
          const value = cell.getValue();
          const fair_price = cell.row.original.fair_price;

          if (value > fair_price * 2) {
            return (
              <div className={"text-danger"}>{priceCell(value, false)}</div>
            );
          } else if (value > fair_price) {
            return (
              <div className={"text-warning"}>{priceCell(value, false)}</div>
            );
          } else {
            return (
              <div className={"text-success"}>{priceCell(value, false)}</div>
            );
          }
        },
      },
    ],
    [],
  );

  const rollups = useMemo(() => {
    if (onlyNeedsAttention) {
      return target.rollups.filter((row) => row.needs_attention);
    }

    return target.rollups;
  }, [target.rollups, onlyNeedsAttention]);

  const cash_required = useMemo(() => {
    return rollups.reduce((acc, row) => {
      return (
        acc +
        (row.in_stock < row.min_quantity
          ? row.min_quantity - row.in_stock
          : 0) *
          row.hub_price
      );
    }, 0);
  }, [rollups]);

  const missing_m3 = useMemo(() => {
    return rollups.reduce((acc, row) => {
      return (
        acc +
        (row.in_stock < row.min_quantity
          ? row.min_quantity - row.in_stock
          : 0) *
          row.item.volume
      );
    }, 0);
  }, [rollups]);

  const onCopyList = useCallback(
    (e) => {
      return target.rollups
        .map((row) => {
          return `${row.min_quantity} ${row.item.name}`;
        })
        .join("\n");
    },
    [target],
  );

  // Initial data load is in progress
  if (target.status === 10) {
    return (
      <Block className={"mb-4"}>
        <BlockHeader>
          <strong>{target.list.name}</strong>
        </BlockHeader>
        <BlockBody>
          <div
            className={"d-flex justify-content-center align-items-center p-4"}
          >
            <Spinner animation={"border"} role={"status"} />
            <span className={"ms-2"}>
              Starting the initial setup for this list. This may take a couple
              of second to a few minutes.
            </span>
          </div>
        </BlockBody>
      </Block>
    );
  }

  return (
    <Block className={"mb-4"}>
      <BlockHeader>
        <div className={"d-flex text-white-50"}>
          <div className={"flex-grow-1"}>
            <strong>{target.station.name}</strong>
            &nbsp;-&nbsp;
            <em>
              <Link to={`/seeding/list/${target.list.id}`}>
                {target.list.name}
              </Link>
            </em>
          </div>
          <div className={"btn-group"}>
            <CopyButton
              className={"btn btn-sm btn-outline-secondary"}
              onDoCopy={onCopyList}
            >
              Copy List
            </CopyButton>
          </div>
        </div>
      </BlockHeader>
      <div className={"table-container"}>
        <Table columns={marketRollupColumns} rows={rollups} />
      </div>
      <BlockFooter>
        <div className={"d-flex text-white-50"}>
          <div className={"flex-grow-1"}>
            {missing_m3 > 0 ? (
              <span>
                <span className={"text-info"}>
                  {missing_m3.toFixed(2)} m<sup>3</sup>
                </span>
                &nbsp;and&nbsp;
                <span className={"text-info"}>
                  ~{compactNumber(cash_required)} ISK
                </span>
                &nbsp;required to fully restock station.
              </span>
            ) : null}
          </div>
          <div className={"text-info"}>
            Last updated {humanizeRelativeDate(target.rollups_at)}
          </div>
        </div>
      </BlockFooter>
    </Block>
  );
}
