import React, { useState, useMemo, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { Content, Header } from "../../components/Page";
import { AutocompleteInput } from "../components/AutocompleteInput";
import { useStorage } from "../../hooks/useStorage";
import { Table } from "../../components/Table";
import { FloatingPopup } from "../../components/FloatingPopup";
import { TypeCell } from "../../components/Cells";

export function PIPlanner() {
  const [targetSystems, setTargetSystems] = useStorage("pi.planner.systems", []);
  const [selectedType, setSelectedType] = useStorage("pi.planner.type", "");

  const { data: schematics, isLoading: schematicsLoading } = useQuery({
    queryKey: ["pi", "schematics"],
    queryFn: () => {
      const params = new URLSearchParams();
      params.set("expand", ["output_item", "inputs.item", "pins"].join(","));
      return fetch(`/api/pi/schematics/?${params.toString()}`)
        .then((res) => res.json())
        .then((data) => data.sort((a, b) => a.tier - b.tier));
    },
    initialData: [],
  });

  const { data: systems, isLoading: systemsLoading } = useQuery({
    queryKey: ["pi", "planets", targetSystems.map(s => s.id)],
    queryFn: () => {
      const promises = targetSystems.map(system => {
        const params = new URLSearchParams();
        params.set("expand", ["planets", "planets.item"].join(","));
        return fetch(`/api/universe/systems/${system.id}/?${params.toString()}`).then(res => res.json());
      });
      return Promise.all(promises);
    },
    enabled: targetSystems.length > 0,
  });

  const tree = useMemo(() => {
    if (!schematics) return {};

    const result = {};

    for (const schematic of schematics) {
      result[schematic.output_item.id] = { uses: [], used_by: [] };
      for (const input of schematic.inputs) {
        result[input.item_id] = result[input.item_id] || { uses: [], used_by: [] };
      }
    }

    for (const schematic of schematics) {
      for (const input of schematic.inputs) {
        result[input.item_id].uses.push(schematic.output_item.id);
        result[schematic.output_item.id].used_by.push(input.item_id);
      }
    }

    return result;
  }, [schematics]);

  const hierarchy = useMemo(() => {
    if (!systems || !schematics) return {};

    const allPlanetTypes = new Set();
    systems.forEach(system => {
      system.planets.forEach(planet => {
        allPlanetTypes.add(planet.item.id);
      });
    });

    const unique_resources = new Set();
    for (const schematic of schematics) {
      for (const input of schematic.inputs) {
        if (
          input.only_on_planet_type &&
          input.only_on_planet_type.some((planet_id) =>
            allPlanetTypes.has(planet_id)
          )
        ) {
          unique_resources.add(input.item_id);
        }
      }
    }

    const item_by_id = schematics.reduce((acc, schematic) => {
      for (const input of schematic.inputs) {
        acc[input.item_id] = { id: input.item_id, name: input.name };
      }
      acc[schematic.output_item.id] = {
        id: schematic.output_item.id,
        name: schematic.output_item.name,
      };
      return acc;
    }, {});

    const results = {
      0: unique_resources,
      1: new Set(),
      2: new Set(),
      3: new Set(),
      4: new Set(),
    };

    const allAvailableItems = new Set(unique_resources);

    for (let tier = 1; tier <= 4; tier++) {
      for (const schematic of schematics) {
        if (schematic.tier !== tier) {
          continue;
        }

        if (allAvailableItems.has(schematic.output_item.id)) {
          continue;
        }

        const inputs = schematic.inputs.map((input) => input.item_id);
        const allInputsAvailable = inputs.every((input) =>
          allAvailableItems.has(input)
        );

        if (allInputsAvailable) {
          results[tier].add(schematic.output_item.id);
          allAvailableItems.add(schematic.output_item.id);
        }
      }
    }

    const finalResults = {};
    for (let tier = 0; tier <= 4; tier++) {
      finalResults[tier] = Array.from(results[tier])
        .map((id) => item_by_id[id])
        .filter(Boolean);
    }

    return finalResults;
  }, [systems, schematics]);

  const isUsedBy = useCallback((id) => {
    if (!tree[id]) return false;
    if (tree[id].uses.some((useId) => useId === selectedType)) {
      return true;
    }

    for (const used_by of tree[id].uses) {
      if (isUsedBy(used_by)) {
        return true;
      }
    }

    return false;
  }, [tree, selectedType]);

  const handleSystemChange = (value) => {
    if (value && !targetSystems.some(s => s.id === value.id)) {
      setTargetSystems([...targetSystems, value]);
    }
  };

  const removeSystem = (systemId) => {
    setTargetSystems(targetSystems.filter(s => s.id !== systemId));
  };

  return (
    <Content>
      <Header
        title="PI Planner"
        description="Scout out multiple solar systems for potential PI."
      />
      <div className="alert alert-info">
        This feature is still in development. Double check before making any decisions.
      </div>
      <div className="mb-4 dark">
        <AutocompleteInput
          type="systems"
          onChange={handleSystemChange}
          inputProps={{ placeholder: "Add a solar system..." }}
        />
      </div>
      {targetSystems.length > 0 && (
        <div className="mb-4">
          <h4>Selected Systems:</h4>
          {targetSystems.map(system => (
            <div key={system.id} className="badge bg-secondary me-2 d-inline-flex align-items-center">
              {system.name}
              <button className="btn-close btn-close-white ms-2" onClick={() => removeSystem(system.id)}></button>
            </div>
          ))}
        </div>
      )}
      {systems && (
        <div>
          <h4 className="mb-3">Planets</h4>
          <Table
            defaultColumn={{ enableSorting: false }}
            columns={[
              {
                header: "Planet",
                accessorKey: "name",
                cell: (cell) => {
                  const { item, name } = cell.row.original;
                  return (
                    <div className="d-flex align-items-center">
                      <img
                        src={`https://images.evetech.net/types/${item.id}/icon?size=32`}
                        alt=""
                        className="me-2"
                      />
                      {name}
                    </div>
                  );
                },
              },
              {
                header: "Type",
                accessorKey: "item.name",
                meta: { align: "center" },
              },
              {
                header: "Produces",
                id: "pins",
                cell: (cell) => {
                  const { id: planet_id } = cell.row.original.item;
                  const inputs = [
                    ...new Set(
                      schematics.flatMap((schematic) =>
                        schematic.inputs
                          .filter((input) =>
                            input.only_on_planet_type.includes(planet_id)
                          )
                          .map((input) => input)
                      )
                    ),
                  ];

                  return (
                    <FloatingPopup
                      trigger={
                        <div>
                          {inputs.map((input) => (
                            <img
                              key={input.item_id}
                              src={`https://images.evetech.net/types/${input.item_id}/icon?size=32`}
                              alt={input.name}
                              className="me-2"
                            />
                          ))}
                        </div>
                      }
                      content={
                        <div>
                          {inputs.map((input) => (
                            <div key={input.item_id}>
                              <TypeCell id={input.item_id} name={input.name} />
                              <span className=""> x{input.quantity}</span>
                            </div>
                          ))}
                        </div>
                      }
                    />
                  );
                },
              },
            ]}
            rows={systems.flatMap(system => 
              system.planets.map(planet => ({ ...planet, system: system.name }))
            )}
          />
          <div className="mt-4">
            <h4 className="mb-3">Schematics</h4>
            <p>
              The following planetary commodities can be produced entirely
              within these systems, without requiring any imports.
            </p>
          </div>
          <div className="row row-cols-1 row-cols-lg-2 row-cols-xxl-5 g-4">
            {[0, 1, 2, 3, 4].map((tier) => (
              <div key={tier} className="col">
                <h5 className="mb-3">
                  P{tier} ({hierarchy[tier]?.length || 0} items)
                </h5>
                <div className="list-group">
                  {(!hierarchy[tier] || hierarchy[tier].length === 0) && (
                    <div className="list-group-item text-bg-warning">
                      No schematics available at this tier.
                    </div>
                  )}
                  {hierarchy[tier]?.map((item) => (
                    <div key={item.id} className="list-group-item">
                      <TypeCell id={item.id} name={item.name} />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Content>
  );
}