import React from "react";
import { createRoot } from "react-dom/client";
import {createBrowserRouter, redirect, RouterProvider} from "react-router-dom";
import * as bootstrap from "bootstrap";
import { Login } from "./Login";
import { Logout } from "./Logout";
import { Layout } from "./layout/Layout";
import { Dashboard } from "./pages/schedule/Dashboard";
import { Settings } from "./pages/settings/Settings";
import { Market } from "./pages/market/Market";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./hooks/useAuth";
import { SeedingLayout } from "./pages/seeding/SeedingLayout";
import { Wallet } from "./pages/wallet/Wallet";
import { PIDashboard } from "./pages/pi/PIDashboard";
import { Assets } from "./pages/assets/Assets";
import { System } from "./pages/universe/System";
import { SeedingListForm } from "./pages/seeding/SeededListForm";
import { SeedingRollups } from "./pages/seeding/SeedingRollups";
import { Universe } from "./pages/universe/Universe";
import { Paste } from "./pages/paste/Paste";
import { SeedingListDetails } from "./pages/seeding/SeedingListDetails";
import { ContractList } from "./pages/contracts/ContractList";
import * as Sentry from "@sentry/react";
import { Characters } from "./pages/characters/Characters";
import { PasteViewer } from "./pages/paste/PasteViewer";
import { Trading } from "./pages/wallet/Trading";
import { Transactions } from "./pages/wallet/Transactions";
import { Journal } from "./pages/wallet/Journal";
import { Character } from "./pages/characters/Character";
import { Loyalty } from "./pages/loyalty/Loyalty";
import { LP } from "./pages/wallet/LP";
import { WalletDashboard } from "./pages/wallet/WalletDashboard";
import { SkillsDashboard } from "./pages/characters/SkillsDashboard";
import { CloneDashboard } from "./pages/characters/CloneDashboard";
import { Orders } from "./pages/wallet/Orders";
import { PI } from "./pages/pi/PI";
import { PIPlanner } from "./pages/pi/PIPlanner";
import { PIMarket } from "./pages/pi/PIMarket";
import {Arbitrage} from "./pages/market/Arbitrage";
import {MarketItem} from "./pages/market/MarketItem";
import {MarketLanding} from "./pages/market/MarketLanding";
import {Content, Page} from "./components/Page";
import {ContractPage, ContractViewer} from "./pages/contracts/ContractViewer";
import {StationDemand} from "./pages/market/StationDemand";
import {Fittings} from "./pages/fitting/Fittings";
import {FittingPage} from "./pages/fitting/Fitting";
import {ProSettings} from "./pages/settings/Pro";

Sentry.init({
  dsn: "https://f72185f614ea8c9c252cdcd06e29579d@o4506336801521664.ingest.sentry.io/4506369147731968",
});

const container = document.getElementById("app");
const root = createRoot(
  container,
  {
    onUncaughtError: Sentry.reactErrorHandler(),
    onCaughtError: Sentry.reactErrorHandler(),
    onRecoverableError: Sentry.reactErrorHandler(),
  }
);
const queryClient = new QueryClient();
const routes = createBrowserRouter([
  {
    path: "/",
    children: [
      { path: "login", element: <Login /> },
      { path: "logout", element: <Logout /> },
      { path: "paste/:id", element: (
          <Layout
            unauthenticated={
              <Page>
                <Content>
                  <PasteViewer />
                </Content>
              </Page>
            }
          >
            <Page>
              <Content>
                <PasteViewer />
              </Content>
            </Page>
          </Layout>
        )
      },
      { path: "contracts/:slug", element: (
        <Layout unauthenticated={<ContractPage />}>
          <ContractPage />
        </Layout>
      )},
      {
        element: <Layout/>,
        children: [
          {index: true, element: <Dashboard/>},
          {path: "/dashboard", element: <Dashboard/>},
          {
            path: "/characters",
            element: <Characters/>,
            children: [
              {index: true, element: <SkillsDashboard/>},
              { path: "clones", element: <CloneDashboard/> },
              {
                path: ":id",
                element: <Character/>,
              },
            ],
          },
          {
            path: "/settings",
            children: [{index: true, element: <Settings/>}],
          },
          {
            path: "/market",
            element: <Market/>,
            children: [
              {index: true, element: <MarketLanding/>},
              {
                path: ":id", loader: ({params}) => {
                  return redirect(`/market/${params.id}/orders`);
                }
              },
              {path: ":id/:tab", element: <MarketItem/>},
            ]
          },
          {
            path: "/market/demand",
            element: <StationDemand/>,
          },
          {
            path: "/market/arbitrage",
            element: <Arbitrage/>,
          },
          {
            path: "/wallet",
            element: <Wallet/>,
            children: [
              {index: true, element: <WalletDashboard/>},
              {path: "orders", element: <Orders/>},
              {path: "trading", element: <Trading/>},
              {path: "transactions", element: <Transactions/>},
              {path: "journal", element: <Journal/>},
              {path: "lp", element: <LP/>},
            ],
          },
          {
            path: "/seeding",
            element: <SeedingLayout/>,
            children: [
              {index: true, element: <SeedingRollups/>},
              {
                path: "list",
                children: [
                  {index: true, element: <SeedingListForm/>},
                  {path: ":id", element: <SeedingListDetails/>},
                ],
              },
            ],
          },
          {
            path: "/pi",
            element: <PI/>,
            children: [
              {index: true, element: <PIDashboard/>},
              {path: "planner", element: <PIPlanner/>},
              {path: "markets", element: <PIMarket/>},
            ],
          },
          {
            path: "/assets",
            children: [
              {index: true, element: <Assets/>},
              {path: ":id", element: <Assets/>},
            ],
          },
          {
            path: "/universe",
            children: [
              {index: true, element: <Universe/>},
              {path: "system/:id", element: <System/>},
            ],
          },
          {
            path: "/paste",
            children: [
              {index: true, element: <Paste/>},
            ],
          },
          {
            path: "/contracts",
            children: [
              {index: true, element: <ContractList/>},
              {path: "item_exchange", element: <ContractList/>},
              {path: "auction", element: <ContractList/>},
              {path: "item_exchange", element: <ContractList/>},
            ],
          },
          {
            path: "/loyalty",
            element: <Loyalty/>,
          },
          {
            path: "/fittings",
            element: <Fittings/>,
          },
          {
            path: "/fittings/:id",
            element: <FittingPage />,
          },
          {
            path: "/pro",
            element: <ProSettings />,
          }
        ],
      },
    ]
  },

]);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <RouterProvider router={routes} />
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
