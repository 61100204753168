import { Spinner } from "react-bootstrap";

export function Loading({ children }) {
  return (
    <div className={"text-center py-4"}>
      {children}
      <div>
        <Spinner animation={"border"} role={"status"}>
          <span className={"visually-hidden"}>Loading...</span>
        </Spinner>
      </div>
    </div>
  );
}
