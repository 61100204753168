import React, {useCallback, useMemo, useState} from "react";
import { Table } from "../../components/Table";
import {
  CompactStationLocation,
  IconCell,
  priceCell,
} from "../../components/Cells";
import { compactDateTimeDelta } from "../../formatting";
import { Loading } from "../../components/Loading";
import { OpenInGame } from "../../components/OpenInGame";
import { FairValue, isFairValue, useContracts } from "../../api/useContracts";
import Public from "jsx:../../icons/public.svg";
import { Link } from "react-router-dom";

const EXPAND = [
  "items",
  "items.item",
  "items.item.fair_price",
  "start_location",
  "start_location.system",
  "end_location",
  "end_location.system",
  "found_for_corporation",
];

const FIELDS = [
  "id",
  "slug",
  "date_expired",
  "reward",
  "price",
  "collateral",
  "buyout",
  "title",
  "volume",
  "highest_bid",
  "availability",
  "found_for_corporation.name",
  "found_for_corporation.id",
  "found_for_corporation.alliance_id",
  "items.item",
  "items.quantity",
  "items.is_included",
  "items.is_blueprint_copy",
  "start_location.name",
  "start_location.id",
  "start_location.system.name",
  "start_location.system.id",
  "start_location.system.security",
  "end_location.name",
  "end_location.id",
  "end_location.system.name",
  "end_location.system.id",
  "end_location.system.security",
];

export function ContractTable({ 
  type, 
  filters, 
  sorting, 
  setSorting, 
  url,
  setURL
}) {
  const [openID, setOpenID] = useState(null);

  const { data: contracts, isLoading } = useContracts({
    contractType: type,
    expand: EXPAND,
    fields: FIELDS,
    filters,
    sorting,
    url
  });

  const itemExchangeColumns = useMemo(
    () => [
      {
        header: "",
        accessorKey: "availability",
        meta: {
          align: "center"
        },
        cell: (cell) => {
          const { availability, found_for_corporation } = cell.row.original;
          switch (availability) {
            case "public":
              return (
                <abbr title={"This contract is available to the public."}>
                  <Public className={"icon"} />
                </abbr>
              );
            case "alliance":
              return (
                <abbr title={"This contract is available to alliance members."}>
                  <img src={`https://images.evetech.net/alliances/${found_for_corporation.alliance_id}/logo?size=32`} alt={"Alliance Logo"} />
                </abbr>
              );
            case "corporation":
              return (
                <abbr title={"This contract is available to corporation members."}>
                  <img src={`https://images.evetech.net/corporations/${found_for_corporation.id}/logo?size=32`} alt={"Corporation Logo"} />
                </abbr>
              );
            default:
              return null;
          }
        }
      },
      {
        id: "icon",
        accessorKey: "items",
        header: "",
        enableSorting: false,
        meta: {
          align: "center",
        },
        cell: (cell) => {
          const value = cell.getValue();

          if (value.length === 1 && value[0].item) {
            return (
              <IconCell
                id={value[0].item.id}
                name={value[0].item.name}
                size={32}
              />
            );
          } else {
            return null;
          }
        },
      },
      {
        id: "item",
        accessorKey: "items",
        header: "Items",
        enableSorting: false,
        cell: (cell) => {
          const value = cell.getValue();

          return (
            <div className={"d-flex align-items-center ali"}>
              <div className={"flex-grow-1"}>
                <Link
                  className={"btn btn-link btn-sm text-start"}
                  to={`/contracts/${cell.row.original.slug}/`}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                >
                  {value.length === 1 && value[0].item ? (
                    <>
                      {value[0].item.name}
                      {value[0].quantity > 1 && (
                        <span className={"ms-1 fw-bolder"}>
                          (x{value[0].quantity})
                        </span>
                      )}
                    </>
                  ) : cell.row.original.title ? (
                    <em>{cell.row.original.title}</em>
                  ) : (
                    "[ multiple ]"
                  )}
                </Link>
              </div>
              <div className={"me-2"}>
                {value.length === 1 && value[0].is_blueprint_copy && (
                  <span className={"badge text-bg-info ms-1"}>BPC</span>
                )}
                {value.length > 1 && (
                  <span className={"badge text-bg-secondary ms-1"}>{value.length} items</span>
                )}
              </div>
            </div>
          );
        },
      },
      {
        id: "start_location_id",
        accessorKey: "start_location",
        header: "Location",
        meta: {
          nowrap: true,
        },
        cell: (cell) => {
          const value = cell.getValue();
          if (!value) {
            return (
              <abbr title={"Data for this location is unavailable."}>
                <em>{cell.row.original.start_location_id}</em>
              </abbr>
            );
          }
          return <CompactStationLocation location={value} />;
        },
      },
      {
        accessorKey: "price",
        header: "Price (ISK)",
        meta: {
          align: "end",
          nowrap: true,
        },
        cell: (cell) => {
          let { price, fairPrice, value} = isFairValue(cell.row.original);
          const formattedPrice = fairPrice ? fairPrice.toLocaleString("en") : null;

          switch (value) {
            case FairValue.Fair:
              return (
                <abbr
                  title={`This contract appears to be fairly priced, estimated at ${formattedPrice}`}
                >
                  {priceCell(price)}
                </abbr>
              )
            case FairValue.Underpriced:
              return (
                <abbr
                  className={"text-success"}
                  title={`This contract is underpriced compared to the cost of ${formattedPrice}`}
                >
                  {priceCell(price)}
                </abbr>
              );
            case FairValue.Overpriced:
              return (
                <abbr
                  className={"text-warning"}
                  title={`This contract is overpriced compared to the cost of ${formattedPrice}`}
                >
                  {priceCell(price)}
                </abbr>
              );
            case FairValue.VeryOverpriced:
              return (
                <abbr
                  className={"text-danger"}
                  title={`This contract is very overpriced compared to the cost of ${formattedPrice}`}
                >
                  {priceCell(price)}
                </abbr>
              );
            case FairValue.NotEnoughData:
              return (
                <abbr
                  className={"text-info"}
                  title={"There is not enough data to determine the cost of this contract."}
                >
                  {priceCell(price)}
                </abbr>
              );
            default:
              return priceCell(price);
          }
        },
      },
      {
        accessorKey: "date_expired",
        header: "Expires",
        meta: {
          align: "end",
          nowrap: true,
        },
        cell: (cell) => {
          const value = cell.getValue();

          // Show caution if expiring in the next 24 hours.
          if (new Date(value) < new Date(Date.now() + 12 * 60 * 60 * 1000)) {
            return (
              <abbr
                className={"text-warning"}
                title={
                  "This contract expires soon. You should check it before it expires."
                }
              >
                {compactDateTimeDelta(value)}
              </abbr>
            );
          }
          return compactDateTimeDelta(value);
        },
      },
    ],
    [],
  );

  const courierColumns = useMemo(
    () => [
      itemExchangeColumns[0],
      {
        id: "action",
        header: "",
        meta: {
          nowrap: true,
        },
        cell: (cell) => {
          return (
            <div className={"text-center"}>
              <button
                className={"btn btn-outline-primary btn-sm"}
                onClick={() => setOpenID(cell.row.original.id)}
              >
                Open
              </button>
            </div>
          );
        },
      },
      {
        id: "start_location_id",
        accessorKey: "start_location",
        header: "Start",
        cell: (cell) => {
          const value = cell.getValue();
          if (!value) {
            return (
              <abbr title={"Data for this location is unavailable."}>
                <em>{cell.row.original.start_location_id}</em>
              </abbr>
            );
          }
          return <CompactStationLocation location={value} />;
        },
      },
      {
        accessorKey: "end_location",
        header: "End",
        cell: (cell) => {
          const value = cell.getValue();
          if (!value) {
            return (
              <abbr title={"Data for this location is unavailable."}>
                <em>{cell.row.original.end_location_id}</em>
              </abbr>
            );
          }
          return <CompactStationLocation location={value} />;
        },
      },
      {
        accessorKey: "reward",
        header: "Reward (ISK)",
        meta: {
          align: "end",
        },
        cell: (cell) => priceCell(cell.getValue(), false),
      },
      {
        accessorKey: "collateral",
        header: "Collateral (ISK)",
        meta: {
          align: "end",
        },
        cell: (cell) => priceCell(cell.getValue(), false),
      },
      {
        accessorKey: "date_expired",
        header: "Expires",
        meta: {
          align: "end",
        },
        cell: (cell) => compactDateTimeDelta(cell.getValue()),
      },
    ],
    [],
  );

  const auctionColumns = useMemo(
    () => [
      itemExchangeColumns[0],
      itemExchangeColumns[1],
      itemExchangeColumns[2],
      {
        id: "start_location_id",
        accessorKey: "start_location",
        header: "Location",
        meta: {
          nowrap: true,
        },
        cell: (cell) => {
          const value = cell.getValue();
          if (!value) {
            return (
              <abbr title={"Data for this location is unavailable."}>
                <em>{cell.row.original.start_location_id}</em>
              </abbr>
            );
          }
          return <CompactStationLocation location={value} />;
        },
      },
      {
        accessorKey: "highest_bid",
        header: "Bid (ISK)",
        meta: {
          align: "end",
          nowrap: true,
        },
        cell: (cell) => priceCell(cell.getValue(), false),
      },
      {
        accessorKey: "buyout",
        header: "Buyout (ISK)",
        meta: {
          align: "end",
          nowrap: true,
        },
        cell: (cell) => {
          const value = cell.getValue();
          if (!value) {
            return <em>-</em>;
          }
          return priceCell(cell.getValue(), false);
        },
      },
      {
        accessorKey: "date_expired",
        header: "Expires",
        meta: {
          align: "end",
          nowrap: true,
        },
        cell: (cell) => {
          const value = cell.getValue();

          // Show caution if expiring in the next 24 hours.
          if (new Date(value) < new Date(Date.now() + 12 * 60 * 60 * 1000)) {
            return (
              <abbr
                className={"text-warning"}
                title={
                  "This contract expires soon. You should check it before it expires."
                }
              >
                {compactDateTimeDelta(value)}
              </abbr>
            );
          }
          return compactDateTimeDelta(value);
        },
      },
    ],
    [],
  );


  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="table-container">
        <Table
          rows={contracts.results}
          columns={
            {
              item_exchange: itemExchangeColumns,
              courier: courierColumns,
              auction: auctionColumns,
            }[type]
          }
          sorting={sorting}
          setSorting={setSorting}
          manualSorting={true}
        />
      </div>
      <div className="my-4">
        {contracts.previous && (
          <button
            className="btn btn-primary me-2"
            onClick={() => setURL(contracts.previous)}
          >
            Previous
          </button>
        )}
        {contracts.next && (
          <button
            className="btn btn-primary"
            onClick={() => setURL(contracts.next)}
          >
            Next
          </button>
        )}
      </div>
      {openID && (
        <OpenInGame
          type="contract"
          id={openID}
          onClose={() => setOpenID(null)}
        />
      )}
    </>
  );
}