import {Link, Outlet, useOutlet} from "react-router-dom";
import { MarketTree } from "./MarketTree";
import { useCallback, useState } from "react";
import { Accordion } from "react-bootstrap";
import {
  Bottom,
  CenteredContent,
  Content,
  Page,
  Sidebar,
  Top,
} from "../../components/Page";
import {CopyButton} from "../../components/Copy";
import {useStorage} from "../../hooks/useStorage";

export function Market() {
  const [shoppingList, setShoppingList] = useStorage("market.shoppingList", "");
  const [activeUtilKey, setActiveUtilKey] = useState("0");

  const addToShoppingList = useCallback((item) => {
    setShoppingList((prevValue) => {
      if (prevValue === "") {
        return item;
      }
      return `${item}\n${prevValue}`;
    });
    setActiveUtilKey("1");
  }, [setShoppingList, setActiveUtilKey]);

  return (
    <Page>
      <Sidebar>
        <Top>
          <MarketTree />
        </Top>
        <Bottom>
          <Accordion
            className={"slim"}
            activeKey={activeUtilKey}
            onSelect={setActiveUtilKey}
          >
            <Accordion.Item eventKey={"1"}>
              <Accordion.Header>Shopping List</Accordion.Header>
              <Accordion.Body>
                <textarea
                  className={"form-control"}
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.15)",
                    color: "white",
                  }}
                  rows={3}
                  readOnly={true}
                  value={shoppingList}
                ></textarea>
                <div className={"btn-group w-100 mt-1"}>
                  <CopyButton
                    className={"btn btn-outline-secondary btn-sm"}
                    onDoCopy={() => shoppingList} />
                  <button
                    className={"btn btn-outline-secondary btn-sm"}
                    onClick={() => setShoppingList("")}
                  >
                    Clear
                  </button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Link to={"/market/arbitrage"} className={"btn btn-primary btn-sm w-100 mt-2"}>
            Arbitrage
          </Link>
          <Link to={"/market/demand"} className={"btn btn-primary btn-sm w-100 mt-2"}>
            Station Demand
          </Link>
        </Bottom>
      </Sidebar>
      <Content>
        <Outlet context={{ addToShoppingList }} />
      </Content>
    </Page>
  );
}
