import { Page, Sidebar, Top } from "../../components/Page";
import { NavLink, Outlet } from "react-router-dom";
import React from "react";

export function PI() {
  return (
    <Page>
      <Sidebar>
        <Top>
          <div className={"list-group list-group-flush border-0"}>
            <NavLink
              end
              to={"/pi"}
              className={
                "list-group-item list-group-item-action border-0 d-flex align-items-center"
              }
            >
              <strong>Dashboard</strong>
            </NavLink>
            <NavLink
              end
              to={"/pi/planner"}
              className={
                "list-group-item list-group-item-action border-0 d-flex align-items-center"
              }
            >
              <strong>PI Planner</strong>
            </NavLink>
            <NavLink
              end
              to={"/pi/markets"}
              className={
                "list-group-item list-group-item-action border-0 d-flex align-items-center"
              }
            >
              <strong>PI Markets</strong>
            </NavLink>
          </div>
        </Top>
      </Sidebar>
      <Outlet />
    </Page>
  );
}
