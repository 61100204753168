export function BlockBody({ children }) {
  return <div className={"card-body"}>{children}</div>;
}

export function BlockFooter({ children }) {
  return <div className={"card-footer"}>{children}</div>;
}

export function BlockList({ children }) {
  return <ul className={"list-group list-group-flush"}>{children}</ul>;
}

export function BlockListItem({ className, children }) {
  return (
    <li
      className={`list-group-item d-flex align-items-center justify-content-between ${className}`}
    >
      {children}
    </li>
  );
}
export function BlockLinkListItem({ href, children }) {
  return (
    <a className={"list-group-item list-group-item-action"} href={href}>
      {children}
    </a>
  );
}

export function BlockHeader({ children }) {
  return <div className={"card-header"}>{children}</div>;
}

export function Block({ header, children, className = "" }) {
  return <div className={`card card-block ${className}`}>{children}</div>;
}
