import {useQuery} from "@tanstack/react-query";
import {Table} from "./Table";
import {IconCell, TypeCell} from "./Cells";
import {FloatingPopup} from "./FloatingPopup";
import {Link} from "react-router-dom";

/**
 * Displays a table of the available characters and the current state
 * of their skills compared to the provided list of required skills.
 * @constructor
 */
export function SkillRequirements ({ requiredSkills }) {
  const { data, isLoading } = useQuery({
    queryKey: ["skills_needed", "characters"],
    queryFn: () => {
      const url = new URL("/api/characters/", window.location.origin);
      url.searchParams.set("expand", ["skills", "skills.skill"].join(","));

      return fetch(url).then((res) => res.json());
    },
    initialData: [],
  });

  // We dynamically create 1 column per character
  const columns = [
    {
      header: "Skill",
      accessorKey: "skill",
      cell: (cell) => {
        const skill = cell.getValue();
        return <TypeCell id={skill.id} name={skill.name} />;
      }
    },
    ...data.map((character) => ({
      id: character.id,
      accessorFn: (row) => row[character.id],
      header: () => (
        <FloatingPopup
          trigger={
            <Link to={`/characters/${character.id}`}>
              <IconCell id={character.id} name={character.name }/>
            </Link>
          }
          content={
            <span>{character.name}</span>
          }
        />
      ),
      cell: (cell) => {
        const value = cell.getValue();
        let contents = null;

        if (!value) {
          contents = <span className={"text-danger"}>✘</span>;
        } else if (value.data.active_skill_level >= value.level) {
          contents = <span className={"text-success"}>✔</span>;
        } else {
          contents = (
            <span className={"text-warning"}>
              <sup>{value.data.active_skill_level}</sup>&frasl;<sub>{value.level}</sub>
            </span>
          );
        }

        return (
          <div className={"text-center"}>
            {contents}
          </div>
        )
      }
    })),
  ]

  const rows = requiredSkills.map((skill) => {
    const row = {
      skill: skill.skill,
    }

    data.forEach((character) => {
      const skillData = character.skills.find((s) => s.skill.id === skill.skill.id);

      if (!skillData) {
        row[character.id] = null;
      } else {
        row[character.id] = {
          skill: skill.skill,
          level: skill.level,
          data: skillData,
        };
      }
    });

    return row;
  });

  return (
    <div className={"overflow-x-auto"}>
      <Table
        columns={columns}
        rows={rows}
        isLoading={isLoading}
      />
    </div>
  )
}