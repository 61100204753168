import { useQuery } from "@tanstack/react-query";
import { Table } from "../../components/Table";
import { Modal, Spinner } from "react-bootstrap";
import { useState } from "react";
import {
  characterCell,
  CompactStationLocation,
  priceCell,
  TypeCell,
} from "../../components/Cells";
import { Header } from "../../components/Page";
import { ScopeBar } from "../../components/ScopeBar";
import { FloatingPopup } from "../../components/FloatingPopup";

import Search from "jsx:../../icons/search.svg";
import ShiftRight from "jsx:../../icons/shift-right.svg";
import {applyFiltersToURL, FilterBuilder} from "../../components/FilterBuilder";
import {AutocompleteInput} from "../components/AutocompleteInput";
import {useStorage} from "../../hooks/useStorage";

export function Trading({ showHeader = true }) {
  const [cursor, setCursor] = useState(null);
  const [details, setDetails] = useState(null);
  const [filters, setFilters] = useStorage("trading.filters", []);

  const { data, isLoading } = useQuery({
    queryKey: ["trading", cursor, filters],
    queryFn: () => {
      const params = new URLSearchParams();
      params.set(
        "expand",
        [
          "character",
          "item",
          "location",
          "counterpart",
          "counterpart.character",
          "counterpart.location",
        ].join(","),
      );

      applyFiltersToURL(params, [
        {type: "direction", operator: "==", value: 20},
        ...filters
      ]);

      if (cursor) {
        params.set("cursor", cursor);
      }

      return fetch(`/api/movements/?${params.toString()}`).then((res) =>
        res.json(),
      );
    },
  });

  const columns = [
    {
      header: "Date",
      accessorKey: "when",
      cell: (cell) => {
        const { when } = cell.row.original;
        const date = new Date(when);
        return date.toLocaleDateString();
      },
    },
    {
      header: "Quantity",
      accessorKey: "quantity",
      meta: {
        align: "center",
      },
    },
    {
      header: "Type",
      accessorKey: "item",
      cell: (cell) => {
        const item = cell.getValue();
        return <TypeCell id={item.id} name={item.name} />;
      },
    },
    {
      header: "Buy",
      accessorKey: "counterpart.price",
      meta: {
        align: "center",
      },
      cell: (cell) => {
        const { counterpart } = cell.row.original;

        if (!counterpart) {
          return "-";
        }

        return priceCell(counterpart.price, false);
      },
    },
    {
      header: "Sell",
      accessorKey: "price",
      meta: {
        align: "center",
      },
      cell: (cell) => {
        const { price, counterpart } = cell.row.original;

        if (counterpart && price > counterpart.price) {
          return (
            <span className={"text-success"}>
              {priceCell(price, false)}
            </span>
          )
        } else if (!counterpart) {
          return (
            <abbr className={"text-warning"} title={"No known source price"}>
              {priceCell(price, false)}
            </abbr>
          )
        } else {
          return (
            <span className={"text-danger"}>
              {priceCell(price, false)}
            </span>
          )
        }
      }
    },
    {
      header: "%",
      meta: {
        align: "center",
      },
      cell: (cell) => {
        const { price, counterpart, quantity } = cell.row.original;

        if (!counterpart) {
          return "-";
        }

        const profit = ((price - counterpart.price) / counterpart.price) * 100;

        return (
          <FloatingPopup
            trigger={
              <span className={`text-${profit >= 0 ? "success" : "danger"}`}>
                {profit >= 0 ? "+" : "-"}{Math.abs(profit).toFixed(2)}%
              </span>
            }
            content={
              <dl className={"mb-0 p-2"}>
                <dt>Price Paid</dt>
                <dd>{priceCell(counterpart.price)}</dd>
                <dt>Sold For</dt>
                <dd>{priceCell(price)}</dd>
                <dt>Profit Per Unit</dt>
                <dd>{priceCell(price - counterpart.price)}</dd>
                <dt>Total Profit</dt>
                <dd>{priceCell((price - counterpart.price) * quantity)}</dd>
              </dl>
            }
          />
        );
      },
    },
    {
      header: "",
      id: "actions",
      cell: (cell) => {
        return (
          <button
            className={"btn btn-link"}
            onClick={() => setDetails(cell.row.original)}
          >
            <Search className={"icon sm"} />
          </button>
        );
      },
    },
  ];

  if (isLoading) {
    return (
      <div className={"text-center"}>
        <Spinner animation={"grow"} role={"status"}>
          <span className={"visually-hidden"}>Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <>
      {showHeader && (
        <Header
          title={"Trade Tracker"}
          description={
            <p>
              The <strong>Trade Tracker</strong> view shows all of the market
              transactions that your character has made to sell things you've{" "}
              <strong>bought from the market</strong> or{" "}
              <strong>built with industry</strong>. Items that have been bought
              but not sold yet do not appear here.
            </p>
          }
        />
      )}
      <div className={"mb-4"}>
        <ScopeBar scopes={["esi-wallet.read_character_wallet.v1"]}/>
      </div>
      <div className={"mb-3"}>
        <FilterBuilder
          onChange={setFilters}
          filters={filters}
          allowedFilters={{
            location: {
              label: "Location",
              operators: ["==", "!="],
              component: AutocompleteInput,
              props: {
                type: "location",
              },
            },
            item: {
              label: "Item",
              operators: ["==", "!="],
              component: AutocompleteInput,
              props: {
                type: "type",
              },
            },
            character: {
              label: "Character",
              operators: ["==", "!="],
              component: AutocompleteInput,
              props: {
                type: "character",
              },
            },
          }}
        />
      </div>
      {data.results.length ? (
        <div>
          <Table
            defaultColumn={{enableSorting: false}}
            rows={data.results}
            columns={columns}
          />
          <div className={"my-4"}>
            {data.previous && (
              <button
                className={"btn btn-primary me-2"}
                onClick={() =>
                  setCursor(new URL(data.previous).searchParams.get("cursor"))
                }
              >
                Previous
              </button>
            )}
            {data.next && (
              <button
                className={"btn btn-primary"}
                onClick={() =>
                  setCursor(new URL(data.next).searchParams.get("cursor"))
                }
              >
                Next
              </button>
            )}
          </div>
        </div>
      ) : (
        <div>
          <p className={"alert alert-warning"}>
            You haven't made any trades yet. You can make your first trade by
            buying and selling something from the market.
          </p>
        </div>
      )}
      {details && (
        <Modal show={true} onHide={() => setDetails(null)}>
          <Modal.Body>
            <div>
              {!details.counterpart && (
                <div className={"alert alert-info"}>
                  We aren't sure how this item was sourced. It may have
                  been built or looted.
                </div>
              )}
              <div className={"d-flex flex-row justify-content-between mx-4"}>
                {details.counterpart && (
                  <>
                    <div className={"text-center"}>
                      <h5 className={"border-bottom border-primary pb-2"}>
                        Source
                      </h5>
                      <dl>
                        <dt>Type</dt>
                        <dd>
                          {
                            {
                              10: "Market Order",
                            }[details.counterpart.price_source]
                          }
                        </dd>
                        <dt>Sourced By</dt>
                        <dd>
                          {characterCell(
                            details.counterpart.character.id,
                            details.counterpart.character.name,
                          )}
                        </dd>
                        <dt>Price</dt>
                        <dd>{priceCell(details.counterpart.price)}</dd>
                        <dt>When</dt>
                        <dd>
                          {new Date(details.counterpart.when).toLocaleString()}
                        </dd>
                        <dt>Where</dt>
                        <dd>
                          <CompactStationLocation
                            location={details.counterpart.location}
                          />
                        </dd>
                      </dl>
                    </div>
                    <div className={"d-flex flex-row align-items-center"}>
                      <ShiftRight className={"icon"}/>
                    </div>
                  </>
                )}
                <div className={"text-center"}>
                  <h5 className={"border-bottom border-primary pb-2"}>Sink</h5>
                  <dl>
                    <dt>Type</dt>
                    <dd>
                      {
                        {
                          10: "Market Order",
                        }[details.price_source]
                      }
                    </dd>
                    <dt>Sold By</dt>
                    <dd>
                      {characterCell(
                        details.character.id,
                        details.character.name,
                      )}
                    </dd>
                    <dt>Price</dt>
                    <dd>
                      {details.counterpart && details.price > details.counterpart.price ? (
                        <span className={"text-success"}>
                          {priceCell(details.price)}
                        </span>
                      ) : (
                        <span className={"text-danger"}>
                          {priceCell(details.price)}
                        </span>
                      )}
                    </dd>
                    <dt>When</dt>
                    <dd>{new Date(details.when).toLocaleString()}</dd>
                    <dt>Where</dt>
                    <dd>
                      <CompactStationLocation location={details.location}/>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className={"btn btn-primary w-100"}
              onClick={() => setDetails(null)}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
