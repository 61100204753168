import { Colorizer } from "./Colorizer";
import { Link } from "react-router-dom";
import Warning from "jsx:../icons/warning.svg";
import { isCitadel } from "./Constants";
import { FloatingPopup } from "./FloatingPopup";

export function toPercent(value) {
  return `${(value * 100).toFixed(2)}%`;
}

export function security(value) {
  const sec = Math.max(Number(value), 0.0).toFixed(1);
  return (
    <span className={`${sec <= 0.0 ? "null" : sec < 0.5 ? "low" : "high"}-sec`}>
      {sec}
    </span>
  );
}

export function CompactStationLocation({ location }) {
  const triggerContent = (
    <div className="btn btn-link p-0">{location.system.name}</div>
  );

  const popupContent = (
    <dl className="mb-0 p-2">
      <dt>Station</dt>
      <dd>{location.name}</dd>
    </dl>
  );

  return (
    <>
      {security(location.system.security)}&nbsp;
      {isCitadel(location.id) && (
        <span title="This is a player-owned structure, you may not be able to dock here.">
          <Warning className="icon sm icon-warning" />
          &nbsp;
        </span>
      )}
      <FloatingPopup trigger={triggerContent} content={popupContent} />
    </>
  );
}

export function priceCell(value, show_isk = true) {
  const price = new Intl.NumberFormat("EN", {
    maximumFractionDigits: value > 1000 ? 0 : 2,
  });
  return (
    <div>
      {price.format(value)}
      {show_isk && " ISK"}
    </div>
  );
}

export function characterCell(id, name, colorize = true) {
  return (
    <div className={"d-flex align-items-center"}>
      <img
        src={`https://images.evetech.net/characters/${id}/portrait?size=32`}
        alt={name}
        className={"me-2"}
        style={{
          borderRadius: "8px",
        }}
      />
      {colorize ? <Colorizer value={name}>{name}</Colorizer> : name}
    </div>
  );
}

/**
 * Checks if the given ID belongs to a character.
 * @param {number} id - The ID to check.
 * @returns {boolean} - True if the ID is for a character, false otherwise.
 */
function isCharacter(id) {
  return (
    (id >= 3_000_000 && id <= 4_000_000) ||
    (id >= 90_000_000 && id <= 98_000_000) ||
    (id >= 100_000_000 && id <= 2_100_000_000) ||
    (id >= 2_100_00_00 && id <= 2_147_483_647)
  );
}

/**
 * Checks if the given ID belongs to an item.
 * @param {number} id - The ID to check.
 * @returns {boolean} - True if the ID is for an item, false otherwise.
 */
function isItem(id) {
  return id >= 0 && id <= 10_000;
}

/**
 * Checks if the given ID belongs to a corporation.
 * @param {number} id - The ID to check.
 * @returns {boolean} - True if the ID is for a corporation, false otherwise.
 */
function isCorporation(id) {
  return (
    (id >= 1_000_000 && id <= 2_000_000) ||
    (id >= 98_000_000 && id <= 99_000_000)
  );
}

export function IconCell({
  id,
  name,
  isCopy = false,
  iconSize = 32,
  ...props
}) {
  if (isCharacter(id)) {
    return (
      <img
        src={`https://images.evetech.net/characters/${id}/portrait?size=${iconSize}`}
        alt={name}
        {...props}
      />
    );
  }

  if (isCorporation(id)) {
    return (
      <img
        src={`https://images.evetech.net/corporations/${id}/logo?size=${iconSize}`}
        alt={name}
        {...props}
      />
    );
  }

  if (name.includes("SKIN")) {
    return null;
  }

  if (name.endsWith("Blueprint")) {
    return (
      <img
        src={`https://images.evetech.net/types/${id}/bp${
          isCopy ? "c" : ""
        }?size=${iconSize}`}
        alt={""}
        {...props}
      />
    );
  }

  return (
    <img
      src={`https://images.evetech.net/types/${id}/icon?size=${iconSize}`}
      alt={""}
      {...props}
    />
  );
}

export function TypeCell({ id, name, iconOnly = false, iconSize = 32 }) {
  return (
    <div className={"d-inline-flex align-items-center"}>
      <Link to={`/market/${id}`} className={"me-2"}>
        <IconCell id={id} name={name} iconSize={iconSize} className={"me-2"} />
        {!iconOnly && name}
      </Link>
    </div>
  );
}

export function typeLink(id, name) {
  return <Link to={`/market/${id}`}>{name}</Link>;
}
