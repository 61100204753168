import { useStorage } from "../../hooks/useStorage";
import { useQuery } from "@tanstack/react-query";
import { AggregatesChart } from "../../components/AggregatesChart";
import { Table } from "../../components/Table";
import { Link } from "react-router-dom";
import { IconCell } from "../../components/Cells";
import React from "react";
import { Content, Header } from "../../components/Page";
import { ScopeBar } from "../../components/ScopeBar";
import Skill from "jsx:../../icons/skill.svg";

export function SkillsDashboard() {
  const [sorting, setSorting] = useStorage("characters.skills.sorting", [
    {
      id: "total_sp",
      desc: true,
    },
  ]);
  const { data: sp, isLoading: spLoading } = useQuery({
    queryKey: ["characters", "sp"],
    queryFn: () => {
      const params = new URLSearchParams();
      params.set(
        "fields",
        ["id", "name", "total_sp", "unallocated_sp", "skill_queue"].join(","),
      );
      params.set("expand", ["skill_queue"].join(","));
      return fetch(`/api/characters/?${params.toString()}`).then((res) =>
        res.json(),
      );
    },
    initialData: [],
  });

  return (
    <Content>
      <Header title={
        <>
          <Skill className={"icon me-2"} />
          Skills Dashboard
        </>
      } />
      <div className="mb-4">
        <ScopeBar
          optional={[
            "esi-clones.read_implants.v1",
            "esi-skills.read_skills.v1",
            "esi-skills.read_skillqueue.v1",
            "esi-location.read_location.v1",
          ]}
        />
      </div>
      <div className={"mb-4"}>
        <h3 className={"mb-4"}>Over Time</h3>
        <AggregatesChart dataKey={"total_sp"} label={"Total Skill Points"} />
      </div>
      <div className={"mb-4"}>
        <h3 className={"mb-4"}>By Character</h3>
        <Table
          rows={sp}
          sorting={sorting}
          setSorting={setSorting}
          defaultColumn={{
            enableSorting: false,
          }}
          columns={[
            {
              accessorFn: (row) => ({
                id: row.id,
                name: row.name,
              }),
              header: "Character",
              cell: (cell) => {
                const value = cell.getValue();
                return (
                  <Link to={`/characters/character/${value.id}`}>
                    <IconCell id={value.id} name={value.name} />
                    <span className={"ms-2"}>{value.name}</span>
                  </Link>
                );
              },
            },
            {
              accessorKey: "total_sp",
              header: "Total (SP)",
              enableSorting: true,
              meta: { align: "center" },
            },
            {
              accessorKey: "unallocated_sp",
              header: "Unallocated (SP)",
              enableSorting: true,
              meta: { align: "center" },
            },
            {
              accessorKey: "skill_queue",
              header: "SP/min*",
              cell: (cell) => {
                const value = cell.getValue();

                for (const skill of value) {
                  if (!skill.finish_date || !skill.start_date) {
                    return "Paused";
                  }

                  const finish_date = new Date(skill.finish_date);
                  const start_date = new Date(skill.start_date);

                  if (finish_date < new Date()) {
                    continue;
                  }

                  const elapsed = finish_date - start_date;
                  const elapsed_hours = elapsed / 1000 / 60 / 60;
                  const sp = skill.level_end_sp - skill.training_start_sp;

                  const sp_per_minute = Math.round(sp / elapsed_hours / 60);

                  if (sp_per_minute <= 30) {
                    return (
                      <span className={"text-danger"}>{sp_per_minute}</span>
                    );
                  } else if (sp_per_minute <= 35) {
                    return (
                      <span className={"text-warning"}>{sp_per_minute}</span>
                    );
                  } else {
                    return sp_per_minute;
                  }
                }

                return "Paused";
              },
              meta: { align: "center" },
            },
          ]}
        />
        <div className={"py-2 small text-end"}>
          <sup>*</sup> Estimated based on current skill queue
        </div>
      </div>
    </Content>
  );
}
