import { useQuery } from "@tanstack/react-query";
import { Table } from "../../components/Table";
import { useEffect, useMemo, useState } from "react";
import {
  characterCell,
  CompactStationLocation,
  IconCell,
} from "../../components/Cells";
import { Link } from "react-router-dom";
import { ScopeBar } from "../../components/ScopeBar";
import { useDebounce } from "../../hooks/useDebounce";
import { Modal } from "react-bootstrap";
import Open from "jsx:../../icons/open.svg";
import Parent from "jsx:../../icons/parent.svg";
import { Content, Header, Page } from "../../components/Page";
import {
  applyFiltersToURL,
  FilterBuilder,
} from "../../components/FilterBuilder";
import { Loading } from "../../components/Loading";
import { AutocompleteInput } from "../components/AutocompleteInput";
import { useStorage } from "../../hooks/useStorage";

export function AssetsPopover({ assetId, onHide }) {
  const { data: asset, isLoading } = useQuery({
    queryKey: ["assets", "get", assetId],
    queryFn: () => {
      const url = new URL(`/api/assets/${assetId}/`, window.location.origin);
      url.searchParams.set(
        "expand",
        ["item", "item.fair_price", "location", "character"].join(","),
      );

      return fetch(url).then((res) => res.json());
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Modal show={true} onHide={onHide} fullscreen={true}>
      <Modal.Body>
        <div className={"d-flex align-items-center"}>
          <img
            src={`https://images.evetech.net/types/${asset.item.id}/icon?size=64`}
            alt={""}
            className={"img-fluid rounded"}
          />
          <div className={"ms-3 flex-grow-1"}>
            {asset.name && (
              <div>
                <em>{asset.name}</em>
              </div>
            )}
            <div>
              <Link to={`/market/${asset.item.id}`}>{asset.item.name}</Link>
            </div>
          </div>
          {asset.location && (
            <div className={"text-end"}>
              {asset.location.system && (
                <div>
                  <Link to={`/universe/system/${asset.location.system.id}/`}>
                    {asset.location.system.name}
                  </Link>
                </div>
              )}
              <div>{asset.location.name}</div>
            </div>
          )}
        </div>
        <AssetsTable
          filters={[
            {
              type: "parent",
              operator: "==",
              value: asset.asset_id,
            },
          ]}
          inAContainer={true}
        />
      </Modal.Body>
      <Modal.Footer>
        <button className={"btn btn-primary w-100"} onClick={onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export function AssetsTable({
  filters,
  inAContainer = false,
  defaultParentID = null,
  search = null,
  hideIfEmpty = false,
}) {
  const [cursor, setCursor] = useState(null);
  const [child, setChild] = useState(null);

  const url = useMemo(() => {
    const params = new URLSearchParams();

    if (filters) {
      applyFiltersToURL(params, filters);
    }

    if (cursor) {
      params.set("cursor", cursor);
    }

    if (search) {
      params.set("search", search);
    }

    params.set(
      "expand",
      ["item", "item.fair_price", "location", "character"].join(","),
    );

    return `/api/assets/?${params.toString()}`;
  }, [cursor, search, filters]);

  useEffect(() => {
    setCursor(null);
  }, [filters, search]);

  const { data, isLoading } = useQuery({
    queryKey: ["assets", url],
    queryFn: () => {
      return fetch(url).then((res) => res.json());
    },
  });

  const columns = useMemo(
    () => [
      {
        header: "Item",
        accessorKey: "item",
        cell: (cell) => {
          const item = cell.getValue();
          const original = cell.row.original;
          const isContainer = original.is_singleton && item.capacity;

          return (
            <div className={"d-flex"}>
              <div className={"flex-grow-1"}>
                <IconCell
                  id={item.id}
                  name={item.name}
                  iconSize={32}
                  isCopy={original.is_blueprint_copy}
                />
                <span className={"ms-2"}>
                  <Link to={`/market/${item.id}`}>
                    {original.name || item.name}
                  </Link>
                  {cell.row.original.is_blueprint_copy && (
                    <span className={"ms-2 badge bg-secondary"}>Copy</span>
                  )}
                </span>
              </div>
              {isContainer ? (
                <button
                  title={"Open container"}
                  className={"btn btn-sm btn-outline-secondary"}
                  onClick={() => setChild(cell.row.original.asset_id)}
                >
                  <Open className={"icon sm"} />
                </button>
              ) : null}
              {original.parent && !inAContainer && !original.in_a_hangar ? (
                <button
                  title={"Open parent"}
                  className={"btn btn-sm btn-outline-secondary ms-1"}
                  onClick={() => setChild(cell.row.original.parent)}
                >
                  <Parent className={"icon sm"} />
                </button>
              ) : null}
            </div>
          );
        },
      },
      {
        header: "Character",
        accessorKey: "character",
        cell: (cell) => {
          const character = cell.getValue();
          return characterCell(character.id, character.name);
        },
      },
      {
        header: "Quantity",
        accessorKey: "quantity",
        meta: {
          align: "end",
        },
      },
      {
        header: "Location",
        accessorKey: "location",
        meta: {
          align: "end",
        },
        cell: (cell) => {
          const location = cell.getValue();
          const original = cell.row.original;

          if (!defaultParentID && location) {
            return <CompactStationLocation location={location} />;
          } else {
            return <em>{original.location_flag.name}</em>;
          }
        },
      },
    ],
    [data, defaultParentID],
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {!data.results.length ? (
        hideIfEmpty ? null : (
          <div className={"alert alert-info"}>
            No matching assets found. You don't appear to own any assets that
            match the filters you've set.
          </div>
        )
      ) : (
        <div className={"table-container"}>
          <Table rows={data.results} columns={columns} />
        </div>
      )}
      <div className={"my-4"}>
        {data.previous && (
          <button
            className={"btn btn-primary me-2"}
            onClick={() =>
              setCursor(new URL(data.previous).searchParams.get("cursor"))
            }
          >
            Previous
          </button>
        )}
        {data.next && (
          <button
            className={"btn btn-primary"}
            onClick={() =>
              setCursor(new URL(data.next).searchParams.get("cursor"))
            }
          >
            Next
          </button>
        )}
      </div>
      {child && <AssetsPopover assetId={child} onHide={() => setChild(null)} />}
    </>
  );
}

export function Assets() {
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [filters, setFilters] = useStorage("assets.filters", []);

  const debounceSearch = useDebounce(() => {
    setSearch(searchInput);
  });

  return (
    <Page>
      <Content>
        <Header
          title={"Assets"}
          description={
            <>
              The assets page provides an overview of all inventory assets
              across all of your
              <span className={"text-info"}> characters</span> and
              <span className={"text-info"}> corporations.</span>
            </>
          }
        />
        <div className={"mb-3"}>
          <ScopeBar scopes={["esi-assets.read_assets.v1"]} />
        </div>
        <div className={"mt-3 mb-2 dark"}>
          <div className={"input-group"}>
            <input
              type={"text"}
              className={"form-control"}
              placeholder={"Type to search..."}
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
                debounceSearch();
              }}
            />
          </div>
        </div>
        <div className={"my-3"}>
          <FilterBuilder
            onChange={(newFilters) => setFilters(newFilters)}
            filters={filters}
            allowedFilters={{
              price: {
                label: "Price",
                operators: ["==", "!=", ">>", "<<", ">=", "<="],
              },
              type: {
                label: "Type",
                operators: ["==", "!="],
                component: AutocompleteInput,
                props: {
                  type: "type",
                },
              },
              location: {
                label: "Location",
                operators: ["==", "!="],
                component: AutocompleteInput,
                props: {
                  type: "location",
                },
              },
              group: {
                label: "Group",
                operators: ["&&", "!&"],
                component: AutocompleteInput,
                props: {
                  type: "group",
                },
              },
              character: {
                label: "Character",
                operators: ["==", "!="],
                component: AutocompleteInput,
                props: {
                  type: "character",
                },
              },
            }}
          />
        </div>
        <AssetsTable filters={filters} search={search} />
      </Content>
    </Page>
  );
}
