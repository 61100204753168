import { SeededStationList } from "./SeededStationList";

export function SeedingRollups() {
  return (
    <>
      <div className={"row"}>
        <div className={"col"}>
          <h4>Dashboard</h4>
          <p>
            Item lists are a powerful tool for seeding and stockpiling. They
            allow you to specify a list of items with{" "}
            <span className={"text-info"}>minimum quantities</span> that should
            always exist at one or more stations.
          </p>
          <p>
            This dashboard will only show you items which need attention, either
            because of low stock or being excessively overpriced.
          </p>
        </div>
      </div>
      <div className={"row"}>
        <div className={"col"}>
          <SeededStationList />
        </div>
      </div>
    </>
  );
}
