import { Content, Header } from "../../components/Page";
import Clone from "jsx:../../icons/clone.svg";
import { useQuery } from "@tanstack/react-query";
import { useStorage } from "../../hooks/useStorage";
import { Table } from "../../components/Table";
import {
  characterCell,
  CompactStationLocation,
  IconCell,
  TypeCell,
} from "../../components/Cells";
import { FloatingPopup } from "../../components/FloatingPopup";
import { ScopeBar } from "../../components/ScopeBar";

export function ClonesTable({ filters }) {
  const [sorting, setSorting] = useStorage("characters.clones.sorting", [
    {
      id: "name",
      desc: false,
    },
  ]);
  const { data, isLoading } = useQuery({
    queryKey: ["characters", "clones"],
    queryFn: () => {
      const params = new URLSearchParams();
      params.set(
        "fields",
        [
          "id",
          "name",
          "location",
          "clones.jump_clone_id",
          "clones.location.name",
          "clones.location.id",
          "clones.location.system.name",
          "clones.location.system.id",
          "clones.location.system.security",
          "clones.implants.name",
          "clones.implants.id",
        ].join(","),
      );
      params.set(
        "expand",
        [
          "location",
          "clones",
          "clones.implants",
          "clones.location",
          "clones.location.system",
        ].join(","),
      );
      return fetch(`/api/characters/?${params.toString()}`)
        .then((res) => res.json())
        .then((data) => {
          // We want to unroll each clone into a separate row along with the character information.
          const x = data.flatMap((character) => {
            return character.clones.map((clone) => {
              return {
                name: character.name,
                id: character.id,
                location: character.location,
                clone,
              };
            });
          });
          return x;
        });
    },
  });

  return (
    <>
      <div className={"mb-4"}>
        <ScopeBar
          optional={[
            "esi-clones.read_implants.v1",
            "esi-location.read_location.v1",
          ]}
        />
      </div>
      <Table
        isLoading={isLoading}
        rows={data || []}
        sorting={sorting}
        setSorting={setSorting}
        columns={[
          {
            header: "Character",
            accessorKey: "name",
            cell: (cell) => {
              const { id, name, clone } = cell.row.original;
              return (
                <div className={"d-flex align-items-center"}>
                  <div className={"flex-grow-1"}>
                    {characterCell(id, name)}
                  </div>
                  {clone.jump_clone_id === 0 && (
                    <div className={"me-2"}>
                      <span className={"badge bg-primary ms-2"}>Active Clone</span>
                    </div>
                  )}
                </div>
              )
            },
          },
          {
            header: "Location",
            accessorKey: "clone.location.id",
            cell: (cell) => {
              const { location } = cell.row.original.clone;
              if (!location) {
                return <em>Unknown</em>;
              }
              return <CompactStationLocation location={location} />;
            },
          },
          {
            header: "Implants",
            accessorKey: "clone.implants",
            enableSorting: false,
            cell: (cell) => {
              const implants = cell.getValue();
              if (!implants) {
                return <em>-</em>;
              }
              return (
                <FloatingPopup
                  trigger={
                    <>
                      {implants.map((implant) => (
                        <IconCell
                          key={implant.id}
                          id={implant.id}
                          name={implant.name}
                        />
                      ))}
                    </>
                  }
                  content={
                    <div>
                      {implants.map((implant) => (
                        <div key={implant.id}>
                          <TypeCell {...implant} />
                        </div>
                      ))}
                    </div>
                  }
                />
              );
            },
          },
        ]}
      />
    </>
  );
}

export function CloneDashboard() {
  const [filters, setFilters] = useStorage("characters.clone.filters", []);

  return (
    <Content>
      <Header
        title={
          <>
            <Clone className={"icon me-2"} />
            Clone Dashboard
          </>
        }
      />
      <ClonesTable filters={filters} />
    </Content>
  );
}
