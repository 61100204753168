import {ContractTable} from "../contracts/ContractTable";
import {useStorage} from "../../hooks/useStorage";
import {useState} from "react";
import {Link} from "react-router-dom";

export function MarketContractsTab({ marketId }) {
  const [sorting, setSorting] = useStorage("contracts.sorting", [
    { id: "price", desc: false },
  ]);
  const [url, setURL] = useState(null);

  return (
    <div className={"mt-4"}>
      <p>
        This table shows you all contracts that are available for this item on
        the public contracts market. Go to the <Link to={"/contracts"}>contracts</Link> page
        for advanced filtering and searching.
      </p>
      <ContractTable
        type={"item_exchange"}
        sorting={sorting}
        setSorting={setSorting}
        url={url}
        setURL={setURL}
        filters={[
          {
            type: "type",
            value: {
              id: marketId,
            },
            operator: "==",
          },
        ]}
      />
    </div>
  );
}