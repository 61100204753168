import { Modal } from "react-bootstrap";
import { useAuth } from "../hooks/useAuth";
import { useMutation } from "@tanstack/react-query";

export function OpenInGame({ type, id, onClose }) {
  const { user } = useAuth();

  const open = useMutation({
    mutationKey: ["open", type, id],
    mutationFn: ({ characterID }) => {
      const params = new URLSearchParams();
      params.set("type", type);
      params.set("id", id);
      params.set("character_id", characterID);

      return fetch(`/api/open?${params.toString()}`, {
        method: "POST",
      }).then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        onClose();
      });
    },
  });

  const charactersWithScope = user?.characters?.filter((character) =>
    character.scopes.includes("esi-ui.open_window.v1"),
  );

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Body>
        {charactersWithScope?.length ? (
          <>
            <p>Select a character to open this in-game.</p>
            <ul className={"list-group list-group-flush"}>
              {charactersWithScope.map((character) => (
                <button
                  key={character.id}
                  className={[
                    "list-group-item",
                    "list-group-item-action",
                    "d-flex",
                    "align-items-center",
                    "border-0",
                  ].join(" ")}
                  onClick={() => open.mutate({ characterID: character.character })}
                  disabled={open.isPending}
                >
                  <img
                    src={`https://images.evetech.net/characters/${character.character}/portrait?size=32`}
                    alt={character.name}
                    className={"me-2"}
                  />
                  {open.isPending ? (
                    <div
                      className={
                        "spinner-border spinner-border-sm me-2 text-success"
                      }
                      role={"status"}
                    >
                      <span className={"visually-hidden"}>Loading...</span>
                    </div>
                  ) : (
                    character.name
                  )}
                </button>
              ))}
            </ul>
          </>
        ) : (
          <div className={"text-center"}>
            <p className={"text-white-50"}>
              You do not have any characters with the required scope to open
              this in-game. Authorize at least one character with the{" "}
              <code>Open Windows in-game</code> permission.
            </p>
          </div>
        )}
        <button className={"btn btn-primary w-100 mt-3"} onClick={onClose}>
          Close
        </button>
      </Modal.Body>
    </Modal>
  );
}
