import { useState } from "react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { characterCell, priceCell, TypeCell } from "../../components/Cells";
import { OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import { Table } from "../../components/Table";
import { Header } from "../../components/Page";
import { ScopeBar } from "../../components/ScopeBar";
import { Loading } from "../../components/Loading";

const columns = [
  {
    header: "Character",
    accessorKey: "character",
    cell: (cell) => {
      const character = cell.getValue();
      return characterCell(character.id, character.name);
    },
  },
  {
    header: "Date",
    accessorKey: "date",
    cell: (cell) => {
      const value = cell.getValue();
      const date = new Date(value);
      const now = new Date();

      if (date.toDateString() === now.toDateString()) {
        return date.toLocaleTimeString();
      }
      return date.toLocaleDateString();
    },
  },
  {
    header: "Quantity",
    accessorKey: "quantity",
    meta: {
      align: "end",
    },
  },
  {
    header: "Type",
    accessorKey: "item",
    cell: (cell) => {
      const item = cell.getValue();
      return <TypeCell id={item.id} name={item.name} />;
    },
  },
  {
    header: "Total (ISK)",
    accessorFn: (row) => row.quantity * row.unit_price,
    meta: {
      align: "end",
    },
    cell: (cell) => {
      const value = cell.getValue();
      const original = cell.row.original;

      return (
        <OverlayTrigger
          overlay={
            <Popover>
              <Popover.Body>
                <dl>
                  <dt>Per Unit Price</dt>
                  <dd>{priceCell(original.unit_price)}</dd>
                </dl>
              </Popover.Body>
            </Popover>
          }
        >
          <span
            className={[
              original.is_buy ? "text-danger" : "text-success",
              "cursor-pointer",
            ].join(" ")}
          >
            {priceCell(value, false)}
          </span>
        </OverlayTrigger>
      );
    },
  },
];
export function Transactions() {
  const [cursor, setCursor] = useState(null);

  const { data, isLoading } = useQuery({
    queryKey: ["transactions", cursor],
    queryFn: async () => {
      const url = new URL("/api/transactions/", window.location.origin);
      url.searchParams.set("expand", ["character", "item"].join(","));

      url.searchParams.set(
        "fields",
        [
          "id",
          "is_buy",
          "client_id",
          "quantity",
          "date",
          "item.id",
          "item.name",
          "character.id",
          "character.name",
          "unit_price",
        ].join(","),
      );

      if (cursor) {
        url.searchParams.append("cursor", cursor);
      }

      const response = await fetch(url);
      return response.json();
    },
    placeholderData: keepPreviousData,
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Header
        title={"Transactions"}
        description={
          <p>
            Transactions are the records of all the items you have bought and
            sold on the market.
          </p>
        }
      />
      <div className={"mb-4"}>
        <ScopeBar scopes={["esi-wallet.read_character_wallet.v1"]} />
      </div>
      <Table rows={data.results} columns={columns} />
      <div className={"my-4"}>
        {data.previous && (
          <button
            className={"btn btn-primary me-2"}
            onClick={() =>
              setCursor(new URL(data.previous).searchParams.get("cursor"))
            }
          >
            Previous
          </button>
        )}
        {data.next && (
          <button
            className={"btn btn-primary"}
            onClick={() =>
              setCursor(new URL(data.next).searchParams.get("cursor"))
            }
          >
            Next
          </button>
        )}
      </div>
    </>
  );
}
