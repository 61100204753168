import React, { useCallback, useMemo } from "react";
import { Form } from "react-bootstrap";

const OperatorLabels = {
  "==": "is",
  "!=": "is not",
  ">>": "greater than",
  "<<": "less than",
  ">=": "greater than or equal to",
  "<=": "less than or equal to",
  "&&": "in",
  "!&": "not in",
  "~~": "contains",
  "!~": "does not contain",
};

const ControlWrapper = ({ value, onChange, ...props }) => (
  <Form.Control
    value={value}
    onChange={(e) => onChange(e.target.value)}
    {...props}
  />
);

const FilterRow = ({ filter, index, onChange, onRemove, allowedFilters }) => {
  const handleTypeChange = useCallback(
    (e) => {
      const newType = e.target.value;
      onChange(index, {
        type: newType,
        operator: allowedFilters[newType].operators[0],
        value: "",
      });
    },
    [index, onChange, allowedFilters],
  );

  const handleOperatorChange = useCallback(
    (e) => {
      onChange(index, { ...filter, operator: e.target.value });
    },
    [index, onChange, filter],
  );

  const handleValueChange = useCallback(
    (value) => {
      onChange(index, { ...filter, value: value?.target?.value || value });
    },
    [index, onChange, filter],
  );

  if (!allowedFilters[filter.type]) return null;

  const InputComponent =
    allowedFilters[filter.type].component || ControlWrapper;

  return (
    <div className="row mb-3 dark g-2">
      <div className="col col-md-3">
        <Form.Select value={filter.type} onChange={handleTypeChange}>
          {Object.entries(allowedFilters).map(([key, value]) => (
            <option key={key} value={key}>
              {value.label}
            </option>
          ))}
        </Form.Select>
      </div>
      <div className="col col-md-2">
        <Form.Select value={filter.operator} onChange={handleOperatorChange}>
          {allowedFilters[filter.type].operators.map((operator) => (
            <option key={operator} value={operator}>
              {OperatorLabels[operator]}
            </option>
          ))}
        </Form.Select>
      </div>
      <div className="col col-md-5">
        <InputComponent
          key={filter.type}
          value={filter.value}
          onChange={handleValueChange}
          {...allowedFilters[filter.type].props}
        />
      </div>
      <div className="col-12 col-md-2">
        <button
          className="btn btn-outline-danger w-100"
          onClick={() => onRemove(index)}
        >
          Remove
        </button>
      </div>
    </div>
  );
};

export const FilterBuilder = ({
  filters,
  onChange,
  allowedFilters = {},
  maxFilters = 5,
}) => {
  const handleAddFilter = useCallback(() => {
    if (filters.length >= maxFilters) return;
    const defaultFilterType = Object.keys(allowedFilters)[0];
    onChange([
      ...filters,
      {
        type: defaultFilterType,
        operator: allowedFilters[defaultFilterType].operators[0],
        value: "",
      },
    ]);
  }, [filters, onChange, allowedFilters, maxFilters]);

  const handleRemoveFilter = useCallback(
    (index) => {
      onChange(filters.filter((_, i) => i !== index));
    },
    [filters, onChange],
  );

  const handleFilterChange = useCallback(
    (index, newFilter) => {
      const newFilters = [...filters];
      newFilters[index] = newFilter;
      onChange(newFilters);
    },
    [filters, onChange],
  );

  const filterRows = useMemo(
    () =>
      filters.map((filter, index) => (
        <FilterRow
          key={index}
          filter={filter}
          index={index}
          onChange={handleFilterChange}
          onRemove={handleRemoveFilter}
          allowedFilters={allowedFilters}
        />
      )),
    [filters, handleFilterChange, handleRemoveFilter, allowedFilters],
  );

  return (
    <div>
      {filterRows}
      <button
        className="btn btn-outline-primary mb-3"
        onClick={handleAddFilter}
        disabled={filters.length >= maxFilters}
      >
        Add Filter
      </button>
      {filters.length >= maxFilters && (
        <div className="mt-2">
          <small className="text-danger">
            You can only have {maxFilters} filters at a time.
          </small>
        </div>
      )}
    </div>
  );
};

export const applyFiltersToURL = (params, filters) => {
  if (!filters) return;

  filters.forEach((filter) => {
    if (filter.value) {
      const operator = filter.operator || "==";
      const value = filter.value?.id || filter.value;
      params.append(filter.type, `${operator}${value}`);
    }
  });
};
export const applySortingToURL = (params, sorting) => {
  if (!sorting) return;

  params.set(
    "ordering",
    sorting.map((sort) => `${sort.desc ? "-" : ""}${sort.id}`).join(",")
  );
};
