import { useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Loading } from "../../components/Loading";

function TreeNode({ node, expanded = false }) {
  const [isExpanded, setIsExpanded] = useState(expanded);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  const onClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <li key={node.i} className={"tree-node"}>
      <div onClick={onClick} className={"label"}>
        <span
          className={`caret-${isExpanded ? "expanded" : "collapsed"}`}
        ></span>
        {node.n}
      </div>
      {node.c && isExpanded && (
        <ul
          className={`${isExpanded ? "expanded" : "collapsed"} text-truncate`}
        >
          {node.c.map((child) => (
            <TreeNode key={child.i} node={child} />
          ))}
        </ul>
      )}
      {node.t && isExpanded && (
        <ul className={`${isExpanded ? "expanded" : "collapsed"}`}>
          {Object.entries(node.t).map(([key, value]) => (
            <li key={key} className={"text-truncate tree-leaf"} title={value}>
              <Link to={`/market/${key}`} className={"d-block"}>
                {value}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
}

export function MarketTree() {
  const [search, setSearch] = useState("");

  const { data, isLoading } = useQuery({
    queryKey: ["market"],
    queryFn: () =>
      fetch("/api/market/tree/").then((res) => res.json()),
  });

  // The entire tree is very slow to render, so we only render the
  // visible nodes when the data actually changes.
  const renderedTreeNodes = useMemo(() => {
    if (!data) {
      return null;
    }
    return data.map((node) => <TreeNode key={node.i} node={node} />);
  }, [data]);

  const renderedSearchNodes = useMemo(() => {
    // Recursively search "data" for matching leaf nodes.
    const searchTree = (data, search) => {
      const results = [];
      for (const node of data) {
        if (node.c) {
          results.push(...searchTree(node.c, search));
        }
        if (node.t) {
          for (const [key, value] of Object.entries(node.t)) {
            if (value.toLowerCase().includes(search.toLowerCase())) {
              results.push(
                <li key={key} className={"text-truncate"} title={value}>
                  <Link to={`/market/${key}`} className={"d-block"}>
                    {value}
                  </Link>
                </li>,
              );
            }
          }
        }
      }
      return results;
    };
    if (!data) {
      return null;
    }
    return searchTree(data, search);
  }, [search]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className={"dark"}>
        <input
          type={"text"}
          className={"form-control"}
          placeholder={"Search"}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      {search && search.length > 2 && (
        <ul className={"tree font-monospace mt-2"}>{renderedSearchNodes}</ul>
      )}
      <ul
        className={`tree font-monospace mt-2 ${
          search && search.length > 2 ? "invisible d-none" : "visible"
        }`}
      >
        {renderedTreeNodes}
      </ul>
    </>
  );
}
