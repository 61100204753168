import { Content, Header } from "../../components/Page";
import { Table } from "../../components/Table";
import { IconCell, priceCell, TypeCell } from "../../components/Cells";
import { FloatingPopup } from "../../components/FloatingPopup";
import { useStorage } from "../../hooks/useStorage";
import { AutocompleteInput } from "../components/AutocompleteInput";
import { Modal, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { useMemo, useState } from "react";
import { useSchematics } from "./usePI";
import Chain from "jsx:../../icons/chain.svg";
import { PIChainChart } from "./PIChain";
import { ReactFlowProvider } from "@xyflow/react";

export function PIMarket() {
  const [selectedItem, setSelectedItem] = useState(null);
  const [region, setRegion] = useStorage("pi.market_regions", {
    id: 10000002,
    name: "The Forge",
  });

  const [priceField, setPriceField] = useStorage(
    "pi.market_price_field",
    "split",
  );

  const [inputField, setInputField] = useStorage(
    "pi.market_input_field",
    "extract",
  );

  const pi = useSchematics({ region_id: region.id });

  const columns = useMemo(
    () => [
      {
        header: "Tier",
        accessorKey: "tier",
        meta: {
          align: "center",
        },
        cell: (cell) => `P${cell.getValue()}`,
      },
      {
        header: "Item",
        accessorFn: (row) => ({
          id: row.output_item.id,
          name: row.output_item.name,
        }),
        cell: (cell) => {
          const { id, name } = cell.getValue();
          return <TypeCell id={id} name={name} />;
        },
      },
      {
        header: "Inputs",
        accessorKey: "inputs",
        cell: (cell) => {
          const row = cell.row.original;
          return (
            <FloatingPopup
              trigger={row.inputs.map((input) => (
                <IconCell
                  key={input.item_id}
                  id={input.item_id}
                  name={input.name}
                />
              ))}
              content={
                <div>
                  {row.inputs.map((input) => (
                    <div>
                      <TypeCell
                        key={input.item_id}
                        id={input.item_id}
                        name={input.name}
                      />
                      <span className={""}> x{input.quantity}</span>
                    </div>
                  ))}
                </div>
              }
            />
          );
        },
      },
      {
        header: "Profit (ISK)",
        id: "profit",
        meta: {
          align: "center",
        },
        cell: (cell) => {
          const row = cell.row.original;

          const inputCost = row.inputs.reduce((acc, input) => {
            if (inputField === "buy") {
              return acc + input.hub_price_buy * input.quantity;
            } else if (inputField === "sell") {
              return acc + input.hub_price_sell * input.quantity;
            } else if (inputField === "split") {
              return acc + input.hub_price_split * input.quantity;
            }
            return 0;
          }, 0);

          const outputCost = {
            buy: row.output_hub_price_buy,
            sell: row.output_hub_price_sell,
            split: row.output_hub_price_split,
          }[priceField];

          return priceCell(outputCost - inputCost, false);
        },
      },
      {
        header: "",
        id: "actions",
        meta: {
          align: "center",
        },
        cell: (cell) => {
          const row = cell.row.original;
          return (
            <button
              className={"btn btn-link"}
              onClick={() => setSelectedItem(row.output_item.id)}
              title={"View chain"}
            >
              <Chain className={"icon sm link"} />
            </button>
          );
        },
      },
    ],
    [priceField, inputField, pi.schematics, setSelectedItem],
  );

  return (
    <Content>
      <Header
        title={"PI Markets"}
        description={"A quick glance at the Planetary Interaction markets."}
      />
      <div className={"alert alert-info"}>
        This feature is still in development. Double check the prices before
        making any decisions.
      </div>
      <div className={"row dark align-items-center g-2 mb-3"}>
        <div className={"col-12"}>
          <AutocompleteInput
            type={"regions"}
            defaultValue={region}
            onChange={(value) => setRegion(value)}
            helpText={"Select the region to use when calculating profit."}
          />
        </div>
        <div className={"col"}>
          <ToggleButtonGroup
            value={priceField}
            name="priceField"
            type="radio"
            onChange={(value) => setPriceField(value)}
            className={"w-100"}
          >
            <ToggleButton id="split" value={"split"} variant={"dark"}>
              Split
            </ToggleButton>
            <ToggleButton id="buy" value={"buy"} variant={"dark"}>
              Buy
            </ToggleButton>
            <ToggleButton id="sell" value={"sell"} variant={"dark"}>
              Sell
            </ToggleButton>
          </ToggleButtonGroup>
          <small className={"form-text text-white-50"}>
            The price field to use when calculating profit.
          </small>
        </div>
        <div className={"col"}>
          <ToggleButtonGroup
            value={inputField}
            name="inputField"
            type="radio"
            onChange={(value) => setInputField(value)}
            className={"w-100"}
          >
            <ToggleButton id="input_extract" value={"extract"} variant={"dark"}>
              Extract
            </ToggleButton>
            <ToggleButton id="input_buy" value={"buy"} variant={"dark"}>
              Buy
            </ToggleButton>
            <ToggleButton id="input_sell" value={"sell"} variant={"dark"}>
              Sell
            </ToggleButton>
            <ToggleButton id="input_split" value={"split"} variant={"dark"}>
              Split
            </ToggleButton>
          </ToggleButtonGroup>
          <small className={"form-text text-white-50"}>
            The price field to use when calculating input costs.
          </small>
        </div>
      </div>
      <Table
        isLoading={pi.schematicsIsLoading}
        defaultColumn={{
          enableSorting: false,
        }}
        columns={columns}
        rows={
          pi.schematicsIsLoading
            ? []
            : pi.schematics.filter((schematic) => schematic.tier !== 0)
        }
      />
      {selectedItem && (
        <Modal
          show={true}
          fullscreen={true}
          onHide={() => setSelectedItem(null)}
        >
          <Modal.Body>
            <ReactFlowProvider>
              <PIChainChart
                pi={pi}
                itemId={selectedItem}
                options={{}}
                onOptionChange={() => {}}
              />
            </ReactFlowProvider>
          </Modal.Body>
          <Modal.Footer>
            <button
              className={"btn btn-primary w-100"}
              onClick={() => setSelectedItem(null)}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </Content>
  );
}
