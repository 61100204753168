import { useQuery } from "@tanstack/react-query";
import { Spinner } from "react-bootstrap";
import React from "react";
import { Table } from "../../components/Table";
import { Link } from "react-router-dom";
import { priceCell } from "../../components/Cells";

const columns = [
  {
    header: "Type",
    accessorFn: (row) => row.slug,
    cell: (cell) => {
      return (
        <Link to={`/paste/${cell.getValue()}`}>
          {
            {
              0: "Misc",
              1: "Contract",
              2: "Fit",
              4: "Inventory",
            }[cell.row.original.result.type]
          }
        </Link>
      );
    },
  },
  {
    header: "Sell",
    accessorFn: (row) => row.result?.totals?.sell,
    meta: {
      align: "end",
    },
    cell: (cell) => {
      return priceCell(cell.getValue());
    },
  },
  {
    header: "Buy",
    accessorFn: (row) => row.result?.totals?.buy,
    meta: {
      align: "end",
    },
    cell: (cell) => {
      return priceCell(cell.getValue());
    },
  },
  {
    header: "Created",
    accessorFn: (row) => row.created,
    meta: {
      align: "end",
    },
    cell: (cell) => {
      return new Date(cell.getValue()).toLocaleString();
    },
  },
];

export function PasteHistory() {
  const { data: pastes, isLoading: isLoadingPastes } = useQuery({
    queryKey: ["pastes"],
    queryFn: async () => {
      const response = await fetch(`/api/paste/`);
      return response.json();
    },
    initialData: { results: [] },
  });

  if (isLoadingPastes) {
    return (
      <div className={"text-center py-4"}>
        <Spinner animation={"grow"} role={"status"}>
          <span className={"visually-hidden"}>Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div>
      <Table rows={pastes.results} columns={columns} />
      <p className={"mt-2 fw-bold"}>
        Your pastes will be deleted after 30 days.
      </p>
    </div>
  );
}
