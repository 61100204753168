import React from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { TextArea } from "../../components/Inputs";
import { Content, Header, Page } from "../../components/Page";
import { savePaste } from "../../mutations/paste";
import { PasteHistory } from "./PasteHistory";
import { AutocompleteInput } from "../components/AutocompleteInput";
import { useStorage } from "../../hooks/useStorage";

export function PasteForm({ defaultContent = "" }) {
  const [lastRegion, setLastRegion] = useStorage("lastRegion", {
    id: 10000002,
    name: "The Forge",
  });

  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const save = savePaste({
    onSuccess: (data) => {
      navigate(`/paste/${data.slug}`);
    },
    onError: (error) => {
      setError("root.serverError", {
        type: "server",
        message: error.message,
      });
    },
  });

  return (
    <div>
      <form
        className={"dark"}
        onSubmit={handleSubmit((data) => {
          setLastRegion(data.region);
          data.region = data.region.id;
          save.mutate(data);
        })}
      >
        {errors.root?.serverError && (
          <div className={"alert alert-danger"} role={"alert"}>
            {errors.root.serverError.message}
          </div>
        )}
        <TextArea
          label={"Paste"}
          name={"content"}
          register={register}
          errors={errors}
          required={true}
          rows={10}
          helpText={"Paste contracts, shopping lists, ship fits, etc."}
          defaultValue={defaultContent}
        />
        <Controller
          render={({ field }) => (
            <AutocompleteInput
              type={"regions"}
              name={field.name}
              required={true}
              defaultValue={field.value}
              onChange={field.onChange}
              inputProps={field}
              helpText={
                "Select a region to use for market data when determining prices."
              }
            />
          )}
          name={"region"}
          control={control}
          defaultValue={lastRegion}
        />
        <div className={"mb-3"}>
          <button
            className={"btn btn-success"}
            type={"submit"}
            disabled={save.isPending}
          >
            {save.isPending ? (
              <>
                <span
                  className={"spinner-border spinner-border-sm"}
                  role={"status"}
                  aria-hidden={"true"}
                />
                &nbsp;Saving...
              </>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

export function Paste() {
  return (
    <Page>
      <Content>
        <Header
          title={"New Paste"}
          description={
            <p>
              Copy almost anything from EVE Online and paste it here. This tool
              will attempt to parse the contents and determine the value of the
              items you pasted.
            </p>
          }
        />
        <PasteForm />
        <PasteHistory />
      </Content>
    </Page>
  );
}
