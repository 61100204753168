import { ClonesTable } from "./ClonesTable";
import { SkillsTable } from "./SkillsTable";
import { SkillQueueTable } from "./SkillQueueTable";
import { useQuery } from "@tanstack/react-query";
import { Loading } from "../../components/Loading";
import { useParams } from "react-router-dom";
import { Content, Header } from "../../components/Page";
import { ScopeBar } from "../../components/ScopeBar";
import { compactNumber } from "../../formatting";
import { priceCell } from "../../components/Cells";
import { Tab, Tabs } from "react-bootstrap";

export function Character() {
  const { id: characterId } = useParams();

  const { data: character, isLoading } = useQuery({
    queryKey: ["characters", "get", characterId],
    queryFn: () => {
      const url = new URL(
        `/api/characters/${characterId}/`,
        window.location.origin,
      );
      url.searchParams.set(
        "expand",
        [
          "home_location",
          "clones",
          "clones.implants",
          "clones.location",
          "clones.location.system",
          "clones.system",
        ].join(","),
      );

      return fetch(url).then((res) => res.json());
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Content>
      <Header title={`${character.name}`} />
      <div className={"mb-4"}>
        <ScopeBar
          optional={[
            "esi-clones.read_implants.v1",
            "esi-skills.read_skills.v1",
            "esi-skills.read_skillqueue.v1",
            "esi-location.read_location.v1",
          ]}
        />
      </div>
      <div className={"container-fluid gx-0"}>
        <div className={"row mb-4"}>
          <div className={"col-12 col-md-6"}>
            <dl className={"feature"}>
              <dt>Home Location</dt>
              <dd>
                {character.home_location
                  ? character.home_location.name
                  : "Unknown"}
              </dd>
              <dt>Total SP</dt>
              <dd>{compactNumber(character.total_sp)}</dd>
              <dt>Unallocated SP</dt>
              <dd>{compactNumber(character.unallocated_sp)}</dd>
              <dt>Wallet</dt>
              <dd>{priceCell(character.balance)}</dd>
            </dl>
          </div>
          <div className={"col-12 col-md-6"}>
            <dl className={"feature"}>
              <dt>Security Status</dt>
              <dd>{character.security_status?.toFixed(1)}</dd>
            </dl>
          </div>
        </div>
        <div className={"row mb-4"}>
          <div className={"col-12"}>
            <h3 className={"fs-5 with-border d-inline-block"}>Clones</h3>
            <div className={"table-container table-short"}>
              <ClonesTable character={character} />
            </div>
          </div>
        </div>
        <div className={"row mb-4"}>
          <div className={"col-12"}>
            <h3 className={"fs-5 mb-3 with-border d-inline-block"}>Skills</h3>
            <Tabs defaultActiveKey="queue">
              <Tab title={"Skill Queue"} eventKey={"queue"}>
                <div className={"table-container table-short mt-3"}>
                  <SkillQueueTable characterId={character.id} />
                </div>
              </Tab>
              <Tab title={"Injected Skills"} eventKey={"skills"}>
                <div className={"table-container table-short mt-3"}>
                  <SkillsTable characterId={character.id} />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </Content>
  );
}
