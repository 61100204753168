import { useQuery } from "@tanstack/react-query";
import { Bottom, Page, Sidebar, Top } from "../../components/Page";
import { Loading } from "../../components/Loading";
import { Link, NavLink, Outlet } from "react-router-dom";
import { characterCell } from "../../components/Cells";
import React from "react";
import Skill from "jsx:../../icons/skill.svg";
import Clone from "jsx:../../icons/clone.svg";

export function Characters() {
  const { data, isLoading } = useQuery({
    queryKey: ["characters", "list"],
    queryFn: () => {
      const params = new URLSearchParams();
      params.set(
        "fields",
        [
          "id",
          "name",
          "clones.location.system.name",
          "clones.jump_clone_id",
        ].join(","),
      );
      params.set("expand", ["clones.location.system"].join(","));
      return fetch(`/api/characters/?${params.toString()}`).then((res) =>
        res.json(),
      );
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Page>
      <Sidebar>
        <Top>
          <div className={"list-group list-group-flush border-0"}>
            <NavLink
              end
              to={"/characters"}
              className={
                "list-group-item list-group-item-action border-0 d-flex align-items-center"
              }
            >
              <Skill className={"icon sm me-2"} />
              <strong>Skill Points</strong>
            </NavLink>
            <NavLink
              end
              to={"/characters/clones"}
              className={
                "list-group-item list-group-item-action border-0 d-flex align-items-center"
              }
            >
              <Clone className={"icon sm me-2"} />
              <strong>All Clones</strong>
            </NavLink>
            {data.map((character) => {
              const activeClone = character.clones.find(
                (clone) => clone.jump_clone_id === 0,
              );

              return (
                <NavLink
                  key={character.id}
                  to={`/characters/${character.id}`}
                  className={
                    "list-group-item list-group-item-action border-0 d-flex align-items-center"
                  }
                >
                  {characterCell(character.id, character.name, false)}
                  {activeClone && (
                    <span className={"badge text-bg-light ms-auto"}>
                      {activeClone.location?.system?.name}
                    </span>
                  )}
                </NavLink>
              );
            })}
          </div>
        </Top>
        <Bottom>
          <div>
            <Link to={"/login"} className={"btn btn-primary btn-sm w-100"}>
              Add Character
            </Link>
          </div>
        </Bottom>
      </Sidebar>
      <Outlet />
    </Page>
  );
}
