import {Content, Header, Page} from "../../components/Page";
import {ScopeBar} from "../../components/ScopeBar";
import {useQuery} from "@tanstack/react-query";
import {characterCell, IconCell, TypeCell} from "../../components/Cells";
import {applyFiltersToURL, FilterBuilder} from "../../components/FilterBuilder";
import {useStorage} from "../../hooks/useStorage";
import {AutocompleteInput} from "../components/AutocompleteInput";
import {Loading} from "../../components/Loading";
import {Link} from "react-router-dom";

export function Fittings() {
  const [filters, setFilters] = useStorage("fittings.filters", []);
  const { data: fittings, isLoading } = useQuery({
    queryKey: ["fittings", filters],
    queryFn: async () => {
      const params = new URLSearchParams();
      params.append("expand", "ship_item,items,items.item,character");
      if (filters) {
        applyFiltersToURL(params, filters);
      }
      const response = await fetch(`/api/fittings/?${params.toString()}`);
      return response.json();
    },
  });

  return (
    <Page>
      <Content>
        <Header title={"Fittings"} description={
          <>
            Manage your ship fittings here. You can create new fittings, view existing fittings,
            and import fittings from ESI.
          </>
        }/>
        <ScopeBar scopes={["esi-fittings.read_fittings.v1", "esi-fittings.write_fittings.v1"]}/>
        <div className={"mt-4"}>
          <FilterBuilder
            filters={filters}
            onChange={setFilters}
            allowedFilters={{
              item: {
                label: "Item",
                operators: ["==", "!="],
                component: AutocompleteInput,
                props: {
                  type: "type",
                },
              },
              character: {
                label: "Character",
                operators: ["==", "!="],
                component: AutocompleteInput,
                props: {
                  type: "character",
                },
              },
            }}
          />
          {isLoading ? (
            <Loading />
          ) : fittings && fittings.length === 0 ? (
            <div className={"alert alert-info"}>
              No fittings found.
            </div>
          ) : (
            <div className={"row row-cols-1 row-cols-md-6 g-4"}>
              {fittings.map((fitting) => {
                return (
                  <div key={fitting.id} className={"col"}>
                    <div className={"card card-block"}>
                      <Link to={`/fittings/${fitting.id}`} className={"stretched-link"}>
                        <IconCell
                          id={fitting.ship_item.id}
                          name={fitting.ship_item.name}
                          iconSize={128}
                          className={"card-img-top"}
                        />
                      </Link>
                      <div className={"card-body"}>
                        <h5 className={"card-title text-truncate mb-0"}>{fitting.name}</h5>
                      </div>
                      <div className={"list-group list-group-flush text-white border-top-0"}>
                        <div className={"list-group-item"}>
                          <TypeCell id={fitting.ship_item.id} name={fitting.ship_item.name} />
                        </div>
                        <div className={"list-group-item"}>
                          {characterCell(fitting.character.id, fitting.character.name)}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Content>
    </Page>
  )
}