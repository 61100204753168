import React, { useEffect, useMemo, useState } from "react";
import { Accordion, Form } from "react-bootstrap";
import { useAuth } from "./hooks/useAuth";

const LoginButton = new URL(
  "images/eve-sso-login-white-large.png",
  import.meta.url,
);

const ScopeMap = {
  "esi-universe.read_structures.v1": "Read Structures",
  "esi-clones.read_clones.v1": "Read Clones",
  "esi-clones.read_implants.v1": "Read Implants",
  "esi-skills.read_skills.v1": "Read Skills",
  "esi-skills.read_skillqueue.v1": "Read Skill Queue",
  "esi-assets.read_assets.v1": "Read Assets",
  "esi-wallet.read_character_wallet.v1": "Read Wallet",
  "esi-characters.read_loyalty.v1": "Read Loyalty Points",
  "esi-markets.structure_markets.v1": "Read Markets",
  "esi-markets.read_character_orders.v1": "Read Orders",
  "esi-planets.manage_planets.v1": "Read Planetary Interaction",
  "esi-ui.open_window.v1": "Open Windows In-game",
  "esi-location.read_location.v1": "Read Location",
  "esi-fittings.read_fittings.v1": "Read Ship Fittings",
  "esi-fittings.write_fittings.v1": "Write Ship Fittings",
};

const CorpScopeMap = {
  "esi-characters.read_corporation_roles.v1": "Read Your Own Roles",
  "esi-corporations.read_starbases.v1": "Read Starbases",
  "esi-wallet.read_corporation_wallets.v1": "Read Wallets",
  "esi-contracts.read_corporation_contracts.v1": "Read Contracts",
};

/**
 * Handles the /login callback, which sets the JWT for the user.
 */
export function Login() {
  const url = new URL(window.location.href);
  const token = url.searchParams.get("token");

  const [scopes, setScopes] = useState(() => {
    // Check if permissions are already saved in local storage.
    const savedScopes = window.localStorage.getItem("scopes");
    if (savedScopes) {
      // Remove any keys that are no longer in the defaultScopes list.
      const parsedScopes = JSON.parse(savedScopes);
      Object.keys(parsedScopes).forEach((scope) => {
        if (
          !Object.keys(ScopeMap).includes(scope) &&
          !Object.keys(CorpScopeMap).includes(scope)
        ) {
          delete parsedScopes[scope];
        }
      });
      return parsedScopes;
    }

    // Otherwise, use the default permissions.
    const defaultScopes = {};
    Object.keys(ScopeMap).forEach((scope) => {
      defaultScopes[scope] = true;
    });
    return defaultScopes;
  });

  useEffect(() => {
    window.localStorage.setItem("scopes", JSON.stringify(scopes));
  }, [scopes]);

  const scopesString = useMemo(() => {
    return Object.keys(scopes)
      .filter((scope) => scopes[scope])
      .join(",");
  }, [scopes]);

  const { user } = useAuth();

  return (
    <main className={"h-100 d-flex flex-column"}>
      <div className={"m-auto text-center"}>
        <h1 className={"h3 my-3 fw-normal"}>Cosmic Ledger</h1>
        <form action={"/api/auth/login"} method={"POST"}>
          <input type={"hidden"} name={"scopes"} value={scopesString} />
          <button type={"submit"} className={"btn"}>
            <img src={LoginButton} alt={"EVE SSO Login"} />
          </button>
        </form>
        {user && (
          <div className={"mt-3 p-4"}>
            <p>You are currently logged in.</p>
            <p>
              Login again to link additional characters to your account or to
              change permissions.
            </p>
          </div>
        )}
      </div>
      <Accordion className={"mt-3 slim"}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Permissions</Accordion.Header>
          <Accordion.Body>
            <p>
              Various features of the Cosmic Ledger are only available if
              permissions are granted for them. You can use this to change what
              permissions the Ledger will request.
            </p>
            <h2 className={"fs-6 fw-bold"}>Personal Roles</h2>
            <div className={"row"}>
              {Object.keys(ScopeMap).map((scope) => {
                return (
                  <div className={"col-12 col-md-6 col-lg-3"} key={scope}>
                    <Form.Check
                      type={"switch"}
                      id={scope}
                      label={ScopeMap[scope]}
                      checked={scopes[scope]}
                      onChange={(e) => {
                        setScopes({
                          ...scopes,
                          [scope]: e.target.checked,
                        });
                      }}
                    />
                  </div>
                );
              })}
            </div>
            <h2 className={"fs-6 mt-2 fw-bold"}>Corporate Roles</h2>
            <div className={"row"}>
              {Object.keys(CorpScopeMap).map((scope) => {
                return (
                  <div className={"col-12 col-md-6 col-lg-3"} key={scope}>
                    <Form.Check
                      type={"switch"}
                      id={scope}
                      label={CorpScopeMap[scope]}
                      checked={scopes[scope]}
                      onChange={(e) => {
                        setScopes({
                          ...scopes,
                          [scope]: e.target.checked,
                        });
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </main>
  );
}
