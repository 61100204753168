import { useState } from "react";
import {Content, Header, Page} from "../../components/Page";
import { AutocompleteInput } from "../components/AutocompleteInput";
import { useQuery } from "@tanstack/react-query";
import { Table } from "../../components/Table";
import { priceCell, TypeCell } from "../../components/Cells";
import { useStorage } from "../../hooks/useStorage";

export function Arbitrage() {
  const [sourceStation, setSourceStation] = useStorage(
    "market.arbitrage.sourceStation",
    null,
  );
  const [destinationStation, setDestinationStation] = useStorage(
    "market.arbitrage.destinationStation",
    null,
  );
  const [taxRate, setTaxRate] = useStorage("market.arbitrage.taxRate", 0.05);
  const [transportFee, setTransportFee] = useStorage(
    "market.arbitrage.transportFee",
    0,
  );

  const { data, isLoading, isRefetching, refetch } = useQuery({
    queryKey: [
      "arbitrage",
      sourceStation,
      destinationStation,
      taxRate,
      transportFee,
    ],
    queryFn: () => {
      const params = new URLSearchParams();

      params.set("tax_rate", taxRate);
      params.set("cost_per_m3", transportFee);

      const url = `/api/market/arbitrage/${sourceStation.id}/${
        destinationStation.id
      }/?${params.toString()}`;
      return fetch(url).then((res) => res.json());
    },
    staleTime: 30000,
    enabled: false,
  });

  return (
    <Page>
      <Content>
        <Header
          title={"Arbitrage"}
          description={
            <>
              Find items that can be bought in one location from available sell
              orders and sold immediately to buy orders in another location for a
              profit.
            </>
          }
        />
        <div className={"row gy-4 dark"}>
          <div className={"col-12 col-md-5"}>
            <AutocompleteInput
              value={sourceStation}
              onChange={setSourceStation}
              inputProps={{
                placeholder: "Source Station",
                autoFocus: true,
              }}
              type={"station"}
              helpText={"Select the station to buy from."}
            />
          </div>
          <div className={"col-12 col-md-2"}>
            <button
              type={"button"}
              className={"btn btn-primary w-100"}
              onClick={() => {
                const temp = sourceStation;
                setSourceStation(destinationStation);
                setDestinationStation(temp);
              }}
              disabled={!sourceStation || !destinationStation}
            >
              Swap
            </button>
          </div>
          <div className={"col-12 col-md-5"}>
            <AutocompleteInput
              value={destinationStation}
              onChange={setDestinationStation}
              inputProps={{
                placeholder: "Destination Station",
              }}
              type={"station"}
              helpText={"Select the station to sell to."}
            />
          </div>
          <div className={"col-12 col-md-6"}>
            <div className={"form-group"}>
              <label htmlFor={"taxRate"}>Broker's Fee</label>
              <input
                type={"number"}
                id={"taxRate"}
                className={"form-control"}
                value={taxRate}
                onChange={(e) => setTaxRate(e.target.value)}
                min={0}
                max={1}
                step={0.01}
              />
              <small className={"form-text text-white-50"}>
                The broker's fee to pay when selling items. 0.05 would be 5%.
              </small>
            </div>
          </div>
          <div className={"col-12 col-md-6"}>
            <div className={"form-group"}>
              <label htmlFor={"transportFee"}>Transport Fee</label>
              <input
                type={"number"}
                id={"transportFee"}
                className={"form-control"}
                value={transportFee}
                onChange={(e) => setTransportFee(e.target.value)}
                min={0}
              />
              <small className={"form-text text-white-50"}>
                The cost per m3 to transport items between stations.
              </small>
            </div>
          </div>
          <div className={"col-12"}>
            <button
              type={"button"}
              className={"btn btn-primary w-100"}
              onClick={() => {
                refetch();
              }}
              disabled={!sourceStation || !destinationStation || isRefetching}
            >
              Calculate
            </button>
          </div>
        </div>
        <div className={"mt-4"}>
          <Table
            rows={data || []}
            isLoading={isLoading || isRefetching}
            defaultColumn={{
              enableSorting: false,
            }}
            columns={[
              {
                header: "Item",
                cell: (cell) => {
                  const { name, id } = cell.row.original;
                  return <TypeCell id={id} name={name} />;
                },
              },
              {
                header: "Cost",
                accessorKey: "cost",
                meta: {
                  align: "end",
                },
                cell: (cell) => {
                  const { cost } = cell.row.original;
                  return priceCell(cost, false);
                },
              },
              {
                header: "Revenue",
                accessorKey: "revenue",
                meta: {
                  align: "end",
                },
                cell: (cell) => {
                  const { revenue } = cell.row.original;
                  return priceCell(revenue, false);
                },
              },
              {
                header: "Profit",
                accessorKey: "profit",
                meta: {
                  align: "end",
                },
                enableSorting: true,
                cell: (cell) => {
                  const { profit } = cell.row.original;
                  return priceCell(profit, false);
                },
              },
              {
                header: "ROI",
                accessorKey: "roi",
                meta: {
                  align: "end",
                },
                enableSorting: true,
                cell: (cell) => {
                  const { roi } = cell.row.original;
                  return (roi * 100).toFixed(2) + "%";
                },
              },
              {
                header: "Volume (m3)",
                meta: {
                  align: "end",
                },
                cell: (cell) => {
                  const { volume, qty } = cell.row.original;
                  return (volume * qty).toLocaleString();
                },
              },
              {
                header: "Quantity",
                accessorKey: "qty",
                meta: {
                  align: "end",
                },
              },
            ]}
          />
        </div>
      </Content>
    </Page>
  );
}
