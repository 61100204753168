import ProIcon from "jsx:../icons/pro.svg";
import {hasPermission, useAuth} from "../hooks/useAuth";

/**
 * Show the pro icon.
 *
 * @returns {JSX.Element}
 * @constructor
 */
export function Pro() {
  return <ProIcon className={"icon md icon-pro"} />;
}

/**
 * Check if the current user has pro.
 *
 * @returns {*} True if the user has pro, false otherwise.
 */
export function hasPro() {
  const { user } = useAuth();
  return user?.subscription_plan === "pro";
}

/**
 * Wrap a component in an overlay that shows when the user doesn't have pro.
 *
 *
 * @param text Optional text to display in the overlay.
 * @param children The component to wrap.
 * @returns {*|JSX.Element}
 * @constructor
 */
export function ProOverlay({ text = null, children }) {
  if (hasPro()) {
    return children;
  }

  return (
    <div className={"my-2"} style={{ display: "grid" }}>
      <div style={{ gridArea: "1/1" }}>{children}</div>
      <div style={{ gridArea: "1/1", backgroundColor: "rgba(0, 0, 0, 0.8)" }}>
        <div
          className={"d-flex justify-content-center align-items-center h-100"}
        >
          <div className={"d-flex flex-column align-items-center"}>
            <Pro />
            <p className={"text-center fw-bolder"}>
              {text || "This feature is only available to Pro users."}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
