import { TypeCell } from "../../components/Cells";
import { compactNumber } from "../../formatting";
import { Table } from "../../components/Table";
import { useQuery } from "@tanstack/react-query";
import { Loading } from "../../components/Loading";
import { useState } from "react";

const skillColumns = [
  {
    header: "Skill",
    accessorKey: "skill",
    cell: (cell) => {
      const skill = cell.getValue();
      return <TypeCell id={skill.id} name={skill.name} />;
    },
  },
  {
    header: "SP",
    accessorKey: "sp",
    meta: {
      align: "end",
    },
    cell: (cell) => {
      const value = cell.getValue();
      return compactNumber(value);
    },
  },
  {
    header: "Level",
    meta: {
      align: "end",
    },
    cell: (cell) => {
      const original = cell.row.original;
      if (original.active_skill_level < original.trained_skill_level) {
        return (
          <span className={"text-warning"}>{original.active_skill_level}</span>
        );
      } else {
        return original.active_skill_level;
      }
    },
  },
];

/**
 * SkillsTable displays a table of skills for a character.
 *
 * @param character The source character.
 * @returns {JSX.Element}
 * @constructor
 */
export function SkillsTable({ characterId }) {
  const [sorting, setSorting] = useState([
    {
      id: "sp",
      desc: true,
    },
  ]);
  const { data: skills, isLoading } = useQuery({
    queryKey: ["characters", characterId, "skills"],
    queryFn: () => {
      const url = new URL(
        `/api/characters/${characterId}/`,
        window.location.origin,
      );
      url.searchParams.append("expand", ["skills", "skills.skill"].join(","));

      return fetch(url)
        .then((res) => res.json())
        .then((res) => res?.skills);
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Table
      columns={skillColumns}
      rows={skills}
      sorting={sorting}
      setSorting={setSorting}
    />
  );
}
