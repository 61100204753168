import { useMutation } from "@tanstack/react-query";

/**
 * Save a paste.
 *
 * @param options Options passed into the useMutation hook.
 */
export function savePaste(options) {
  return useMutation({
    queryKey: ["paste"],
    mutationFn: async (data) => {
      const response = await fetch("/api/paste/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error("Failed to save paste.");
      }
      return response.json();
    },
    ...options,
  });
}
