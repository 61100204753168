import { Content, Page, Sidebar, Top } from "../../components/Page";
import { NavLink, Outlet } from "react-router-dom";

export function Wallet() {
  return (
    <Page>
      <Sidebar>
        <Top>
          <div className={"list-group list-group-flush border-0"}>
            <NavLink
              end
              key={"dashboard"}
              to={"/wallet"}
              className={
                "list-group-item list-group-item-action border-0 d-flex align-items-center"
              }
            >
              Dashboard
            </NavLink>
            <NavLink
              end
              key={"orders"}
              to={"/wallet/orders"}
              className={
                "list-group-item list-group-item-action border-0 d-flex align-items-center"
              }
            >
              Orders
            </NavLink>
            <NavLink
              key={"trades"}
              to={"/wallet/trading"}
              className={
                "list-group-item list-group-item-action border-0 d-flex align-items-center"
              }
            >
              Trade Tracker
            </NavLink>
            <NavLink
              key={"transactions"}
              to={"/wallet/transactions"}
              className={
                "list-group-item list-group-item-action border-0 d-flex align-items-center"
              }
            >
              Transactions
            </NavLink>
            <NavLink
              key={"journal"}
              to={"/wallet/journal"}
              className={
                "list-group-item list-group-item-action border-0 d-flex align-items-center"
              }
            >
              Journal
            </NavLink>
            <NavLink
              key={"lp"}
              to={"/wallet/lp"}
              className={
                "list-group-item list-group-item-action border-0 d-flex align-items-center"
              }
            >
              Loyalty Points
            </NavLink>
          </div>
        </Top>
      </Sidebar>
      <Content>
        <Outlet />
      </Content>
    </Page>
  );
}
