import { AutocompleteInput } from "../components/AutocompleteInput";
import React, { useCallback, useState } from "react";
import { useStorage } from "../../hooks/useStorage";
import { CopyButton } from "../../components/Copy";

/**
 * Shows a widget that allows a user to select what items are cheaper to
 * use locally versus importing them from another region.
 *
 * @constructor
 */
export function LocalBuy({ foreignRegion, items }) {
  const [shippingCostPerM3, setShippingCostPerM3] = useStorage(
    "buy.shippingCostPerM3",
    140,
  );
  const [localStation, setLocalStation] = useStorage("buy.localStation", null);
  const [importResult, setImportResult] = useState("");
  const [localResult, setLocalResult] = useState("");
  const [progress, setProgress] = useState(0);
  const [estimatedLocally, setEstimatedLocally] = useState(null);
  const [estimatedImport, setEstimatedImport] = useState(null);

  const calculate = useCallback(
    async (e) => {
      e.preventDefault();
      e.target.disabled = true;
      e.target.innerHTML = "Calculating...";

      const buyLocal = [];
      const buyImport = [];
      let buyLocalTotal = 0;
      let buyImportTotal = 0;

      const totalItems = items.length;
      for (const item of items) {
        setProgress(((buyLocal.length + buyImport.length) / totalItems) * 100);

        const result = await fetch(
          `/api/types/${item.type_id}/orders/?location_id=${localStation.id}`
        );
        let orders = await result.json();
        orders = orders.filter((order) => !order.is_buy_order);
        orders.sort((a, b) => a.price - b.price);

        if (orders.length === 0) {
          buyImport.push(item);
          buyImportTotal += item.sell * item.quantity;
          continue;
        }

        const shippingCost = parseInt(shippingCostPerM3);
        const totalVolume = item.volume * item.quantity;
        const bestLocalPrice = parseFloat(orders[0].price);
        const bestImportPrice = parseFloat(item.sell);

        const bestLocalTotal = bestLocalPrice * item.quantity;
        const bestImportTotal = bestImportPrice * item.quantity;
        const bestImportShipping = bestImportTotal + shippingCost * totalVolume;

        if (bestLocalTotal < bestImportShipping) {
          buyLocal.push(item);
          buyLocalTotal += bestLocalTotal;
        } else {
          buyImport.push(item);
          buyImportTotal += bestImportShipping;
        }
      }

      setLocalResult(
        buyLocal.map((item) => `${item.name} x ${item.quantity}`).join("\n"),
      );

      setImportResult(
        buyImport.map((item) => `${item.name} x ${item.quantity}`).join("\n"),
      );

      setEstimatedLocally(buyLocalTotal);
      setEstimatedImport(buyImportTotal);

      e.target.disabled = false;
      e.target.innerText = "Calculate";
      setProgress(0);
    },
    [items, foreignRegion, localStation, shippingCostPerM3],
  );

  return (
    <div className={"container-fluid"}>
      <div className={"row"}>
        <div>
          Choose a station or structure to use as a buy location. This tool will
          calculate the cost of buying the items on the market of the chosen
          station versus importing them.
        </div>
        <div>
          <span className={"text-info"}>Note:</span> This tool is in beta,
          always double check prices before buying.
        </div>
      </div>
      <div className={"row dark mt-3"}>
        <div className={"col-6"}>
          <AutocompleteInput
            label={"Location"}
            type={"station"}
            onChange={(station) => setLocalStation(station)}
            defaultValue={localStation}
          />
        </div>
        <div className={"col-6"}>
          <div className={"form-group"}>
            <label className={"form-label"}>Shipping Cost Per m3</label>
            <input
              type={"number"}
              min={0}
              max={1000}
              className={"form-control"}
              value={shippingCostPerM3}
              onChange={(e) => setShippingCostPerM3(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-12"}>
          <button
            type={"button"}
            className={"btn btn-primary w-100 mt-3"}
            onClick={calculate}
          >
            Calculate
          </button>
        </div>
      </div>
      {progress > 0 && (
        <div className="progress mt-2" role="progressbar">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated bg-primary"
            style={{
              width: `${progress}%`,
            }}
          />
        </div>
      )}
      <div className={"row"}>
        <div className={"col-12 col-md-6 mt-3"}>
          <h5>Buy Locally</h5>
          <textarea
            rows={10}
            className={"w-100"}
            value={localResult}
            readOnly={true}
          />
          {estimatedLocally && (
            <div className={"mt-2"}>
              <span className={"text-success"}>
                Estimated Cost: {estimatedLocally.toLocaleString()} ISK
              </span>
            </div>
          )}
          <CopyButton
            className={"btn btn-outline-secondary w-100 mt-3"}
            onDoCopy={() => {
              return localResult;
            }}
          >
            Copy
          </CopyButton>
        </div>
        <div className={"col-12 col-md-6 mt-3"}>
          <h5>Import</h5>
          <textarea
            rows={10}
            className={"w-100"}
            value={importResult}
            readOnly={true}
          />
          {estimatedImport && (
            <div className={"mt-2"}>
              <span className={"text-success"}>
                Estimated Cost: {estimatedImport.toLocaleString()} ISK
              </span>
            </div>
          )}
          <CopyButton
            className={"btn btn-outline-secondary w-100 mt-3"}
            onDoCopy={() => {
              return importResult;
            }}
          >
            Copy
          </CopyButton>
        </div>
      </div>
    </div>
  );
}
