import { Table } from "../../components/Table";
import { useQuery } from "@tanstack/react-query";
import { TypeCell } from "../../components/Cells";
import { CountdownWidget } from "../../components/CountdownWidget";
import { Loading } from "../../components/Loading";
import { useState } from "react";

const skillQueueColumns = [
  {
    header: "Skill",
    accessorKey: "skill",
    cell: (cell) => {
      const skill = cell.getValue();
      return <TypeCell id={skill.id} name={skill.name} />;
    },
  },
  {
    header: "Level",
    meta: {
      align: "end",
    },
    cell: (cell) => {
      const original = cell.row.original;
      if (original.finished_level < original.level) {
        return (
          <span className={"text-warning"}>{original.finished_level}</span>
        );
      } else {
        return original.finished_level;
      }
    },
  },
  {
    header: "Finishes",
    accessorKey: "finish_date",
    meta: {
      align: "end",
    },
    cell: (cell) => {
      const value = cell.getValue();
      if (!value) {
        return <em>Paused</em>;
      }

      const date = new Date(value);

      return <CountdownWidget expiry={date} />;
    },
  },
];

export function SkillQueueTable({ characterId }) {
  const [sorting, setSorting] = useState([
    {
      id: "finish_date",
      desc: false,
    },
  ]);
  const { data, isLoading } = useQuery({
    queryKey: ["characters", characterId, "skillqueue"],
    queryFn: () => {
      const url = new URL(
        `/api/characters/${characterId}/`,
        window.location.origin,
      );
      url.searchParams.append(
        "expand",
        ["skill_queue", "skill_queue.skill"].join(","),
      );

      return fetch(url).then((res) => res.json());
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  if (!data.skill_queue.length) {
    return (
      <div className={"alert alert-info"}>
        No skills in queue. Add skills to your queue in-game to track them here.
      </div>
    );
  }

  return (
    <Table
      columns={skillQueueColumns}
      rows={data.skill_queue}
      sorting={sorting}
      setSorting={setSorting}
    />
  );
}
