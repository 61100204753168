import React, { useCallback, useEffect, useMemo, useRef } from "react";
import {
  useNodesState,
  useEdgesState,
  Controls,
  Background,
  Handle,
  Position,
  ReactFlow,
  MarkerType,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import Dagre from "@dagrejs/dagre";
import { IconCell } from "../../components/Cells";
import { stringToColour } from "../../components/Colorizer";

function getTierColor(tier) {
  const colors = ["#f0f9ff", "#e0f2fe", "#bae6fd", "#7dd3fc", "#38bdf8"];
  return colors[tier] || colors[0];
}

function getTierBorderColor(tier) {
  const colors = ["#bae6fd", "#7dd3fc", "#38bdf8", "#0ea5e9", "#0284c7"];
  return colors[tier] || colors[0];
}

const PINode = ({ data }) => {
  const nodeRef = useRef(null);
  const backgroundColor = data.isSelected ? "#86efac" : getTierColor(data.tier);
  const borderColor = data.isSelected
    ? "#22c55e"
    : getTierBorderColor(data.tier);

  useEffect(() => {
    if (nodeRef.current) {
      const { width, height } = nodeRef.current.getBoundingClientRect();
      data.onNodeResize(data.id, width, height);
    }
  }, [data]);

  return (
    <div
      ref={nodeRef}
      className="d-flex flex-column items-center justify-center"
      style={{
        background: backgroundColor,
        border: `2px solid ${borderColor}`,
        borderRadius: "0.35rem",
        color: "black",
      }}
    >
      <div className={"flex-grow-1 small"}>
        <IconCell id={data.id} name={data.label} className={"me-2"} />
        {data.label}
      </div>
      <Handle
        type="source"
        position={Position.Right}
        id="right"
        className="opacity-0"
      />
      <Handle
        type="target"
        position={Position.Left}
        id="left"
        className="opacity-0"
      />
    </div>
  );
};

const getLayoutedElements = (nodes, edges) => {
  const dagreGraph = new Dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({
    rankdir: "LR",
    ranksep: 200,
    nodesep: 100,
  });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: node.width, height: node.height });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  Dagre.layout(dagreGraph);

  return {
    nodes: nodes.map((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      return {
        ...node,
        targetPosition: "left",
        sourcePosition: "right",
        position: {
          x: nodeWithPosition.x - node.width / 2,
          y: nodeWithPosition.y - node.height / 2,
        },
      };
    }),
    edges,
  };
};

function generatePIChain(pi, selectedItemId, onNodeResize) {
  const nodes = [];
  const edges = [];
  const processedItems = new Set();

  function addNode(schematic, isInput = true) {
    if (processedItems.has(schematic.output_item.id)) return;
    processedItems.add(schematic.output_item.id);

    const node = {
      id: schematic.output_item.id.toString(),
      type: "custom",
      data: {
        onNodeResize,
        id: schematic.output_item.id,
        label: schematic.output_item.name,
        tier: schematic.tier,
        isSelected: schematic.output_item.id === selectedItemId,
        schematic: schematic,
      },
      width: 240,
      height: 40,
    };
    nodes.push(node);

    if (isInput) {
      schematic.inputs.forEach((input) => {
        const inputSchematic = pi.schematics.find(
          (s) => s.output_item.id === input.item_id,
        );
        if (inputSchematic) {
          addNode(inputSchematic, true);
        }
        edges.push({
          id: `${input.item_id}-${schematic.output_item.id}`,
          source: input.item_id.toString(),
          target: schematic.output_item.id.toString(),
          style: { stroke: stringToColour(schematic.output_item.name) },
          markerEnd: { type: MarkerType.ArrowClosed },
        });
      });
    } else {
      const outputSchematics = pi.schematics.filter((s) =>
        s.inputs.some((input) => input.item_id === schematic.output_item.id),
      );
      outputSchematics.forEach((outputSchematic) => {
        addNode(outputSchematic, false);
        edges.push({
          id: `${schematic.output_item.id}-${outputSchematic.output_item.id}`,
          source: schematic.output_item.id.toString(),
          target: outputSchematic.output_item.id.toString(),
          style: { stroke: stringToColour(schematic.output_item.name) },
          markerEnd: { type: MarkerType.ArrowClosed },
        });
      });
    }
  }

  const selectedSchematic = pi.schematics.find(
    (s) => s.output_item.id === selectedItemId,
  );
  if (selectedSchematic) {
    addNode(selectedSchematic, true);
    processedItems.clear();
    addNode(selectedSchematic, false);
  }

  return { nodes, edges };
}

export const PIChainChart = ({ pi, itemId }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const nodeTypes = useMemo(() => ({ custom: PINode }), []);

  const onNodeResize = useCallback(
    (id, width, height) => {
      setNodes((prevNodes) =>
        prevNodes.map((node) =>
          node.id === id ? { ...node, width, height } : node,
        ),
      );
    },
    [setNodes],
  );

  const updateLayout = useCallback(() => {
    if (!pi.schematics || !itemId) return;

    const { nodes: newNodes, edges: newEdges } = generatePIChain(
      pi,
      itemId,
      onNodeResize,
    );
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      newNodes,
      newEdges,
    );
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  }, [pi.schematics, itemId, setNodes, setEdges, onNodeResize]);

  useEffect(() => {
    updateLayout();
  }, [updateLayout]);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      nodeTypes={nodeTypes}
      nodesDraggable={false}
      nodesConnectable={false}
      proOptions={{ hideAttribution: true }}
      fitView
    >
      <Controls />
      <Background />
    </ReactFlow>
  );
};
