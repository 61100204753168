import { useQuery } from "@tanstack/react-query";
import { Content, Header } from "../../components/Page";
import { Table } from "../../components/Table";
import {characterCell, IconCell, TypeCell} from "../../components/Cells";
import {usePIByCharacter, useSchematics} from "./usePI";
import {ScopeBar} from "../../components/ScopeBar";
import {useCountdown} from "../../components/CountdownWidget";
import {getUniqueObjectsByKey, padPrefix} from "../../utils";
import {FloatingPopup} from "../../components/FloatingPopup";
import {Loading} from "../../components/Loading";

export function PIDashboard() {
  const {isLoading, results} = usePIByCharacter();

  return (
    <Content>
      <Header title={"Planetary Interaction"} />
      <div className={"mb-4"}>
        <ScopeBar scopes={["esi-planets.manage_planets.v1"]} />
      </div>
      <div className={"mb-4"}>
        {isLoading ? (
          <Loading />
        ) : (
          Object.keys(results).map((key) => {
            const {character, planets} = results[key];

            return (
              <div key={character.id} className={"mb-4"}>
                <h5>{characterCell(character.id, character.name)}</h5>
                <Table
                  key={character.id}
                  rows={planets}
                  isLoading={isLoading}
                  columns={[
                    {
                      header: "Planet",
                      accessorKey: "planet.name",
                      cell: (cell) => {
                        const row = cell.row.original;
                        return (
                          <>
                            <IconCell
                              id={row.planet.item.id}
                              name={row.planet.name}
                              className={"me-2"}
                            />
                            {row.planet.name}
                          </>
                        );
                      },
                    },
                    {
                      header: "Level",
                      accessorKey: "upgrade_level",
                      meta: {
                        align: "center",
                      }
                    },
                    {
                      header: "Extractions",
                      cell: (cell) => {
                        const { pins } = cell.row.original;

                        return <FloatingPopup
                          trigger={
                            <div className={"d-flex flex-column align-items-center"}>
                              {pins.map((pin) => {
                                if (!pin.extracting?.id) {
                                  return null
                                }

                                const expiryTime = new Date(pin.expiry_time);
                                const now = new Date();
                                const isPast = expiryTime < now;
                                const {days, hours, minutes, seconds, state} = useCountdown(expiryTime);

                                return (
                                  <div key={pin.id}>
                                    <IconCell
                                      id={pin.extracting.id}
                                      name={pin.extracting.name}
                                      className={"me-2"}
                                    />
                                    {isPast ? (
                                      <span className={"text-white-50"}>Done</span>
                                    ) : (
                                      <span>{padPrefix(days, 2)}:{padPrefix(hours, 2)}:{padPrefix(minutes, 2)}:{padPrefix(seconds, 2)}</span>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          }
                          content={
                            <div>
                              {getUniqueObjectsByKey(pins, (p) => p?.extracting?.id).map((pin, idx) => {
                                if (!pin.extracting?.id) {
                                  return null
                                }
                                return <TypeCell key={idx} id={pin.extracting.id} name={pin.extracting.name} />
                              })}
                            </div>
                          }
                        />
                      }
                    },
                    {
                      header: "Imports",
                      cell: (cell) => {
                        const { imports } = cell.row.original;

                        return <FloatingPopup
                          trigger={
                            <div>
                              {imports.map((imported) => (
                                <IconCell
                                  key={imported.item_id}
                                  id={imported.item_id}
                                  name={imported.name}
                                  className={"me-2"}
                                />
                              ))}
                            </div>
                          }
                          content={
                            <div>
                              {imports.map((imported) => (
                                <div key={imported.item_id}>
                                  <TypeCell id={imported.item_id} name={imported.name} />
                                </div>
                              ))}
                            </div>
                          }
                        />
                      }
                    },
                    {
                      header: "Exports",
                      cell: (cell) => {
                        const { exports } = cell.row.original;

                        return <FloatingPopup
                          trigger={
                            <div>
                              {exports.map((exported) => (
                                <IconCell
                                  key={exported.id}
                                  id={exported.id}
                                  name={exported.name}
                                  className={"me-2"}
                                />
                              ))}
                            </div>
                          }
                          content={
                            <div>
                              {exports.map((exported) => (
                                <div key={exported.item_id}>
                                  <TypeCell id={exported.id} name={exported.name} />
                                </div>
                              ))}
                            </div>
                          }
                        />
                      }
                    },
                  ]}
                />
              </div>
            );
          }
        ))}
      </div>
    </Content>
  );
}
