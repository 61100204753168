import { useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

export function useCountdown(expiry) {
  const [time, setTime] = useState(Date.now());
  const [result, setResult] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    state: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (expiry === null) {
      setResult({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        state: 0,
      });
    } else if (expiry < Date.now()) {
      setResult({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        state: 1,
      });
    } else {
      const seconds_from_now = Math.floor((expiry - Date.now()) / 1000);
      const days = Math.floor(seconds_from_now / 86400);
      const hours = Math.floor((seconds_from_now % 86400) / 3600);
      const minutes = Math.floor((seconds_from_now % 3600) / 60);
      const seconds = Math.floor(seconds_from_now % 60);

      setResult({
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
        state: 2,
        formatted: `${days}d${hours}h${minutes}m${seconds}s`,
      });
    }
  }, [time]);

  return result;
}

export function CountdownWidget({ expiry }) {
  const { days, hours, minutes, seconds, state } = useCountdown(expiry);

  if (state === 0) {
    return <span className={"badge"}>Not Running</span>;
  }

  if (state === 1) {
    return <span className={"badge"}>Completed</span>;
  }

  // Convert the expiry to the browser's local time.
  const local = new Date(expiry + new Date().getTimezoneOffset() * 60 * 1000);

  return (
    <OverlayTrigger
      overlay={
        <Popover>
          <Popover.Body>
            <dl>
              <dt>EVE Time</dt>
              <dd>{new Date(expiry).toLocaleString()}</dd>
              <dt>Your local time</dt>
              <dd>{local.toLocaleString()}</dd>
            </dl>
          </Popover.Body>
        </Popover>
      }
    >
      <span className={"badge"}>
        {days}d {hours}h {minutes}m {seconds}s
      </span>
    </OverlayTrigger>
  );
}
