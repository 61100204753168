import {CenteredContent} from "../../components/Page";
import {Link} from "react-router-dom";

export function MarketLanding() {
  return (
    <CenteredContent>
      Select or search for an item from the sidebar to view its market
      data.
      <ul className={"list-unstyled list-inline"}>
        <li className={"list-inline-item"}>
          <Link to={"/market/44992"}>PLEX</Link>
        </li>
        <li className={"list-inline-item"}>
          <Link to={"/market/40520"}>Large Skill Injector</Link>
        </li>
      </ul>
    </CenteredContent>
  );
}