import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["user"],
    queryFn: () => {
      const params = new URLSearchParams();
      params.set("expand", [
        "characters",
        "characters.groups",
        "characters.groups.permissions",
      ].join(","));

      return (
        fetch(`/api/auth/me?${params.toString()}`)
          .then((res) => {
            if (res.status !== 200) {
              return null;
            }
            return res.json();
        })
      );
    },
    initialData: undefined,
  });

  const queryClient = useQueryClient();

  const logout = useCallback(() => {
    queryClient.invalidateQueries("user");
    window.location.href = "/api/auth/logout";
  }, [data, queryClient]);

  return (
    <AuthContext.Provider value={{ user: data, isLoading, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

/**
 * Hook to access the current user.
 *
 * @returns {{}}
 */
export const useAuth = () => {
  return useContext(AuthContext);
};

/**
 * Check if the current user has a specific permission.
 *
 * @param permission
 * @returns {boolean}
 */
export function hasPermission(permission) {
  const { user } = useAuth();

  const permissions = user?.characters?.flatMap((character) => {
    return character.groups.flatMap((group) => {
      return group.permissions.map((permission) => {
        return permission.name;
      });
    });
  }) || [];

  return user && permissions.includes(permission);
}
