import { useNavigate, useParams } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Input, TextArea } from "../../components/Inputs";
import { AutocompleteInput } from "../components/AutocompleteInput";
import { ProOverlay } from "../../components/Pro";

export function SeedingListForm() {
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "stations",
  });

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const saveMutation = useMutation({
    mutationFn: (data) =>
      fetch(`/api/seeding/list/${id ? id + "/" : ""}`, {
        method: id ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...data,
          targets: [
            ...data.stations.map((station) => {
              return {
                station: station.station.id,
                price_per_m3: station.price_per_m3,
              };
            }),
          ],
        }),
      })
        .then((res) => {
          if (res.status === 400) {
            return res.json().then((data) => {
              Object.keys(data).forEach((key) => {
                setError(key, {
                  type: "server",
                  message: data[key].join(", "),
                });
              });
            });
          }

          return res.json();
        })
        .then((data) => {
          queryClient
            .invalidateQueries({
              queryKey: ["seeding"],
            })
            .then(() => {
              navigate(`/seeding/list/${data.id}`);
            });

          return data;
        }),
  });

  const deleteMutation = useMutation({
    mutationFn: () =>
      fetch(`/api/seeding/list/${id}/`, {
        method: "DELETE",
      }).then((res) => {
        if (res.status === 204) {
          queryClient.invalidateQueries({
            queryKey: ["seeding"],
          });
          navigate(`/seeding`);
        }
      }),
  });

  useQuery({
    queryKey: ["seeding", "list", id],
    queryFn: () =>
      fetch(
        `/api/seeding/list/${id}/?expand=targets.station,items.item`
      )
        .then((res) => res.json())
        .then((data) => {
          reset({
            name: data.name,
            discord_webhook: data.discord_webhook,
            content: data.items
              .map((item) => `${item.min_quantity} ${item.item.name}`)
              .join("\n"),
            stations: data.targets
              .filter((target) => !!target.station)
              .map((station) => ({
                station: {
                  id: station.station.id,
                  name: station.station.name,
                },
                price_per_m3: station.price_per_m3,
              })),
          });
          return data;
        }),
    enabled: !!id && /^-?[0-9]+$/.test(id),
  });

  return (
    <form onSubmit={handleSubmit(saveMutation.mutate)} className={"dark"}>
      <Input
        label={"List Name"}
        type={"text"}
        name={"name"}
        register={register}
        errors={errors}
        required={true}
        autoComplete={"off"}
      />
      <TextArea
        label={"List Content"}
        type={"text"}
        name={"content"}
        register={register}
        errors={errors}
        placeholder={"500 Multifrequency L\n500 Gamma L\n500 Xray L"}
        rows={8}
        helpText={
          'One item per line, in the format of "quantity name". Copy from the in-game buy window or use the shopping list tool.'
        }
        required={true}
      />
      <ProOverlay
        text={"Discord Notifications are only available to Pro users."}
      >
        <Input
          label={"Discord Webhook"}
          type={"text"}
          name={"discord_webhook"}
          register={register}
          errors={errors}
          placeholder={"https://discord.com/api/webhooks/..."}
          autoComplete={"off"}
          helpText={
            "If you want to receive notifications for this list, enter a Discord webhook URL here."
          }
        />
      </ProOverlay>

      <h3 className={"fs-5"}>Stations/Structures</h3>
      <p>
        Add a station to keep track of minimum quantities and fair prices at that
        location.
      </p>
      {fields.map((field, index) => (
        <div className={"card mb-3"} key={field.id}>
          <div className={"card-body"}>
            <div className={"form-group"}>
              <label
                htmlFor={`stations.${index}.station`}
                className={"form-label"}
              >
                Station/Structure
              </label>
              <Controller
                render={({ field }) => {
                  return (
                    <AutocompleteInput
                      name={field.name}
                      type={"station"}
                      placeholder={"Start typing a station name..."}
                      onChange={field.onChange}
                      inputProps={field}
                      defaultValue={field.value}
                    />
                  );
                }}
                name={`stations.${index}.station`}
                control={control}
              />
              <p className={"form-text text-white-50"}>
                The station or structure to apply this list to. Start typing to
                autocomplete. If a private structure isn't showing up, you may
                need to wait up to an hour.
              </p>
            </div>
            <div className={"form-group"}>
              <label
                htmlFor={`stations.${index}.price_per_m3`}
                className={"form-label"}
              >
                Cost Per m<sup>3</sup>
              </label>
              <input
                type={"number"}
                className={"form-control"}
                placeholder={"140"}
                {...register(`stations.${index}.price_per_m3`)}
              />
              <p className={"form-text text-white-50"}>
                This is an additional cost added to the item's price per m
                <sup>3</sup> when calculating the fair cost of the item. This is
                useful for items that are difficult to transport, such as ships.
              </p>
            </div>
            <button
              className={"btn btn-danger"}
              type={"button"}
              onClick={() => remove(index)}
            >
              Remove
            </button>
          </div>
        </div>
      ))}
      <button
        className={"btn btn-primary mb-3"}
        type={"button"}
        onClick={() =>
          append({
            price_per_m3: 140,
          })
        }
      >
        Add Station/Structure
      </button>
      <div className={"form-group"}>
        <button
          className={"btn btn-success"}
          type={"submit"}
          disabled={saveMutation.isPending}
        >
          {saveMutation.isPending ? (
            <>
              <span
                className={"spinner-border spinner-border-sm"}
                role={"status"}
                aria-hidden={"true"}
              />
              &nbsp;Saving...
            </>
          ) : (
            "Save"
          )}
        </button>
        {id && (
          <button
            className={"btn btn-danger mx-2"}
            type={"button"}
            onClick={deleteMutation.mutate}
          >
            Delete
          </button>
        )}
      </div>
    </form>
  );
}
