export function compactNumber(value) {
  return new Intl.NumberFormat("en", { notation: "compact" }).format(value);
}

export function humanizeRelativeDate(value) {
  // If the value is a string, convert it
  if (typeof value === "string") {
    value = new Date(value);
  }

  // Get the current date and subtract to get hours
  const now = new Date();
  const diff = now - value;

  return new Intl.RelativeTimeFormat("en", { numeric: "auto" }).format(
    -Math.round(diff / 1000 / 60 / 60),
    "hour",
  );
}

export function compactDateTimeDelta(value) {
  // Show days if > 1 day, otherwise show hours if > 1 hour, otherwise show minutes.
  // The value is a Date object in the future.
  if (!(value instanceof Date)) {
    value = new Date(value);
  }

  const now = new Date();
  const diff = value - now;

  if (diff > 1000 * 60 * 60 * 24) {
    return `${Math.round(diff / 1000 / 60 / 60 / 24)}d`;
  } else if (diff > 1000 * 60 * 60) {
    return `${Math.round(diff / 1000 / 60 / 60)}h`;
  } else {
    return `${Math.round(diff / 1000 / 60)}m`;
  }
}
