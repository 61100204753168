import React, {useCallback, useState} from "react";
import { useQuery } from "@tanstack/react-query";
import { Tab, Tabs } from "react-bootstrap";
import { Blueprint } from "../industry/Blueprint";
import { AssetsTable } from "../assets/Assets";
import { HistoryTab } from "./HistoryTab";
import { OrdersTab } from "./OrdersTab";
import {IconCell, priceCell} from "../../components/Cells";
import { SkillsTab } from "./SkillsTab";
import { ScopeBar } from "../../components/ScopeBar";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import { Loading } from "../../components/Loading";
import {MarketContractsTab} from "./MarketContractsTab";
import {OpenInGame} from "../../components/OpenInGame";
import {CopyText} from "../../components/Copy";
import {Table} from "../../components/Table";

export function MarketItem() {
  const { id: market_id, tab } = useParams();
  const { addToShoppingList } = useOutletContext();
  const [openInGame, setOpenInGame] = useState(false);
  const navigate = useNavigate()

  const { data: marketItem, isLoading: typeIsLoading } = useQuery({
    queryKey: ["market", market_id],
    queryFn: () =>
      fetch(`/api/types/${market_id}/?expand=dogma_attributes,insurance_rates`).then((res) => res.json()),
  });

  const setKey = useCallback((k) => {
    navigate(`/market/${market_id}/${k}`);
  }, [navigate, market_id]);

  if (typeIsLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div className={"d-flex align-items-center"}>
        <div className={"flex-grow-0"}>
          <IconCell
            id={marketItem.id}
            name={marketItem.name}
            iconSize={64}
            className={"img-fluid"}
          />
        </div>
        <div className={"flex-grow-1 px-4"}>
          <h1 className={"fs-4"}>
            <CopyText text={marketItem.name}>
              {marketItem.name}
            </CopyText>
          </h1>
          <button
            className={"btn btn-sm btn-outline-secondary"}
            onClick={() => addToShoppingList(`1 ${marketItem.name}`)}
          >
            Add to Shopping List
          </button>
        </div>
      </div>
      <Tabs
        activeKey={tab}
        onSelect={(k) => setKey(k)}
        className={"mt-4"}
        unmountOnExit={false}
        transition={false}
      >
        <Tab eventKey="orders" title="Orders">
          {tab === "orders" && (
            <div className={"mt-4"}>
              <OrdersTab marketId={market_id} />
            </div>
          )}
        </Tab>
        <Tab eventKey="history" title="History">
          {tab === "history" && <HistoryTab itemID={market_id} />}
        </Tab>
        <Tab eventKey="industry" title="Industry">
          {tab === "industry" && (
            <div className={"mt-4"}>
              <ScopeBar optional={["esi-assets.read_assets.v1"]} />
              <div className={"mt-2"}>
                <Blueprint item_id={market_id} />
              </div>
            </div>
          )}
        </Tab>
        <Tab eventKey={"assets"} title={"Assets"}>
          {tab === "assets" && (
            <div className={"mt-4"}>
              <ScopeBar scopes={["esi-assets.read_assets.v1"]} />
              <div className={"mt-2"}>
                <AssetsTable
                  filters={[
                    {
                      type: "type",
                      value: {
                        id: market_id,
                      },
                      operator: "==",
                    },
                  ]}
                />
              </div>
            </div>
          )}
        </Tab>
        <Tab eventKey={"contracts"} title={"Contracts"}>
          {tab === "contracts" && (
            <MarketContractsTab marketId={market_id} />
          )}
        </Tab>
        {marketItem && marketItem.groups?.includes(150) && (
          <Tab eventKey={"skills"} title={"Skills"}>
            <div className={"mt-3"}>
              <p>
                This table shows you all of your characters that have this
                skillbook injected.
              </p>
              <div className={"mb-3"}>
                <ScopeBar scopes={["esi-skills.read_skills.v1"]} />
              </div>
              <SkillsTab skillId={parseInt(market_id)} />
            </div>
          </Tab>
        )}
        {marketItem && marketItem.insurance_rates?.length > 0 && (
          <Tab eventKey={"insurance"} title={"Insurance"}>
              <Table
                rows={marketItem.insurance_rates.sort((a, b) => b.payout - a.payout)}
                columns={[
                  {accessorKey: "name", header: "name"},
                  {
                    accessorKey: "cost",
                    header: "Cost",
                    cell: (cell) => priceCell(cell.getValue()),
                  },
                  {
                    accessorKey: "payout",
                    header: "Payout",
                    cell: (cell) => priceCell(cell.getValue()),
                  },
                ]}
              />
          </Tab>
        )}
      </Tabs>
      {openInGame && (
        <OpenInGame
          type={"market_details"}
          id={market_id}
          onClose={() => setOpenInGame(false)}
        />
      )}
    </div>
  );
}
