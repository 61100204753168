import { Link, NavLink, Outlet } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Page, Sidebar, Content, Top, Bottom } from "../../components/Page";
import {Loading} from "../../components/Loading";

export function SeedingLayout({ children }) {
  const { data, isLoading } = useQuery({
    queryKey: ["seeding"],
    queryFn: () =>
      fetch("/api/seeding/list/?omit=items,rollups").then((res) => res.json()),
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Page header={"Seeding"}>
      <Sidebar>
        <Top>
          <div className={"list-group list-group-flush border-0"}>
            <NavLink
              end
              key={"overview"}
              to={"/seeding"}
              className={
                "list-group-item list-group-item-action border-0"
              }
            >
              <strong>Dashboard</strong>
            </NavLink>
            {data &&
              data.map((list) => {
                return (
                  <NavLink
                    key={list.id}
                    to={`/seeding/list/${list.id}`}
                    className={
                      "list-group-item list-group-item-action border-0"
                    }
                  >
                    <span className={"d-inline-block text-truncate"}>
                      {list.name}
                    </span>
                  </NavLink>
                );
              })}
          </div>
        </Top>
        <Bottom>
          <Link
            to={"/seeding/list/"}
            className={"btn btn-primary btn-sm w-100"}
          >
            Add List
          </Link>
        </Bottom>
      </Sidebar>
      <Content>
        {children ? children : <Outlet />}
      </Content>
    </Page>
  );
}
