import React, { useState, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Table } from './Table';
import { FilterBuilder } from './FilterBuilder';
import { applyFiltersToURL, applySortingToURL } from './FilterBuilder';

const DataTable = ({
  endpoint,
  fields,
  expand,
  columns,
  allowedFilters,
  sorting,
  filters,
  onFilterChange,
  onSortChange,
  defaultColumn
}) => {
  const [url, setURL] = useState(undefined);

  useEffect(() => {
    setURL(undefined);
  }, [filters, sorting]);

  const dataURL = useMemo(() => {
    const params = new URLSearchParams();

    params.append("fields", fields.join(","));

    if (expand) {
      params.append("expand", expand.join(","));
    }

    if (filters) {
      applyFiltersToURL(params, filters);
    }

    if (sorting) {
      applySortingToURL(params, sorting);
    }

    return `${endpoint}?${params.toString()}`;
  }, [endpoint, fields, expand, filters, sorting]);

  const { data, isLoading } = useQuery({
    queryKey: ["data", url, dataURL],
    queryFn: () => {
      return fetch(url || dataURL).then((res) => res.json());
    }
  });

  return (
    <>
      <FilterBuilder
        filters={filters}
        onChange={onFilterChange}
        allowedFilters={allowedFilters}
      />
      <Table
        rows={data?.results || []}
        columns={columns}
        isLoading={isLoading}
        sorting={sorting}
        setSorting={onSortChange}
        manualSorting={sorting === undefined}
        defaultColumn={defaultColumn}
      />
      <div className="my-4">
        {data?.previous && (
          <button
            className="btn btn-primary me-2"
            onClick={() => setURL(data.previous)}
          >
            Previous
          </button>
        )}
        {data?.next && (
          <button
            className="btn btn-primary"
            onClick={() => setURL(data.next)}
          >
            Next
          </button>
        )}
      </div>
    </>
  );
};

export default DataTable;