import { Content, Header, Page } from "../../components/Page";
import { AutocompleteInput } from "../components/AutocompleteInput";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Table } from "../../components/Table";
import { useStorage } from "../../hooks/useStorage";
import { compactNumber } from "../../formatting";
import { TypeCell } from "../../components/Cells";
import {
  applyFiltersToURL,
  FilterBuilder,
} from "../../components/FilterBuilder";

/**
 * Compares the OrderHistory for the last 30 days and 7 days
 * to the currently available sell orders and identifies the
 * items that could use more supply.
 *
 * @constructor
 */
export function StationDemand() {
  const [location, setLocation] = useStorage("market.demand.station", null);
  const [filters, setFilters] = useStorage("market.demand.filters", []);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["station", location, filters],
    queryFn: () => {
      const params = new URLSearchParams();
      if (filters) {
        applyFiltersToURL(params, filters);
      }
      return fetch(
        `/api/market/${location.id}/demand/?${params.toString()}`,
      ).then((res) => res.json());
    },
    enabled: false,
  });

  return (
    <Page>
      <Content>
        <Header
          title={"Station Demand"}
          description={
            <>
              Compares the available sell orders in the given station to the
              historical demand for the last 30 days and 7 days. This can help
              you identify items that are in high demand but have low supply.
            </>
          }
        />
        <div className={"alert alert-info"}>
          This feature is still in development and may not be accurate. Always
          verify the demand for items before making any decisions.
        </div>
        <div className={"row mb-3 g-3 dark"}>
          <div className={"col-12"}>
            <AutocompleteInput
              defaultValue={location}
              onChange={setLocation}
              label={"Station"}
              type={"station"}
              helpText={"Select a station to view the demand for items."}
              inputProps={{
                placeholder: "Search for a station...",
                autoFocus: true,
              }}
            />
          </div>
          <div className={"col-12"}>
            <FilterBuilder
              filters={filters}
              onChange={setFilters}
              allowedFilters={{
                group: {
                  label: "Item Group",
                  operators: ["&&", "!&"],
                  component: AutocompleteInput,
                  props: {
                    type: "group",
                  },
                },
                volume30d: {
                  label: "Volume (30d)",
                  operators: [">=", "<="],
                },
                volume7d: {
                  label: "Volume (7d)",
                  operators: [">=", "<="],
                },
                min_price: {
                  label: "Min Price",
                  operators: [">=", "<="],
                },
                max_price: {
                  label: "Max Price",
                  operators: [">=", "<="],
                },
                avg_price: {
                  label: "Avg Price",
                  operators: [">=", "<="],
                },
              }}
            />
          </div>
          <div className={"col-12"}>
            <button
              className={"btn btn-primary w-100"}
              onClick={() => refetch()}
              disabled={isLoading}
            >
              Calculate
            </button>
          </div>
        </div>
        <div className={"mt-3"}>
          <Table
            rows={data || []}
            isLoading={isLoading}
            defaultColumn={{
              meta: {
                align: "end",
              },
            }}
            columns={[
              {
                accessorKey: "item_name",
                header: "Name",
                meta: { align: "start" },
                cell: (cell) => {
                  const { item_id, item_name } = cell.row.original;
                  return <TypeCell id={item_id} name={item_name} />;
                },
              },
              {
                accessorKey: "min_price",
                header: "Min (ISK)",
                cell: (cell) => compactNumber(cell.getValue()),
              },
              {
                accessorKey: "max_price",
                header: "Max (ISK)",
                cell: (cell) => compactNumber(cell.getValue()),
              },
              {
                accessorKey: "avg_price",
                header: "Avg (ISK)",
                cell: (cell) => compactNumber(cell.getValue()),
              },
              {
                accessorKey: "avg_volume_7d",
                header: (
                  <abbr title={"Average daily demand for the last 7 days"}>
                    7d
                  </abbr>
                ),
                cell: (cell) => compactNumber(cell.getValue()),
              },
              {
                accessorKey: "avg_volume_30d",
                header: (
                  <abbr title={"Average daily demand for the last 30 days"}>
                    30d
                  </abbr>
                ),
                cell: (cell) => compactNumber(cell.getValue()),
              },
              {
                id: "perc",
                accessorFn: (row) => {
                  const avg = row.avg_volume_30d;
                  const available = row.available_volume;

                  if (avg < 1) {
                    return 0;
                  }

                  return (available / avg) * 100;
                },
                header: (
                  <abbr
                    title={
                      "Percentage of available items compared to the average 30d demand"
                    }
                  >
                    %
                  </abbr>
                ),
                cell: (cell) => {
                  return `${cell.getValue().toFixed(0)}%`;
                },
              },
            ]}
          />
        </div>
      </Content>
    </Page>
  );
}
