import { useQuery } from "@tanstack/react-query";
import { SeedingTarget } from "./SeedingTarget";
import { useMemo } from "react";

export function SeededStationList({ listId = null }) {
  const url = useMemo(() => {
    const qURL = new URL("/api/seeding/station/", window.location.origin);
    qURL.searchParams.set(
      "expand",
      [
        "station",
        "rollups",
        "rollups.item",
        "list",
      ].join(","),
    );
    qURL.searchParams.set("omit", ["list.items", "list.rollups"].join(","));
    if (listId) {
      qURL.searchParams.set("list_id", listId);
    }
    return qURL;
  }, [listId]);

  const { data: rollups, isLoading } = useQuery({
    queryKey: ["seeding", "rollups", listId],
    queryFn: () =>
      fetch(url).then((res) => res.json()),
    refetchInterval: (query) => {
      // Refetch if any of the rollup targets are in progress (status of 10).
      if (query.state.data) {
        for (const target of query.state.data) {
          if (target.status === 10) {
            return 5000;
          }
        }
      }
      return false;
    },
  });

  if (isLoading) {
    return (
      <div>
        <div
          className={"d-flex justify-content-center align-items-center vh-100"}
        >
          <div className={"spinner-border"} role={"status"}>
            <span className={"visually-hidden"}>Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  if (!rollups.length) {
    return null;
  }

  if (listId) {
    return (
      <>
        {rollups.map((rollup) => {
          return (
            <SeedingTarget
              key={rollup.id}
              target={rollup}
              onlyNeedsAttention={false}
            />
          );
        })}
      </>
    );
  }

  return (
    <>
      {rollups.map((rollup) => {
        if (rollup.rollups.some((row) => row.needs_attention)) {
          return (
            <SeedingTarget
              key={rollup.id}
              target={rollup}
              onlyNeedsAttention={true}
            />
          );
        }
      })}
    </>
  );
}
