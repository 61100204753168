import { Content, Page, Header } from "../../components/Page";
import { AutocompleteInput } from "../components/AutocompleteInput";
import {useState} from "react";
import {useStorage} from "../../hooks/useStorage";
import { useQuery } from "@tanstack/react-query";
import { Table } from "../../components/Table";
import { IconCell, TypeCell } from "../../components/Cells";
import {
  applyFiltersToURL,
  FilterBuilder
} from "../../components/FilterBuilder";
import { FloatingPopup } from "../../components/FloatingPopup";
import { CopyButton } from "../../components/Copy";

const allowedFilters = {
  corporation: {
    label: "Corporation",
    operators: ["==", "!="],
    component: AutocompleteInput,
    props: {
      type: "lp_corporation",
    },
  },
  group: {
    label: "Item Group",
    operators: ["&&", "!&"],
    component: AutocompleteInput,
    props: {
      type: "group",
    },
  },
  item: {
    label: "Item",
    operators: ["==", "!="],
    component: AutocompleteInput,
    props: {
      type: "type",
    },
  },
};

export function LoyaltyTable({ offers, sorting, setSorting }) {
  const columns = [
    {
      header: "",
      accessorKey: "corporation",
      meta: {
        align: "center",
      },
      cell: (cell) => {
        const value = cell.getValue();
        return (
          <>
            <IconCell id={value.id} name={value.name} title={value.name} />
          </>
        );
      },
    },
    {
      header: "Item",
      accessorKey: "item",
      cell: (cell) => {
        const value = cell.getValue();
        return <TypeCell id={value.id} name={value.name} />;
      },
    },
    {
      header: "Qty",
      accessorKey: "quantity",
      meta: {
        align: "center",
      },
    },
    {
      header: "LP",
      accessorKey: "lp_cost",
      meta: {
        align: "center",
      },
    },
    {
      header: "Cost",
      accessorFn: (row) => ({
        isk_cost: row.isk_cost,
        inputs: row.inputs,
      }),
      meta: {
        align: "center",
      },
      cell: (cell) => {
        const value = cell.getValue();
        return (
          <div className={"py-2"}>
            {!!value.isk_cost && <>{value.isk_cost.toLocaleString()} ISK</>}
            {value.inputs.length > 0 && (
              <div className={"mt-1"}>
                <FloatingPopup
                  trigger={
                    <div>
                      {value.inputs.map((input) => (
                        <IconCell
                          key={input.item.id}
                          id={input.item.id}
                          name={input.item.name}
                        />
                      ))}
                    </div>
                  }
                  content={
                    <div>
                      {value.inputs.map((input) => (
                        <div key={input.item.id} className={"p-2"}>
                          <TypeCell {...input.item} />
                        </div>
                      ))}
                      <CopyButton
                        className={"btn btn-outline-secondary w-100 mt-3"}
                        onDoCopy={() =>
                          value.inputs
                            .map(
                              (input) => `${input.quantity} ${input.item.name}`,
                            )
                            .join("\n")
                        }
                      />
                    </div>
                  }
                />
              </div>
            )}
          </div>
        );
      },
    },
    {
      header: "ISK/LP",
      accessorKey: "isk_per_lp",
      meta: {
        align: "center",
      },
      enableSorting: true,
      cell: (cell) => {
        const value = cell.getValue();
        const price = new Intl.NumberFormat("EN", {
          maximumFractionDigits: value > 1000 ? 0 : 2,
        });
        return price.format(value);
      },
    },
  ];
  return (
    <Table
      defaultColumn={{ enableSorting: false }}
      columns={columns}
      rows={offers}
      sorting={sorting}
      setSorting={setSorting}
      manualSorting={true}
    />
  );
}

export function Loyalty() {
  const [filters, setFilters] = useStorage("lp.filters", []);
  const [offset, setOffset] = useState(null);
  const [sorting, setSorting] = useStorage("lp.sorting", [
    {
      id: "isk_per_lp",
      desc: true,
    },
  ]);

  const { data: offers, isLoading } = useQuery({
    queryKey: ["lp.offers", filters, offset, sorting],
    queryFn: () => {
      const params = new URLSearchParams();
      if (filters) {
        applyFiltersToURL(params, filters);
      }
      if (offset) {
        params.set("offset", offset);
      }

      if (sorting) {
        params.set(
          "ordering",
          sorting
            .map((sort) => {
              return `${sort.desc ? "-" : ""}${sort.id}`;
            })
            .join(","),
        );
      }

      params.set(
        "expand",
        ["item", "inputs.item", "corporation", "inputs"].join(","),
      );

      return fetch(`/api/loyalty/offers/?${params.toString()}`).then((res) =>
        res.json(),
      );
    },
    initialData: [],
  });

  return (
    <Page>
      <Content>
        <Header title={"Loyalty Points"} />
        <div className={"alert alert-info"}>
          The ISK per LP is an <em>estimate</em>, and doesn't always account for
          market manipulations, low volume items, or other factors. Always
          double check the prices before buying!
        </div>
        <div className={"dark mb-3"}>
          <FilterBuilder
            onChange={setFilters}
            filters={filters}
            allowedFilters={allowedFilters}
          />
        </div>
        <LoyaltyTable
          offers={offers?.results || []}
          setSorting={setSorting}
          sorting={sorting}
          isLoading={isLoading}
        />
        <div className={"mt-3"}>
          {offers?.previous && (
            <button
              className={"btn btn-primary me-2"}
              onClick={() =>
                setOffset(new URL(offers.previous).searchParams.get("offset"))
              }
            >
              Previous
            </button>
          )}
          {offers?.next && (
            <button
              className={"btn btn-primary"}
              onClick={() =>
                setOffset(new URL(offers.next).searchParams.get("offset"))
              }
            >
              Next
            </button>
          )}
        </div>
      </Content>
    </Page>
  );
}
