import { useEffect, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

/**
 * A table component that can be used to display data.
 *
 * This component is a wrapper around the @tanstack/react-table
 * component. It provides some default styling and configuration
 * that is useful for most tables.
 *
 * @param rows
 * @param columns
 * @param rowClasses
 * @param showFooter
 * @param filters
 * @param sorting
 * @param setSorting
 * @param manualSorting
 * @param defaultColumn
 * @param isLoading
 * @returns {JSX.Element}
 * @constructor
 */
export function Table({
  rows,
  columns,
  rowClasses,
  showFooter = false,
  filters,
  sorting = undefined,
  setSorting = undefined,
  manualSorting = false,
  defaultColumn,
  isLoading = false,
}) {
  if (!sorting || !setSorting) {
    [sorting, setSorting] = useState([]);
  }

  const table = useReactTable({
    data: rows,
    columns: columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualSorting,
    enableSortingRemoval: false,
    defaultColumn,
    meta: {
      rowClasses: rowClasses || (() => null),
    },
  });

  useEffect(() => {
    table.setColumnFilters(
      filters
        ? Object.keys(filters).map((key) => ({
            id: key,
            value: filters[key],
          }))
        : undefined,
    );
  }, [filters]);

  return (
    <table
      className={
        "table table-hover font-monospace table-sm align-middle m-0 position-relative"
      }
    >
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const meta = header.column.columnDef.meta || {};
              const tooltip = meta.tooltipFn ? meta.tooltipFn(header) : null;

              return (
                <th key={header.id} colSpan={header.colSpan} nowrap={1} className={"position-sticky top-0"}>
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className: `text-${meta.align || "left"}`,
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      <abbr title={tooltip}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </abbr>
                      {{
                        asc: " 🔼",
                        desc: " 🔽",
                      }[header.column.getIsSorted()] ?? null}
                    </div>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan={columns.length} className={"text-center py-3"}>
              Loading...
            </td>
          </tr>
        ) : table.getRowModel().rows.length === 0 ? (
          <tr>
            <td colSpan={columns.length} className={"text-center py-3"}>
              No data available.
            </td>
          </tr>
        ) : (
          table.getRowModel().rows.map((row) => (
            <tr key={row.id} className={table.options.meta?.rowClasses(row)}>
              {row.getVisibleCells().map((cell) => {
                const def = cell.column.columnDef;
                const meta = def.meta || {};

                return (
                  <td
                    key={cell.id}
                    className={`text-${meta?.align || "left"} ${
                      meta?.nowrap ? "text-nowrap" : ""
                    }`}
                    data-label={def.header}
                  >
                    <div
                      className={`${meta?.truncate ? "text-truncate" : ""}`}
                      style={{
                        maxWidth: meta?.maxWidth,
                      }}
                    >
                      {flexRender(def.cell, cell.getContext())}
                    </div>
                  </td>
                );
              })}
            </tr>
          ))
        )}
      </tbody>
      {!showFooter ? null : (
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => {
                const def = header.column.columnDef;
                const meta = def.meta || {};

                return (
                  <th key={header.id}>
                    <div
                      {...{
                        className: `text-${meta.align || "left"}`,
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext(),
                          )}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </tfoot>
      )}
    </table>
  );
}
