export const Input = ({
  register,
  label,
  type,
  name,
  errors,
  helpText,
  required = false,
  ...props
}) => {
  return (
    <div className={"form-group mb-3"}>
      <label className={"form-label"} htmlFor={name}>
        {label}
      </label>
      <input
        type={type}
        className={`form-control ${errors[name] && "input-error"}`}
        {...register(name, {
          required: required,
        })}
        {...props}
      />
      {errors[name] ? (
        <div className={"invalid-feedback"}>{errors[name].message}</div>
      ) : null}
      {helpText ? (
        <small className={"form-text text-white-50"}>{helpText}</small>
      ) : null}
    </div>
  );
};
export const TextArea = ({
  register,
  label,
  type,
  name,
  errors,
  helpText,
  required = false,
  ...props
}) => {
  return (
    <div className={"form-group mb-3"}>
      <label className={"form-label"} htmlFor={name}>
        {label}
      </label>
      <textarea
        className={`form-control ${errors[name] && "input-error"}`}
        {...register(name, {
          required: required,
        })}
        {...props}
      />
      {errors[name] ? (
        <div className={"invalid-feedback"}>{errors[name].message}</div>
      ) : null}
      {helpText ? (
        <small className={"form-text text-white-50"}>{helpText}</small>
      ) : null}
    </div>
  );
};

export const Checkbox = ({
  register,
  label,
  type,
  name,
  errors,
  helpText,
  required = false,
  ...props
}) => {
  return (
    <div className={"form-check mb-3"}>
      <input
        type={type}
        className={`form-check-input ${errors[name] && "input-error"}`}
        {...register(name, {
          required: required,
        })}
        {...props}
      />
      <label className={"form-check-label"} htmlFor={name}>
        {label}
      </label>
      {errors[name] ? (
        <div className={"invalid-feedback"}>{errors[name].message}</div>
      ) : null}
      {helpText ? (
        <small className={"form-text text-white-50"}>{helpText}</small>
      ) : null}
    </div>
  );
};

export const SelectInput = ({
  register,
  label,
  type,
  name,
  errors,
  helpText,
  required = false,
  options,
  defaultValue,
  ...props
}) => {
  return (
    <div className={"form-group mb-3"}>
      <label className={"form-label"} htmlFor={name}>
        {label}
      </label>
      <select
        className={`form-control ${errors[name] && "input-error"}`}
        defaultValue={defaultValue}
        {...register(name, {
          required: required,
        })}
        {...props}
      >
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
      {errors[name] ? (
        <div className={"invalid-feedback"}>{errors[name].message}</div>
      ) : null}
      {helpText ? (
        <small className={"form-text text-white-50"}>{helpText}</small>
      ) : null}
    </div>
  );
};
