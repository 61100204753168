import { useQuery } from "@tanstack/react-query";
import * as Plot from "@observablehq/plot";
import { compactNumber } from "../formatting";
import React, {useEffect, useRef, useState} from "react";

export const AggregatesChart = ({ dataKey, characterId = null }) => {
  const chartRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateDimensions = () => {
      const containerWidth = chartRef.current.parentElement.clientWidth;
      setDimensions({
        width: containerWidth,
        height: Math.max(400, Math.min(600, containerWidth * 0.6))
      });
    };

    window.addEventListener('resize', updateDimensions);
    updateDimensions();

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const { data} = useQuery({
    queryKey: ["aggregates", dataKey, characterId],
    queryFn: () => {
      const params = new URLSearchParams();
      if (characterId) {
        params.append("character", characterId);
      }
      return fetch(`/api/aggregates/${dataKey}/?${params.toString()}`).then((res) =>
        res.json(),
      ).then((data) => {
        return data.map((d) => ({
          ...d,
          day: new Date(d.day),
        })).sort((a, b) => a.day - b.day);
      });
    },
  });

  useEffect(() => {
    if (!data || data.length === 0 || dimensions.width === 0) return;

    const chart = Plot.plot({
      y: {
        grid: true,
        label: "Value",
        tickFormat: compactNumber,
      },
      grid: true,
      marks: [
        Plot.ruleY([0]),
        Plot.frame(),
        Plot.areaY(data, {
          x: "day",
          y: "value",
          stroke: "entity_name",
          fill: "entity_name",
          fillOpacity: 0.5,
          tip: {
            fill: "black",
          },
          sort: "character"
        })
      ],
      width: dimensions.width,
      height: dimensions.height,
    });

    chartRef.current.innerHTML = "";
    chartRef.current.appendChild(chart);
    chartRef.current.appendChild(chart.legend("color"));
  }, [data, dimensions]);

  return <div ref={chartRef} className="w-full h-full"></div>;
};
