import { useParams } from "react-router-dom";
import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { SeededStationList } from "./SeededStationList";
import { SeedingListForm } from "./SeededListForm";

export function SeedingListDetails() {
  const { id } = useParams();
  const [key, setKey] = useState("overview");

  return (
    <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
      <Tab title={"Overview"} eventKey={"overview"}>
        <div className={"mt-3"}>
          <SeededStationList listId={id} />
        </div>
      </Tab>
      <Tab title={"Edit"} eventKey={"edit"}>
        <div className={"mt-3"}>
          <SeedingListForm />
        </div>
      </Tab>
    </Tabs>
  );
}
