import { useQuery } from "@tanstack/react-query";
import { compactNumber } from "../../formatting";
import { Content, Header, Page, Stat, Stats } from "../../components/Page";
import EventTimeline from "./EventTimeline";


export function Dashboard() {
  const { data: events, isLoading } = useQuery({
    queryKey: ["schedule"],
    queryFn: () => {
      return fetch(`/api/events/`)
        .then((res) => res.json())
        .then((data) => {
          const groups = Object.groupBy(data.results, (event) =>
            new Date(event.date).toLocaleDateString(),
          );

          return {
            ...data,
            groups: groups,
            days: Object.keys(groups).sort((a, b) => new Date(a) - new Date(b)),
          };
        });
    },
  });

  const { data: stats, statsIsLoading } = useQuery({
    queryKey: ["stats"],
    queryFn: async () => {
      const response = await fetch("/api/stats/");
      return response.json();
    },
  });

  if (isLoading || statsIsLoading || !events || !stats) {
    return (
      <div>
        <div
          className={"d-flex justify-content-center align-items-center vh-100"}
        >
          <div className={"spinner-border"} role={"status"}>
            <span className={"visually-hidden"}>Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Page>
      <Content>
        <Header title={"Dashboard"} />
        <Stats>
          <Stat title={"Characters"} value={stats.characters.total} />
          <Stat
            title={"Total SP"}
            value={compactNumber(stats.characters.total_sp)}
          />
          <Stat
            title={"Wallet"}
            value={`${compactNumber(stats.characters.total_isk)} ISK`}
          />
          <Stat
            title={"Assets"}
            value={`${compactNumber(stats.assets.total)} ISK`}
          />
        </Stats>
        {events.results.length === 0 ? (
          <div className={"alert alert-secondary"}>
            No recent or upcoming events.
            <br />
            <small>
              This page shows you upcoming skills, industry jobs, PI extractors,
              refueling schedules, and more.
            </small>
          </div>
        ) : (
          <EventTimeline events={events.results} />
        )}
      </Content>
    </Page>
  );
}
